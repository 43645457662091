import { OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { user } from 'src/app/model/user';
import { Subscription } from 'src/app/model/subcription';
import { SubcriptionByContract } from 'src/app/model/subcriptionByContract';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css'],
})

export class ContactsComponent implements OnDestroy, OnInit, AfterViewInit {
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    loginForm: UntypedFormGroup;
    uDetails: user;

    public load: Boolean = false;
    public listSubcriptions: Array < Subscription > ;
    public listSubContract: Array < SubcriptionByContract > ;
    public tableShow = false;
    public visualizarTabla: boolean = false;
    public contratoOficialSelected: String = "";

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private cookie: CookieService,
        private peticionSvc:PeticionServiceService
    ) {
        var dataTable = $("#datatableFibra").dataTable();
        dataTable.DataTable().destroy
    }

    ngAfterViewInit(): void {
        var dataTable = $("#datatableFibra").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    ngOnInit(): void {
        this.listSubcriptions = new Array;
        this.listSubcriptions.length = 0
        this.getSubcription();
        var dataTable = $("#datatableFibra").dataTable();
        dataTable.DataTable().destroy


        let  param={
            "terceroOpe":this.cookie.get("tercero")
        }

    }

    /**
     * Funcion encargada de retornar al campo select todos los contratos Oficiales 
     */
    getSubcription() {
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            'username': this.cookie.get("user"),
            'password': this.cookie.get("pass"),
        }
        const url = environment.getsubcriptions;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading();
                if (res.subscriptions == "No hay subscripciones disponibles") {
                    Swal.fire({
                        title: "ERROR",
                        icon: "warning",
                        text: "El operador no dispone de contratos en el FTTH."
                    })
                    return;
                } else {
                    res["subscriptions"].forEach(element => {
                        this.listSubcriptions.push(element);
                    });
                }
            }, error => {
                this.hideloading()
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No existen contratos.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     * Funcion encargada de devolver todos los datos de la tabla correspondientes al ContratoOficial Seleccionado
     * @param contract ContratoOficial 
     */
    async getSubcriptionByContract(contract: String) {
        this.listSubContract = null;
        // this.listSubContract.length = 0
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            'contract': contract,

        }
        this.listSubContract = null
        const url = environment.getSuscriptionsByContract;
        this.showloading()
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                // this.dtTrigger.next(); //Necesario para poder mostrar el datatrigger
                // this.tableShow = true;
                if (res.length) {
                    this.listSubContract = new Array
                    res.forEach(
                        subcription => {
                            //-----------MAP FECHA ACTIVO
                            var fechaToMap = subcription.FechActiv.substr(5, 2);

                            if (subcription.FechActiv == "0") {
                                subcription.FechActiv = "0";
                            } else {
                                fechaToMap += '/' + subcription.FechActiv.substr(3, 2);
                                fechaToMap += '/' + subcription.FechActiv.substr(1, 2);
                                subcription.FechActiv = fechaToMap;
                            }
                            //     let statusOrder = this.getOrderStatusBysuscriptionID(subcription.subcriptionId)
                            //-----------END MAP FECHA ACTIVO
                            this.listSubContract.push(subcription)
                        });
                    this.updateDataTable()
                }
                this.hideloading()
            })
        } catch {
            // this.updateDataTable()
            this.hideloading()
        }
    }

    //_______________________________________________MOSTRAMOS EL LOADING
    showloading() {
        this.load = true
    }
        //_______________________________________________OCULTAMOS EL LOADING
    hideloading() {
        this.load = false
    }

    /**
     * Separa el valor del option en 2 partiendo del | y los introduce en un array
     * @param $event manda el evento cada vez que se elige una opcion
     */
    selectOption($event) {
        this.contratoOficialSelected = $event;
        this.getSubcriptionByContract($event)
    }

    ngOnDestroy(): void {
        if (this.dtTrigger) {
            this.dtTrigger.unsubscribe();
        }
    }

    /**
     * Metodo encargado de mostrar el estado de la subcripcion del cliente
     * @param subcriptionsId Subcripcion del usuario
     */
    getOrderStatus(subcriptionsId: String) {
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            'suscriptionID': subcriptionsId //"SID0000000_TS0000000000000_0",

        }
        const url = environment.orderStatus;
        this.load = true
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                Swal.fire({
                    title: 'ESTADO DE LA SUSCRIPCIÓN',
                    text: res.status,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Ok',

                })
                this.hideloading()

            }, error => {
                Swal.fire({
                    title: 'ESTADO DE LA SUSCRIPCIÓN',
                    text: "Error.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Ok',

                })
                this.hideloading()
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     *  Funcion encargada de mostar las posibles acciones tanto (comprobar el estado como realizar un reset del ONT del cliente)
     * @param contrato 
     * @param CodPeticion 
     * @param codDiagnosis 
     */
    async getStatusOnt(contrato: String, CodPeticion: String) {
        //------------------PARAMETROS PARA LA PETICION al endpoint /diagnosisCodes----------------------------/
        const url = environment.ontDiagnosis;

        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const urlDiagnosis = environment.diagnosisCodes;
        //-----------------------------------------------------------------------------------------------------/

        var htmlSelect = '<select id="selectDiagnostic" class="form-control" name="opcionDiagnostic" class="contratoF" ><option value="0">Seleccione la operación a realizar :</option>'
        this.showloading()
            //Obtenemos los diagnosisCode para agregarlo en un campo select
        try {
            this.http.post(urlDiagnosis, null, { headers }).subscribe((res: any) => {
                res.detalle.Código.forEach(element => {
                    htmlSelect += '<option value="' + element.CodDiagnosis + '" >' + element.Descripción + '</option>'
                    this.hideloading()
                });
                htmlSelect += '</select>'

                Swal.fire({
                    title: 'DIAGNÓSTICO DEL ONT',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Ok',
                    html: htmlSelect,

                }).then((result) => {
                    if (result.isConfirmed) {
                        //------------Recoleccion mediante JS de el campo select del modal Swal------------------------
                        var e = (document.getElementById("selectDiagnostic")) as HTMLSelectElement; //Capto el campo select
                        var sel = e.selectedIndex; //obtengo la opcion seleccionada
                        var opt = e.options[sel]; //obtengo el option seleccionado
                        var codigoDiagnosis = (opt).value; //obtengo el valor del option seleccionado
                        // var texto = ( < HTMLSelectElement > opt).text;
                        //--------End Recoleccion mediante JS de el campo select del modal Swal------------------------
                        this.ontDiagnosisLaunch(contrato, CodPeticion, codigoDiagnosis)
                    }
                })
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     * Funcionencargada de lanzar la peticion y devolver los datos de Estado del ONT 
     * Dichos datos son mostrados en un modal
     * @param contrato Contrato Oficial
     * @param CodPeticion NPeticion
     * @param codDiagnosis Codigo seleccionado para realizar la petición
     */
    ontDiagnosisLaunch(contrato: String, CodPeticion: String, codDiagnosis: String) {
        this.showloading()

        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            "contratoOficial": contrato,
            "codPeticion": CodPeticion,
            "codDiagnosis": codDiagnosis
        }
        let url = environment.ontDiagnosis
        var contentResponse = '<div> <table>';

        try {
            this.http.post(url, body, { headers }).subscribe(
                (res: any) => {
                    //---------------Format Response
                    let detalle = res["detalle"].split(";")
                    for (var i = 0; i < detalle.length; i++) {

                        if (detalle[i] != "") {
                            let claveValor = detalle[i].split("=")
                            contentResponse += '<tr><td><label for="' + claveValor[0] + '" >' + claveValor[0] + ' :</label></td>'
                            contentResponse += '<td><input name="' + claveValor[0] + '"  type="text" readonly="readonly" value="' + claveValor[1] + '"/></td></tr>'
                        }
                    }
                    contentResponse += '</table></div>'

                    Swal.fire({
                        title: 'DIAGNÓSTICO DEL ONT',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Ok',
                        html: contentResponse
                    })
                    this.hideloading()
                },
                error => {
                    Swal.fire({
                        title: 'DIAGNÓSTICO DEL ONT',
                        text: error.error.detalle,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Ok',
                    })
                    this.hideloading()
                })
        } catch {
            this.hideloading()
        }
    }

    /**
     * Funcion encargada de obtener las velocidades a las que el cliente puede migrar su linea no incluyendo en la lista 
     * la que actualmente tiene contratada
     * @param contrato 
     * @param velocidadActual 
     * @param peticion 
     */
    migrateSpeedPrepare(contrato: String, velocidadActual: String, peticion: String) {
        //----------------------PARAMETER----------------------------------
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            "contract": contrato
        }
        let url = environment.getRates
        var htmlContainer = '<select id="migrateSpeed" class="form-control" name="migrateSpeed" class="migrateSpeed" ><option value="0">Seleccione el tipo de Migracion a cambiar :</option>'
        var codObjeto = "1"
        this.load = true
            //-------------------------------------------------------------------------
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                res.forEach(element => {
                    if (element.descripcion != velocidadActual) {
                        htmlContainer += '<option value="' + element.codigo + '" >' + element.descripcion + '</option>'
                        codObjeto = element.tipoObjeto
                    }
                    this.hideloading()
                });

                Swal.fire({
                    title: 'MIGRACIÓN RÁPIDA',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Ok',
                    html: htmlContainer
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.hideloading()
                            //------------Recoleccion mediante JS de el campo select del modal Swal------------------------
                        var e = (document.getElementById("migrateSpeed")) as HTMLSelectElement; //Capto el campo select
                        var sel = e.selectedIndex; //obtengo la opcion seleccionada
                        var opt = e.options[sel]; //obtengo el option seleccionado
                        var codigoMigrate = (opt).value; //obtengo el valor del option seleccionado
                        // var texto = ( < HTMLSelectElement > opt).text;
                        this.migrateSpeedLaunch(contrato, peticion, codObjeto, codigoMigrate)
                            //--------End Recoleccion mediante JS de el campo select del modal Swal------------------------
                    }
                })
            })
        } catch {
            this.load = false
        }
        this.load = false
    }
    
    /**
     * Funcion encargada de lanzar la peticion de cambio de migración
     * @param contratoOficial 
     * @param codPeticionInet 
     * @param codTipoObjeto 
     * @param codObjeto 
     */
    migrateSpeedLaunch(contratoOficial: String, codPeticionInet: String, codTipoObjeto: String, codObjeto: String) {
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            "contratoOficial": contratoOficial,
            "codPeticionInet": codPeticionInet,
            "codTipoObjeto": codTipoObjeto,
            "codObjeto": codObjeto
        }

        let url = environment.migrateSpeed
        var htmlContainer = '<select id="migrateSpeed" class="form-control" name="migrateSpeed" class="migrateSpeed" ><option value="0">Seleccione el tipo de Migracion a cambiar :</option>'
        this.load = true
        try {
            //-------------------------------------------------------------------------
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                Swal.fire({
                    icon: 'success',
                    title: 'OK',
                    text: res.detalle,
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                this.hideloading()
            }, (error: any) => {
                this.hideloading()
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No ha sido posible realizar la migración de velocidad.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            })
        } catch {
            this.hideloading()
        }
    }
    /**
     * Funcion encargada de Bloquear o Desbloquear a un Cliente
     * @param $event 
     * @param contrato 
     * @param peticion 
     */
    async cambiarEstadoUsuario($event, contrato: String, peticion: String, id: string) {
        var elemento = (document.getElementById("id")) as HTMLInputElement; //Capto el campo select
        var estadoPosterior = ""
        var textoInformativo = "";

        if ($event.checked == true) { //Esta desbloqueado y quiero bloquearlo
            $event.source.value = "Inactivo";
            estadoPosterior = "R";
            textoInformativo = "El usuario esta bloqueado ¿Seguro que quieres desbloquearlo?"
        } else { //Esta desbloqueado y quiero bloquearlo
            estadoPosterior = "S";
            textoInformativo = "El usuario esta activo ¿Seguro que quieres bloquearlo?"
            $event.source.value = "Activo";
        }

        Swal.fire({
            title: 'CONTROL USUARIOS',
            text: textoInformativo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Ok',
        }).then((result) => {
            if (result.isConfirmed) {
                this.blockUserLaunch(contrato, peticion, estadoPosterior)
            } else {
                if (estadoPosterior === "S" || estadoPosterior === 'D') {
                    $event.source.checked = true;
                    elemento.checked = true;
                } else if (estadoPosterior === "R") {
                    $event.source.checked = false;
                    elemento.checked = false;
                }
            }
        })
    }

    /**
     * Evento encargado de bloquear a un subcriptor
     * @param contrato 
     * @param peticion 
     * @param estadoPosterior 
     */
    blockUserLaunch(contrato: String, peticion: String, estadoPosterior: String) {
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            "contratoOficial": contrato,
            "peticionInet": peticion,
            "estado": estadoPosterior
        }
        let url = environment.blockUser
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                Swal.fire({
                    title: 'CONTROL USUARIOS',
                    text: res.detalle,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Ok',

                })
                this.hideloading()

            }, error => {
                Swal.fire({
                    title: 'CONTROL USUARIOS',
                    text: "ERROR",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Ok',
                })
                this.hideloading()
            })
        } catch {
            this.hideloading()
        }
    }

    /**
     * Funcion encargada de eliminar un subcriptor 
     * @param contratoOficialSelected 
     * @param peticion 
     */
    deleteSuscriber(contratoOficialSelected: String, peticion: String) {
        Swal.fire({
            title: 'CANCELACIÓN DE UN SUSCRIPTOR',
            text: "¿Desea continuar con la cancelación del suscriptor?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Ok',

        }).then((result) => {
            if (result.isConfirmed) {
                // this.showloading()
                const headers = new HttpHeaders({
                    'username': this.cookie.get("user"),
                    'token': this.cookie.get("token"),
                });
                const body = {
                    "contratoOficial": contratoOficialSelected,
                    "peticionInet": peticion
                }
                let url = environment.deleteSuscriber
                this.load = true;
                try {
                    this.http.post(url, body, { headers }).subscribe((res: any) => {
                        Swal.fire({
                            title: '[Info] CANCELACIÓN DE UN SUSCRIPTOR',
                            text: res.detalle,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#00b1aa',
                            confirmButtonText: 'Ok',
                        })
                        this.hideloading()
                        this.load = false;
                    })
                } catch {
                    this.hideloading()
                }
            }
        })

    }
    /**
     * Método encargado de realizar la comprobacion del estado de la subcripción mediante el Soap de Orange
     * En su respuesta nos mostrará los valores del estado del evento 
     * TODO: falta por incluir en la peticion el suscriptionID del subcriptor y el Orderticket
     */
    getSubcriptionStatusOrange(suscriptionID: String, orderTicket: String) {
        this.showloading();
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });

        const body = {
            "suscriptionID": suscriptionID,
            "orderTicket": orderTicket //"19798711"
        }
        var showModal = false;
        let url = environment.getSubscriptionStateOrange
        try {
            this.http.post(url, body, { headers }).subscribe((response: any) => {
                var _icon: any = 'success';
                var _title = ""
                var _color = "";
                var listhtml = ``;
                var htmlContainer = ``;

                if (response != null) {
                    switch (response.code) {
                    case "2-6-3": //TODO OK (VERDE) :)
                        _title += `<h2 style="background-color:#5cb85c; margin:0;">`
                        _title += (response.lastEvent.status == "COMPLETED") ? "COMPLETADA" : "COMPLETADA";
                        _title += '</h2>'
                        _icon = "success";
                        _title += `<div style="background-color:black; margin-bottom: 30px;     color: wheat;">`
                        _title += `<div style=" margin-right:5px; " ><strong>` + response.lastEvent.observations + `</strong></div>`;
                        _title += `</div>`
                        _color = "#5cb85c";
                        showModal = true;
                        response.historic.forEach(element => {
                            //========HTML MODAL======================
                            listhtml += `<div style="border:1px solid black;    margin-bottom: 10px;">`;

                            listhtml += `<div><h4 style="margin:0;">` + element.status + `</h4></div>`

                            let objectObservation = element.observations.split(":"); //separo el events para separar el nodo clave y el nodo valor
                            var value = "";
                            if (objectObservation[1] == undefined || objectObservation[1] == "") { value = "" } else { value = objectObservation[1] }
                            listhtml += `<div style="  display:flex; justify-content: center; white-space: pre; background:black;     color: wheat;">`

                            listhtml += `<div style=" margin-right:5px; " ><strong>` + objectObservation[0] + `</strong></div> <div>` + value + `</div>`;
                            listhtml += `</div>`

                            listhtml += `<div style=" margin-right:5px; " ><strong> ` + element.date + `  </strong></div>`;

                            listhtml += `</div>`;
                            //==========================================
                        })
                        htmlContainer += _title + `<div style="max-height: 205px;overflow-y:scroll;">` + listhtml + `</div>`;
                        break; //END OK 2-6-3
                    case "1-6-5": //HAY PROBLEMAS  :(
                        if (response.lastEvent.status == "CANCELLED") { //Si el evento nos devuelve Cancelado pertenece a los eventos de aviso
                            _title += `<h2 style="background-color:orange; margin:0;">`
                            _title += (response.lastEvent.status == "CANCELLED") ? "CANCELADA" : response.lastEvent.status + "";
                            _title += '</h2>'
                                // _color = "orange";
                            _icon = "warning";
                            let objectObservation = response.lastEvent.observations.split(":");
                            _title += `<div style="background-color:black; margin-bottom: 30px;     color: wheat;" >`
                            _title += `<div style=" margin-right:5px; " ><strong>` + objectObservation[0] + `</strong></div> <div>` + objectObservation[1] + `</div>`;
                            _title += `</div>`
                                // listhtml += `</div>`;
                            showModal = true;
                            response.historic.forEach(element => {
                                listhtml += `<div style="border:1px solid black;margin-bottom: 10px;">`;
                                listhtml += `<div><h4 style="margin:0;">` + element.status + `</h4></div>`

                                listhtml += `<div style="  display:flex; justify-content: center; white-space: pre; background:black;     color: wheat;">`
                                listhtml += `<div style=" margin-right:5px; " ><strong>` + element.observations.split(":")[0] + `</strong></div> <div>` + element.observations.split(":")[1] + `</div>`;
                                listhtml += `</div>`
                                listhtml += `<div style=" margin-right:5px; " ><strong> ` + element.date + `  </strong></div>`;
                                listhtml += `</div>`;
                            });

                            htmlContainer += _title + `<div style="max-height: 205px;overflow-y:scroll;">` + listhtml + `</div>`;
                        } else { //Si el evento nos devuelve cualquie evento que pertenece a los eventos de aviso amarillo
                            _title += `<h2 style="background-color:yellow; margin:0;">`
                            _title += (response.lastEvent.status == "FAILED") ? "FALLIDA" : response.lastEvent.status;
                            _title += '</h2>'

                            // _color = "yellow";
                            _icon = "warning";
                            let objectObservation = response.lastEvent.observations.split(":");
                            _title += `<div style="background-color:black; margin-bottom: 30px;     color: wheat; ">`
                            _title += `<div style=" margin-right:5px; " ><strong>` + objectObservation[0] + `</strong></div> <div>` + objectObservation[1] + `</div>`;
                            _title += `</div>`
                                // listhtml += `</div>`;
                            showModal = true;
                            response.historic.forEach(element => {
                                listhtml += `<div style="border:1px solid black;margin-bottom: 10px;">`;
                                listhtml += `<div><h4 style="margin:0;">` + element.status + `</h4></div>`
                                listhtml += `<div style="  display:flex; justify-content: center; white-space: pre; background:black;     color: wheat;">`
                                listhtml += `<div style=" margin-right:5px; " ><strong>` + element.observations.split(":")[0] + `</strong></div> <div>` + element.observations.split(":")[1] + `</div>`;
                                listhtml += `</div>`
                                listhtml += `<div style=" margin-right:5px; " ><strong> ` + element.date + `  </strong></div>`;
                                listhtml += `</div>`;
                            });

                            htmlContainer += _title + `<div style="max-height: 205px;overflow-y:scroll;">` + listhtml + `</div>`;
                        }
                        break; //end 1-6-5
                    }
                } else { //si el codigo es distinto de 2-6-3 y 1-6-5 
                    _title = "No hay nada para mostrar"
                    _icon = "success";
                    _color = "";
                    // showModal = ;
                    htmlContainer = '';
                    Swal.fire({
                        title: _title,
                        // icon: _icon,
                        html: htmlContainer,
                        // background: _color,
                        showCloseButton: true,
                    })
                }
                this.hideloading();
                if (showModal) {
                    //==================SHOW MODAL===========
                    Swal.fire({
                        // title: _title,
                        icon: _icon,
                        html: htmlContainer,
                        // background: _color,
                        showCloseButton: true,
                    })
                }
            }, error => {
                this.hideloading();
            })
        } catch {
            this.hideloading()
        }
    }

    /**
     * Actualizacion del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatableFibra").dataTable();
        dataTable.DataTable().destroy
        $('#datatableFibra').DataTable({
            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    strToNumber(str) {
        return parseInt(str);
    }
}