import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatSliderChange, MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
//import { MatCard} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { AppComponent } from './app.component';
import { FirstFormComponent } from './pages/first-form/first-form.component';
import { SecondFormComponent } from './pages/second-form/second-form.component';
import { AuthService } from './services/auth.service';
import { InitComponent } from './pages/init/init.component';
import { MobileComponent } from './menus/mobile/mobile.component';
import { MatGridListModule, MatGridTile } from '@angular/material/grid-list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { LineafijaComponent } from './pages/lineafija/lineafija.component';
import { DataTablesModule } from "angular-datatables";
import { DetallelineafijaComponent } from './pages/detallelineafija/detallelineafija.component';
import { ConsumofijoComponent } from './pages/consumofijo/consumofijo.component';
import { CookieService } from 'ngx-cookie-service';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { LineamovilComponent } from './pages/lineamovil/lineamovil.component';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { MatDialogModule } from '@angular/material/dialog';
import { DoneteComponent } from './donete/donete.component';
import { InitmovilComponent } from './pages/initmovil/initmovil.component';
import { AccioneslineamovilComponent } from './pages/accioneslineamovil/accioneslineamovil.component';
import { Publi1Component } from './pages/publicidad/publi1/publi1.component';
import { Publi2Component } from './pages/publicidad/publi2/publi2.component';
import { Publi3Component } from './pages/publicidad/publi3/publi3.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { ServiciosmovilComponent } from './pages/serviciosmovil/serviciosmovil.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CreateSubcriptionComponent } from './pages/create-subcription/create-subcription.component';
import { CdrPageComponent } from './pages/cdr-page/cdr-page.component';
import { AdvancedFindComponent } from './pages/advanced-find/advanced-find.component';
import { SharedGroupComponent } from './pages/shared-group/shared-group.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateClientComponent } from './pages/accioneslineamovil/create-client/create-client.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ExportPageComponent } from './pages/export-page/export-page.component';
import { NotifyComponentComponent } from './pages/accioneslineamovil/notify-component/notify-component.component';
import { InforSImComponentComponent } from './pages/accioneslineamovil/infor-sim-component/infor-sim-component.component';
import { CambioSimComponentComponent } from './pages/accioneslineamovil/cambio-sim-component/cambio-sim-component.component';
import { ConsumoComponentComponent } from './pages/accioneslineamovil/consumo-component/consumo-component.component';
import { GestionBonoComponentComponent } from './pages/accioneslineamovil/gestion-bono-component/gestion-bono-component.component';
import { CambioTarifaComponentComponent } from './pages/accioneslineamovil/cambio-tarifa-component/cambio-tarifa-component.component';
import { CambioClienteComponentComponent } from './pages/accioneslineamovil/cambio-cliente-component/cambio-cliente-component.component';
import { ControlRiesgoComponentComponent } from './pages/accioneslineamovil/control-riesgo-component/control-riesgo-component.component';
import { AddSharedGroupPageComponent } from './pages/add-shared-group-page/add-shared-group-page.component';
import { DownSharedGroupPageComponent } from './pages/down-shared-group-page/down-shared-group-page.component';
import { ListSharedGroupComponent } from './pages/list-shared-group/list-shared-group.component';
import { ControlBajasPageComponent } from './pages/control-bajas-page/control-bajas-page.component';
import { GestionPortabilidadesPageComponent } from './pages/gestion-portabilidades-page/gestion-portabilidades-page.component';

import { DocumentacionComponent } from './pages/accioneslineamovil/documentacion/documentacion.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { BuscarLineasComponent } from './pages/initmovil/buscar-lineas/buscar-lineas.component';
import { VerDocumentoComponent } from './pages/accioneslineamovil/ver-documento/ver-documento.component';
import { ViewDocumentacionPageComponent } from './pages/view-documentacion-page/view-documentacion-page.component';
import { AdvancedFindRiesgoComponent } from './pages/advanced-find-riesgo/advanced-find-riesgo.component';
import { ListControlRiesgoComponent } from './pages/list-control-riesgo/list-control-riesgo.component';
import { ListBloqueadosComponent } from './pages/list-bloqueados/list-bloqueados.component';
import { ModalDomiciliosComponent } from './pages/create-account/modal-domicilios/modal-domicilios.component';
import { GestionRechazosPageComponent } from './pages/gestion-rechazos-page/gestion-rechazos-page.component';
import { GestPortabilidadesFIjoPageComponent } from './pages/gest-portabilidades-fijo-page/gest-portabilidades-fijo-page.component';
// import { ServiciosFijoPageComponent } from './pages/servicios-fijo-page/servicios-fijo-page.component';
import { ExportFijoPageComponent } from './pages/export-fijo-page/export-fijo-page.component';
import { TitularidadFijosPageComponent } from './pages/titularidad-fijos-page/titularidad-fijos-page.component';
import { CreateClientFijoComponent } from './pages/create-client-fijo/create-client-fijo.component';
import { VerDocumentacionFijoPageComponent } from './pages/ver-documentacion-fijo-page/ver-documentacion-fijo-page.component';
import { UploadFijoDocsPageComponent } from './pages/upload-fijo-docs-page/upload-fijo-docs-page.component';
import { AdvancedFindFijoComponent } from './pages/advanced-find-fijo/advanced-find-fijo.component';
import { HistoricTarifasComponent } from './pages/accioneslineamovil/historic-tarifas/historic-tarifas.component';
import { HistoricBonosComponent } from './pages/accioneslineamovil/historic-bonos/historic-bonos.component';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ModalCoincidenciasComponent } from './pages/create-account/modal-coincidencias/modal-coincidencias.component';
import { CreateAccountWhiteBrandComponent } from './pages/create-account-white-brand/create-account-white-brand.component';
import { CreateSubcriptionWhiteBrandComponent } from './pages/create-subcription-white-brand/create-subcription-white-brand.component';
import { CrearPedidosMarcaBlancaComponent } from './crear-pedidos-marca-blanca/crear-pedidos-marca-blanca.component';
import { ListaSuscriptoresMarcaBlancaComponent } from './pages/lista-suscriptores-marca-blanca/lista-suscriptores-marca-blanca.component';
import { ModalAsignaHorasComponent } from './pages/modal-asigna-horas/modal-asigna-horas.component';
import { IncidenciasListComponent } from './pages/incidencias-list/incidencias-list.component';
import { ComentarIncidenciaComponent } from './pages/comentar-incidencia/comentar-incidencia.component';
import { ListaServiciosActivosComponent } from './pages/lista-servicios-activos/lista-servicios-activos.component';
import { WrongPassportModalComponent } from './pages/wrong-passport-modal/wrong-passport-modal.component';
import { ListaAlbaranesComponent } from './pages/lista-albaranes/lista-albaranes.component';
import { DiagnosticoOntModalComponent } from './pages/diagnostico-ont-modal/diagnostico-ont-modal.component';
import { CompruebaCoberturaComponent } from './pages/comprueba-cobertura/comprueba-cobertura.component';
import { ModalCallesComponent } from './modal-calles/modal-calles.component';
import { ListaDocumentosComponent } from './pages/lista-documentos/lista-documentos.component';

import { SubirDocumentacionComponent } from './modals/subir-documentacion/subir-documentacion.component';
import {FileUploadModule} from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { CreateIncidenciasModalComponent } from './pages/create-incidencias-modal/create-incidencias-modal.component';
import { ListarMultisimComponentComponent } from './pages/accioneslineamovil/listar-multisim-component/listar-multisim-component.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    // { path: '', component: FirstFormComponent },
    // { path: 'second', component: SecondFormComponent }
];

@NgModule({ 
    declarations: [
        AppComponent,
        FirstFormComponent,
        SecondFormComponent,
        InitComponent,
        MobileComponent,
        WelcomeComponent,
        LineafijaComponent,
        DetallelineafijaComponent,
        ConsumofijoComponent,
        LineamovilComponent,
        DoneteComponent,
        InitmovilComponent,
        AccioneslineamovilComponent,
        Publi1Component,
        Publi2Component,
        Publi3Component,
        ServiciosComponent,
        ServiciosmovilComponent,
        CreateAccountComponent,
        ContactsComponent,
        CreateSubcriptionComponent,
        CdrPageComponent,
        AdvancedFindComponent,
        SharedGroupComponent,
        CreateClientComponent,
        ExportPageComponent,
        NotifyComponentComponent,
        InforSImComponentComponent,
        CambioSimComponentComponent,
        ConsumoComponentComponent,
        GestionBonoComponentComponent,
        CambioTarifaComponentComponent,
        CambioClienteComponentComponent,
        ControlRiesgoComponentComponent,
        AddSharedGroupPageComponent,
        DownSharedGroupPageComponent,
        ListSharedGroupComponent,
        ControlBajasPageComponent,
        GestionPortabilidadesPageComponent,
        DocumentacionComponent,
        InvoicesComponent,
        BuscarLineasComponent,
        VerDocumentoComponent,
        ViewDocumentacionPageComponent,
        AdvancedFindRiesgoComponent,
        ListControlRiesgoComponent,
        ListBloqueadosComponent,
        ModalDomiciliosComponent,
        GestionRechazosPageComponent,
        GestPortabilidadesFIjoPageComponent,
        ExportFijoPageComponent,
        TitularidadFijosPageComponent,
        CreateClientFijoComponent,
        VerDocumentacionFijoPageComponent,
        UploadFijoDocsPageComponent,
        AdvancedFindFijoComponent,
        HistoricTarifasComponent,
        HistoricBonosComponent,
        ModalCoincidenciasComponent,
        CreateAccountWhiteBrandComponent,
        CreateSubcriptionWhiteBrandComponent,
        CrearPedidosMarcaBlancaComponent,
        ListaSuscriptoresMarcaBlancaComponent,
        ModalAsignaHorasComponent,
        IncidenciasListComponent,
        ComentarIncidenciaComponent,
        ListaServiciosActivosComponent,
        WrongPassportModalComponent,
        ListaAlbaranesComponent,
        DiagnosticoOntModalComponent,
        CompruebaCoberturaComponent,
        ModalCallesComponent,
        ListaDocumentosComponent,
        SubirDocumentacionComponent,
        CreateIncidenciasModalComponent,
        ListarMultisimComponentComponent,
    ],
    exports: [RouterModule, MatGridListModule, MatGridTile],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        ButtonModule,
        FileUploadModule,
        RouterModule.forRoot(routes),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatGridListModule,
        MatExpansionModule,
        MatFormFieldModule,
        DataTablesModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatInputModule,
        MatDialogModule,        
        BrowserAnimationsModule,
        MatSlideToggleModule,
        MatSelectModule,
        DragDropModule,
        NgxGpAutocompleteModule,
    ],
    providers: [
        AuthService, 
        CookieService, 
        MatDatepickerModule, 
        DatePipe, 
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, 
        provideHttpClient(withInterceptorsFromDi()),
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA] 
    })
export class AppModule {}