import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';
import { KeyValue } from '@angular/common'
import { data } from 'jquery';

@Component({
    selector: 'app-consumo-component',
    templateUrl: './consumo-component.component.html',
    styleUrls: ['./consumo-component.component.css']
})
export class ConsumoComponentComponent implements OnInit {
    private onCompare(_left: KeyValue < any, any > , _right: KeyValue < any, any > ): number {
        return -1;
    }
    listaLllamadas
    groupListLlamadas
    data2: any;
    donutRoaming:boolean=false
    consumo = {
        telefono: ''
    }
    @Input() resetFormSubject: Subject < boolean > = new Subject < boolean > ();
    protected newTarifaUpdate: string
    public doughnutChartLabels: string[]
    public doughnutChartLabelRoaming: string[]
    public demodoughnutChartData: number[]
    public demodoughnutChartDataRoaming: number[]
    public doughnutChartType: string

    constructor(
        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService

    ) {}

    ngOnInit(): void {
        this.listaLllamadas = null;
        this.data2 = null;
        this.resetValues()
    }

    /**
     * Retorna un listado de llamadas de lalínea seleccionada
     */
    async verListaLlamadas() {
        await this.loadService.showloading();
        this.listaLllamadas = null;
        this.groupListLlamadas = null;
        this.data2 = null;

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const body = {
            "telefono": this.cookies.get("numero")
                // "telefono": "605363965"
        }
        const uri = environment.getCallsForLine;
        this.listaLllamadas = null;
        this.groupListLlamadas = null;
        try {
            this.http.post(uri, body, { headers }).subscribe((res: any) => {
                if (res.Error) {
                    Swal.fire({
                        icon: "warning",
                        text: res.Error.toUpperCase()
                    })
                } else {
                    this.listaLllamadas = []
                    this.groupListLlamadas = []
                    // this.itemsGroupLlamada = [];

                    res.forEach(element => {
                        this.listaLllamadas.push({
                                telf: element.telf,
                                acum: element.acum,
                                bono: element.bono,
                                cadenap: element.cadenap,
                                dest: element.dest,
                                dura: element.dura,
                                fecha: element.fecha,
                                hora: element.hora,
                                imp: element.imp,
                                tipo: element.tipo,
                                tipon: element.tipon
                            }
                        )
                    });
                    this.groupListLlamadas = this.groupBy(this.listaLllamadas, 'tipo');
                    //  this.groupListLlamadas = Object.keys(this.groupListLlamadas).map((key) =>  this.groupListLlamadas[key]);
                    // this.itemsGroupLlamada = this.groupBy(this.groupListLlamadas, '')
                    // const grouped = this.groupBy(this.listaLllamadas, tipo);
                }
                this.loadService.hideloading()
            });
        } catch {
            this.loadService.hideloading()
        }
    }


    /**
     * Encargado de retornar el consumo en datos de una linea móvil
     */
    async verConsumoDatos() {
        this.listaLllamadas = null; //reset
        await this.loadService.showloading()
        this.data2 = null;
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            this.consumo = {
                "telefono": this.cookies.get("numero")
                    // "telefono":"635505383"
            }
            const url = environment.getConsumoMovil;
            this.http.post(url, this.consumo, { headers }).subscribe((res: any) => {
                this.data2 = res;

                let balance
                let consumo
                if(this.data2.Bono == "Ilimitado"){
                    balance = 1
                    consumo = 0

                }else {
                    balance = this.data2.Balance
                    consumo = this.data2.Consumo
                }

                if(parseFloat(this.data2.Bonor)==0){
                    this.doughnutChartLabels = ["Restante: " + this.data2.Balance, "Consumido: " + this.data2.Consumo];             
                
                    this.demodoughnutChartData = [parseFloat(balance), parseFloat(consumo)];
                }else{
                    /* this.doughnutChartLabels = ["Restante: " + this.data2.Balance, "Consumido: " + this.data2.Consumo,"Restante roaming: "+this.data2.Balancer+"GB"];  */
                    this.doughnutChartLabels = ["Restante: " + this.data2.Balance,"Restante roaming: " + this.data2.Balancer];            
                
                    this.demodoughnutChartData = [parseFloat(balance), parseFloat(consumo),0];
                }

                

                this.doughnutChartType = 'doughnut';
                this.loadService.hideloading()
            });
        } catch {
            this.loadService.hideloading()
        }
    }

    /**
     * Util para la agrupación de llamadas por tipo
     * @param objectArray Array de llamadas
     * @param property capo por que queremos filtrar
     * @returns
     */

    groupBy(objectArray, property) {
        // this.tiposDeLlamada = [];
        return objectArray.reduce(function (acc, obj) {
            // this.tiposDeLlamada.push(obj[property]);
            var key = obj[property]
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    /**
     * Retorna el balance de la línea
     * @returns
     */
    balance() {
        if (this.data2.Bono == "Ilimitado") {
            this.data2.Balance = "0"
        }
        return this.data2.Balance
    }

    /**
     * Método encargado de cerrar el elemento
     * @param lista elemento en formato string que queremos volver null para ocultar
     */
    cerrarAccion(lista: string) {
        switch (lista) {
        case "datos":
            this.data2 = null;
            break;
        case "llamadas":
            this.listaLllamadas = null;
            break;
        }
    }

    /**
     * Calculo de total Importe de llamadas
     * @param $array
     * @returns
     */
    calcularTotalImporte($array) {
        var totalImporte = 0.0;
        $array.forEach(element => {
            totalImporte += parseFloat(element.imp)
        });
        return totalImporte;
    }
    /**
     * Calculo de total segundos consumidos de red ptv
     * @param $array
     * @returns
     */
    calcularDuraccionDeRedPtv($array) {
        var totalDuracionPTV = 0;
        $array.forEach(element => {

            if (element.cadenap == "PTV")
                totalDuracionPTV += parseInt(element.dura)
        });
        return totalDuracionPTV;
    }

    /**
     * Calculo de total segundos consumidos de red externa
     * @param $array
     * @returns
     */
    calcularDuraccionDeRedExterna($array) {
        var totalDuracionExt = 0;
        $array.forEach(element => {

            if (element.cadenap != "PTV")
                totalDuracionExt += parseInt(element.dura)
        });
        return totalDuracionExt;
    }

    /**
     * Reinicializa el componente si el padre lo ordena
     */
    resetValues() {
        this.resetFormSubject.subscribe(response => {
            if (response) {
                this.listaLllamadas = null;
                this.data2 = null;

            }
        })
    }

    /**
     * Convierte los bytes en Megabytes
     */
    convertToMB(grupo, bytes){
        if(grupo["key"] == "MOVIL: Naveg.Nacional"){
            return (bytes * Math.pow(10, -6)).toFixed(2);
        } else{
            return bytes;
        }
    }

}