import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IpServiceService } from 'src/app/ip-service.service';
import { user } from 'src/app/model/user';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { RepositoryDocumentationService } from './repository-Documentation.service';

@Injectable({
    providedIn: 'root'
})

export class AuthService implements OnInit {
    idEquipo: any
    usuario: string;
    clave: string;
    loginForm: UntypedFormGroup;
    ipAddress: string;
    uLoged: user;
    token: any;
    tlf = ''
    data2: any;
    terceroaux: string
    currentpage = null;
    // new
    redirectUrl: string;
    isLoggedIn = new BehaviorSubject < boolean > (false);
    url = environment.login;
    json;

    constructor(
        private http: HttpClient,
        public router: Router,
        private formBuilder: UntypedFormBuilder,
        private ip: IpServiceService,
        private cookies: CookieService,
        private repoService: RepositoryDocumentationService,

    ) {}

    ngOnInit(): void {

    }

        private get header(): any {
        return {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
    }

    /**
     * En esta funcion realizamos una peticion post al servicio para recibir el usuario.
     * Introducimos el usuario y la clave con el formulario y añadimos la ip del cliente.
     */
    async login(u: user) {
        this.http.post(this.url, u, this.header).subscribe((data: any) => {
            if (data.id == "E003") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR AL INICIAR SESIÓN.',
                    text: 'El usuario introducido es incorrecto. Compruebe los datos introducidos.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                this.isLoggedIn.next(false);
            } else if (data.id == "E002") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR AL INICIAR SESIÓN.',
                    text: 'La contraseña introducida es incorrecta. Compruebe los datos introducidos.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5'
                })
                this.isLoggedIn.next(false);
            } else if (data.id == "E001") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'Intente acceder de nuevo.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                this.isLoggedIn.next(false);
            } else if (data.id == "E004") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'Usuario bloqueado',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                this.isLoggedIn.next(false);
            } else if (data.id == "E888") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No tiene permiso para iniciar sesión',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                this.isLoggedIn.next(false);
            } 
             else if (data.id != "E001" && data.id != "E003" && data.id != "E002" && data.id != "E004") {
                this.uLoged = u
                this.data2 = data
                    // this.token = data.detalle;


                // this.setToken(this.token);
                this.setToken(environment.GENERICTOKEN);
                this.cookies.set("doc",data.doc)
                this.cookies.set("tercero", data.id);
                this.cookies.set("in", data.IN);
                this.cookies.set("mb", data.MB);
                if(data.MB){
                    let body = {
                        "tercero": data.id,
                        "password": this.cookies.get("pass")
                    };
                    this.authMarcaBlanca(body).subscribe({
                        next:(data:any)=>{
                            this.cookies.set("tokenMarcaBlanca",data.access_token);
                        },
                        error(err) {
                          Swal.fire({
                              icon: 'error',
                              title: 'ERROR',
                              text: 'No se ha podido conectar con marca blanca. Por favor, inténtelo de nuevo y en el caso de que el problema persista póngase en contacto con nosotros.',
                              footer: 'Email del equipo de soporte:&nbsp;<a href="mailto:operadores@ptvtelecom.com">operadores@ptvtelecom.com</a>.',
                              background: '#F5F5F5',
                          });
                        },
                    });
                }

                this.descargarLogoOperadorSiexiste();
                this.terceroaux = data.id
                    // this.router.navigate(['welcome']);
                this.getToken();
                this.isLoggedIn.next(true);

                this.router.navigate(['welcome'])
            }
        });



    }
    descargarLogoOperadorSiexiste() {
        let operator = this.cookies.get("user");
        this.repoService.downloadLogoOperator("logo_" + operator + ".jpg")
    }
    //_____________________________________________________________AUTENTICACIÓN PARA NO ACCEDER A PAGINAS
    setToken(token: string) {
        this.cookies.set("token", token);
        this.cookies.set("user", this.uLoged.user);
        this.cookies.set("pass", this.uLoged.pass);
    }
    getToken() {
        return this.cookies.get("token");
    }
    //_____________________________________________________________AUTENTICACIÓN PARA NO ACCEDER A PAGINAS
    isAuthenticated() {
        if (this.uLoged) {

            return true;
        } else {
            //____________________SE LOGUEÓ CON ANTERIORIDAD Y REFRESCO LA PAGINA
            if (this.cookies.get("user") &&
                this.cookies.get("pass") &&
                this.cookies.get("token") &&
                this.cookies.get("tercero")) {
                return true;
            }

            //______________SI LLEGA A ESTE PUNTO LO MANDAREMOS AL CARAJO ^_^
            return false;
        }
    }
    //_____________________________________________________________AUTENTICACIÓN PARA NO ACCEDER A LOGIN SI ESTA LOGUEADO
    isAuthenticated2() {
        if (this.uLoged) {
            this.router.navigate(['welcome'])
            return false;

        } else {

            return true;

        }
    }

    //_____________________________________________________________AUTENTICACIÓN PARA NO MOSTRAR NAVBAR
    isloged() {
        if (this.uLoged) {
            return true;
        } else {
            return false;

        }
    }
    //_____________________________________________________________ENVIAR DATOS DE TELEFONO
    gettelefono(telefono: string) {
        this.tlf = telefono
    }
    //
    getViewNavBar() {
        return this.router.url != '/' ? true : false
    }

    //_____________________________________________________________fUNCION PARA DESLOGUEAR Y VOLVER AL INICIO
    logout() {
        this.cookies.set("token", "");
        this.cookies.set("user", "");
        this.cookies.set("pass", "");
        this.cookies.set("in", "");
        this.cookies.set("mb", "");
        if(this.cookies.get('tokenMarcaBlanca')) this.cookies.set("tokenMarcaBlanca","");
        this.uLoged = null;
        this.router.navigate(['']);

    }

    authMarcaBlanca(body){
        let url=environment.loginMarcaBlanca
        return this.http.post(url,body)
    }
    authBitStream(){
        //credenciales bitStream
        let body={
            "email":'ptvtelecom@ptvtelecom.com',
            "password":"tZ516$"
        }
        let url=environment.loginBitstream
        return this.http.post(url,body)
    }

    authMarcaBlancaLocal(body){
        let url="http://127.0.0.1:8000/api/login"
        return this.http.post(url,body)
    }



}