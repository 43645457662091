import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CambioTitularService } from '../create-client-fijo/cambio-titular.service';

@Component({
    selector: 'app-titularidad-fijos-page',
    templateUrl: './titularidad-fijos-page.component.html',
    styleUrls: ['./titularidad-fijos-page.component.css']
})
export class TitularidadFijosPageComponent implements OnInit, OnDestroy, AfterViewInit {
    load: boolean;
    data: any;
    advancedForm: UntypedFormGroup;

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    constructor(
        private http: HttpClient, 
        private formBuilder: UntypedFormBuilder, 
        public cambioTitularServ: CambioTitularService) {
        var dataTable = $("#datatableTitFijos").dataTable();
        dataTable.DataTable().destroy;
    }

    ngOnInit(): void {
        this.cambioTitularServ.data = null;
        this.cambioTitularServ.buscar = null;
        var dataTable = $("#datatableTitFijos").dataTable();
        dataTable.DataTable().destroy;
        this.initFormGroup();
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        var dataTable = $("#datatableTitFijos").dataTable();
        dataTable.DataTable().destroy; //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    /**
     * Inicializacion del formulario
     */
    initFormGroup() {
        this.advancedForm = this.formBuilder.group({
            'telf': ['', [ Validators.pattern(/^[9||8]\d{8}$/gm), Validators.required]],
            'doc': ['', ]
        });
    }

    showloading() {
        this.load = true;
    }

    hideloading() {
        this.load = false;
    }

    /**
     * FUNCION ENCARGADA DE RETORNA LA HORA EN FORMATO LEGIBLE POR EL USUSARIO HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours) {
        let strHours: string = resHours;
        if (strHours.length > 5 && strHours.length < 7) {
            return strHours.substring(0, 2) + ":" + strHours.substring(2, 4) + ":" + strHours.substring(4, 6);
        } else {
            if (strHours.length > 4 && strHours.length < 6) {
                return "0" + strHours.substring(0, 1) + ":" + strHours.substring(1, 3) + ":" + strHours.substring(3, 5);
            } else {
                return resHours;
            }
        }
    }

    /**
     * Retorno de la fecha en formato  dd-mm-aaaa
     * @param strDate 
     * @returns 
     */
    dateTransform(strDate: String) {
        let year = strDate.substring(0, 4);
        let month = strDate.substring(4, 6);
        let day = strDate.substring(6, 8);
        return day + "-" + month + "-" + year;
    }

    updateDataTable() {
        var dataTable = $("#datatableTitFijos").dataTable();
        dataTable.DataTable().destroy;
        $('#datatableTitFijos').DataTable({
            retrieve: true,
            search: false,
            paging: false
        });
        this.dtTrigger.next();
    }
}