import { Component,Inject,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef  } from '@angular/material/dialog';
import { PeticionServiceService } from '../services/peticion-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-calles',
  templateUrl: './modal-calles.component.html',
  styleUrls: ['./modal-calles.component.css']
})
export class ModalCallesComponent implements OnInit {
  coincidencias:any=[]
  calleSeleccionada:any=""
  dtOptions = {

    search: false,
    paging: true,
    language: {
        url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
    },
    retrieve: true,
    order: false
};
  constructor(
    public modalCallesCompo: MatDialogRef<ModalCallesComponent>,
    @Inject(MAT_DIALOG_DATA) private data:any,
    private peticionesSvc:PeticionServiceService
  ) {

  }

  ngOnInit(): void {
    this.getCalles()
    
  }

  async getCalles(){
    let url=this.data.uri

    await this.peticionesSvc.peticionBitStream(url,"get",{},{}).then((result) => {
      this.coincidencias=result
    }).catch((err) => {
      console.log(err);
      Swal.fire({
        title: 'Ha ocurrido un error',
        icon: 'error'
      });
   });
  }

  seleccionaCalle(calle){
    this.calleSeleccionada=calle
  }
  confirmarCalle(){
    this.modalCallesCompo.close(this.calleSeleccionada)
  }

}
