import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-servicios',
    templateUrl: './servicios.component.html',
    styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
    uDetails: user;
    data;data2: any;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };
    dtTrigger: Subject<void> = new Subject<void>();
    aux: any;
    public load: Boolean = false;
    tlf: any;
    linea = {
        telefono: ''
    };

    constructor(private http: HttpClient,
        private auth: AuthService, private cookie: CookieService) {}
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }
    ngOnInit(): void {
        this.tlf = this.cookie.get("telefono")
        this.uDetails = this.auth.uLoged;
        this.getServicios();
    }

    /**
     * Retorna un listadom de servicios
     */
    getServicios() {
        this.showloading();
        let promise = new Promise < void > ((resolve, reject) => {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            let data = {
                nif: this.cookie.get('user'),
                telefonoFijo: this.tlf
            }

            const url = environment.getServicioFijo;
            this.http.post(url, data, { headers }).subscribe(
                (res: any) => { // Success
                    this.data = res;
                    for (var i = 0; i < this.data.length; i++) {
                        var con = this.data[i];
                    }
                    this.dtTrigger.next();
                    this.hideloading();
                },
                (error: any) => { // Error
                    this.hideloading();
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'Se ha producido un error al cargar los servicios.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                }
            );
        });
        // return promise;
    }

    /**
     * Evento para la modificaión de los servicios
     * @param $event 
     * @param nombre 
     * @param estado 
     * @param parm1 
     * @param parm2 
     * @param comando 
     */
    modificarServicio($event: MatSlideToggleChange, nombre, estado, parm1, parm2, comando) {
        Swal.fire({
            title: 'AVISO',
            text: "¿Desea de modificar el servicio?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Modificar',
            cancelButtonText: 'Cancelar'
        }).then(async(result) => {
            if (result.isConfirmed) {
                this.showloading();
                let promise = new Promise < void > ((resolve, reject) => {
                    const headers = new HttpHeaders({
                        'user': this.cookie.get("user"),
                        'token': this.cookie.get("token"),
                        'pass': this.cookie.get("pass"),
                    });

                    let body = {
                        telefonoFijo: this.tlf,
                        nombre: nombre,
                        estado: estado,
                        parm1: parm1,
                        parm2: parm2,
                        comando: comando
                    }

                    const url = environment.modificarServicioFijo;
                    this.http.post(url, body, { headers }).toPromise().then(
                        res => { // Success
                            Swal.fire(
                                'Modificado',
                                'Servicio modificado con exito',
                                'success'
                            );
                            this.getServicios();
                            resolve();
                        },
                        msg => { // Error
                            reject(msg);
                            this.hideloading();
                            Swal.fire({
                                icon: 'error',
                                title: 'ERROR',
                                text: 'Se ha producido un error al modificar los servicios.',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                            });
                        }
                    );
                });
                return promise;
            } else {
                if (estado === "S") { $event.source.checked = true;
                } else if (estado === "N") {
                    $event.source.checked = false;
                }
            }
        })
    }

    showloading() {
        this.load = true;
    }

    hideloading() {
        this.load = false;
    }
}