<div class="formbox">
    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="cabecera">
        MÓVIL - GESTIÓN DE PORTABILIDADES
    </div>
    <div class="cabecera2">
        <select class="form-control contratoSelect" name="contratos" (change)="changeContratos($event.target.value)"
            [(ngModel)]="contratosModel" [ngModelOptions]="{standalone: true}" [disabled]="disbleSelect">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.cof}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}
            </option>
        </select>
    </div>
    <div class="divTabla">
        <div id="dataTablePortabilidad" *ngIf="data" style="width: 95%;zoom: 90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" id="dt">
                <thead style="font-family: MontserratBold;">
                    <tr>
                        <th>FECHA_PEDIDO</th>
                        <th>CONTRATO</th>
                        <th>NUMERO</th>
                        <th>SIM</th>
                        <th>TITULAR_LINEA</th>
                        <th>DOCUMENTO_IDENTIDAD</th>
                        <th>VENTANA_CAMBIO</th>
                        <th>HORA_CAMBIO</th>
                        <th>DESCRIPCION</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;" *ngIf="data">
                    <tr *ngFor="let c of data; let i= index">
                        <td>{{c.FECHA_PEDIDO}}</td>
                        <td>{{c.CONTRATO}}</td>
                        <td>{{c.NUMERO}}</td>
                        <td>{{c.SIM}}</td>
                        <td>{{(c.TITULAR==null)?"None":c.TITULAR}}</td>
                        <td>{{c.DOCUMENTO_IDENTIDAD}}</td>
                        <td>{{c.VENTANA_CAMBIO}}</td>
                        <td>{{c.HORA_CAMBIO}}</td>
                        <td
                            [ngClass]="c.DESCRIPCION=='ACEPTADA'? 'aceptada': c.DESCRIPCION=='SOLICITADA'?'cancelada':'rechazada'">
                            {{c.DESCRIPCION}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span>Para <b style="color: red;"> *RECHAZOS </b> por favor envien nueva documentación requerida a <a
                href="mailto:operadores@ptvtelecom.com">operadores&#64;ptvtelecom.com</a> </span>
    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>

</div>