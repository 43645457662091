import { Component, OnInit, Inject  } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { ModalCallesComponent } from 'src/app/modal-calles/modal-calles.component';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef  } from '@angular/material/dialog';
@Component({
  selector: 'app-comprueba-cobertura',
  templateUrl: './comprueba-cobertura.component.html',
  styleUrls: ['./comprueba-cobertura.component.css']
})
export class CompruebaCoberturaComponent implements OnInit {
  carga:boolean=false
  compruebaCoberturaForm:UntypedFormGroup
  provincias:any
  provinciaSeleccionada:any
  municipios:any
  municipioSeleccionado:any
  inputNombreVia:any
  modalCoincidencias:any
  calleSeleccionada:any
  isCIF:boolean=false;
  bis:boolean=false;
  dataInicial:any
  listaErrores:any[]
  constructor(
    public fb: UntypedFormBuilder,
    public peticionesSvc:PeticionServiceService,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public datos: any, 
    public thisDialog: MatDialogRef<CompruebaCoberturaComponent>,
    
    ) { 
    this.dataInicial=datos
    this.compruebaCoberturaForm=this.fb.group({
      'provincia': ['', [Validators.required]],
      'municipio': ['', [Validators.required]],
      'buscadorInput': ['', [Validators.required]],
      'numero': ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.max(999)]],
      'planta':['', [Validators.pattern('[0-9]+'), Validators.max(999)]],
      'puerta':['', [Validators.max(999)]],
      'portal':['', [Validators.max(999)]],
      'escalera':['', [Validators.pattern('[0-9]+'), Validators.max(999)]],
      'local':['', [Validators.pattern('[0-9]+'), Validators.max(999)]],
      'aclarador':['', []]
    })
    
  }

  ngOnInit(): void {
      this.cargaProvincias()
  }


  async cargaProvincias(){
    this.carga=true;
    await this.peticionesSvc.peticionBitStream(environment.getProvinciasBitstream,"get",{},{}).then((result) => {
       this.provincias=result
    }).catch((err) => {
      Swal.fire({
        title: 'Ha ocurrido un error',
        icon: 'error'
      });
    });
    this.carga=false;
  }

  async cargaMunicipios(provincia:any){
    this.carga=true;
    //reseteo de valores en caso de que seleccione otra provincia 
    this.compruebaCoberturaForm.patchValue({
      buscadorInput: "",
      numero:"",
      planta:"",
      puerta:"",
      portal:"",
      escalera:"",
      local:"",
      aclarador:""
    });
    this.municipioSeleccionado=""
    this.calleSeleccionada=""
    this.listaErrores=[]
    //reseteo finalizado

    this.provinciaSeleccionada=provincia
    let idProvincia=this.provinciaSeleccionada.split("|")[0]

    await this.peticionesSvc.peticionBitStream(environment.getMunicipiosBitstream+idProvincia,"get",{},{}).then((result) => {
      this.municipios=result
    }).catch((err) => {
     Swal.fire({
       title: 'Ha ocurrido un error',
       icon: 'error'
     });
   });
   this.carga=false;
  }

  seleccionaMunicipio(municipio){
    this.compruebaCoberturaForm.patchValue({
      buscadorInput: "",
      numero:"",
      planta:"",
      puerta:"",
      portal:"",
      escalera:"",
      local:"",
      aclarador:""
    });
    this.municipioSeleccionado=""
    this.calleSeleccionada=""
    this.listaErrores=[]

    this.municipioSeleccionado=municipio
  }
  getCoincidencias(){
    this.compruebaCoberturaForm.patchValue({
      numero:"",
      planta:"",
      puerta:"",
      portal:"",
      escalera:"",
      local:"",
      aclarador:""
    });
    this.listaErrores=[]
    
    this.modalCoincidencias=this.dialog.open(ModalCallesComponent,{
      height: '45vh',
      width:'50vw',
      data:{
        'uri':environment.getCalleBitstream+this.provinciaSeleccionada.split("|")[0]+"/"+this.municipioSeleccionado.split("|")[0]+"/"+this.compruebaCoberturaForm.controls["buscadorInput"].value
      }
    })

    this.modalCoincidencias.afterClosed().subscribe({
      next:(data:any)=>{
        this.calleSeleccionada=data
      }
    })


  }

  validaFormulario(){
    this.listaErrores=[]
    if(this.compruebaCoberturaForm.controls["numero"].invalid){
      this.listaErrores.push("El campo número es inválido")
    }
    if(this.compruebaCoberturaForm.controls["planta"].invalid){
      this.listaErrores.push("El campo palnta es inválido")
    }
    if(this.compruebaCoberturaForm.controls["puerta"].invalid){
        this.listaErrores.push("El campo puerta es inválido")
    }
    if(this.compruebaCoberturaForm.controls["portal"].invalid){
         this.listaErrores.push("El campo portal es inválido")
    }
    if(this.compruebaCoberturaForm.controls["escalera"].invalid){
        this.listaErrores.push("El campo escalera es inválido")
    }
    if(this.compruebaCoberturaForm.controls["local"].invalid){
        this.listaErrores.push("El campo local es inválido")
    }
  }
  async cambioDomicilio(){

    
    if(!this.compruebaCoberturaForm.invalid){
      let provincia=this.provinciaSeleccionada.split("|")[0];
      let municipio=this.municipioSeleccionado.split("|")[0];
      
      let url=environment.getCoberturaBitstream+provincia+"/"+municipio+"/"+this.calleSeleccionada.codCalle+"/"+this.compruebaCoberturaForm.controls["numero"].value
      
      await this.peticionesSvc.peticionBitStream(url,"get",{},{}).then((result:any) => {
        if(result.status){
          
          Swal.fire({
            title: result.message,
            text:"¿Está seguro de que desea efectuar el cambio de domicilio?",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Confirmar',
            cancelButtonColor: '#d33'
  
        }).then((result) => {
          if (result.isConfirmed) {
           this.ejecutaCambioDomicilio()
          }else{
            Swal.fire({
              title: "Cambio domicilio cancelado",
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#00b1aa',
              confirmButtonText: 'Cancelar',
          })
          }
        })
       
        }else{
          Swal.fire({
            title: result.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Confirmar',
        })
        }
      }).catch((err) => {
        console.log(err);
        
      });
    }else{
      this.validaFormulario()
      
    }
   
  }
  async ejecutaCambioDomicilio(){

    let payload={
      "cod_contrato":this.dataInicial.data.idContrato,
      "cod_provincia": this.provinciaSeleccionada.split("|")[0],
      "cod_municipio": this.municipioSeleccionado.split("|")[0],
      "cod_calle": this.calleSeleccionada.codCalle,
      "num_finca": this.compruebaCoberturaForm.controls["numero"].value,
      "bis": this.bis,
      "portal": this.compruebaCoberturaForm.controls["portal"].value,
      "escalera": this.compruebaCoberturaForm.controls["numero"].value,
      "local": this.compruebaCoberturaForm.controls["escalera"].value,
      "planta": this.compruebaCoberturaForm.controls["planta"].value,
      "puerta": this.compruebaCoberturaForm.controls["puerta"].value,
      "aclarador": this.compruebaCoberturaForm.controls["aclarador"].value,
      "fecha_planificacion":0
    }

    //aquí ira la petición que ejecuta el ws del cambio de domicilio

    await this.peticionesSvc.peticionMarca(environment.cambioDomicilio,"post",{},payload).then((result:any)=>{
      if (result.status) {
        Swal.fire({
          title: result.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
        })
        this.thisDialog.close()
      }else{
        Swal.fire({
          title: result.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
        })
      }
      
    },(err:any)=>{
      Swal.fire({
        title: err,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      })
    })
    
  }
  toogleBis(){
    this.bis=!this.bis;
  }
}
