import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoadingServiceService } from '../../pages/accioneslineamovil/loading-service.service';
import { environment } from 'src/environments/environment';
import { SIM } from 'src/app/model/SIM';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SIMService {

    public SIM:SIM=new SIM();
    public multiSIM:SIM[]=[];
    constructor(
        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService
    ){}

    /**
     * Extrae la información de la SIM de número perteneciente al tercero ambos guardados en las cookies.
     */
    public infoSIM() {
        try {
            this.SIM=new SIM();
            this.loadService.showloading();
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            var cuerpo = {
                "tercero": this.cookies.get("tercero"),
                "telefono": this.cookies.get("numero")
            }
            const url = environment.InfoSIMMovil;
            this.http.post(url, cuerpo, { headers }).subscribe((res: any) => {
                this.getSimCodes(res.mac).then(
                    (codigosSIM: any)=>{
                        this.SIM.setICC(res.mac);
                        this.SIM.setPIN(codigosSIM.PIN);
                        this.SIM.setPUNK(codigosSIM.PUNK);
                        this.loadService.hideloading();
                    },
                    (err)=>this.loadService.hideloading()
                );
            });
        } catch {
            this.loadService.hideloading()
        }
    }


    /**
     * Extrae los códigos de pin y punk de la tarjeta SIM pasada por parámetros.
     * @param iccSim Es el ICC de la tarjeta SIM que queremos extraer los códigos. 
     */
    private getSimCodes(iccSim: string) {
        return new Promise((resolve, rejects)=>{
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            var cuerpo = {
                "tercero": this.cookies.get("tercero"),
                "iccSim": iccSim
            }
            const url = environment.getSimCodes;
            this.http.post(url, cuerpo, { headers }).toPromise().then(
                (res: any) => {
                    resolve({
                        'PIN':[res.pin,res.pin2],
                        'PUNK':[res.puk,res.puk2]
                    });
                },
                (err)=>rejects(null)
            );
        });
    }

    /**
     * Estrae las multisim relacionadas con el número de teléfono, el contrato provisional y el tercero. 
     */
    public listarMultisim() {
        try {
            this.multiSIM=[];
            this.loadService.showloading();
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            var cuerpo = {
                "tercero": this.cookies.get("tercero"),
                "contratoProvisional": this.cookies.get("cprovisional"),
                "numeroTelefono": this.cookies.get("numero")

            }
            const url = environment.verlistMutisim;
            this.http.post(url, cuerpo, { headers }).subscribe((res: any) => {
                res.multiSim.forEach(multisim => {
                    this.multiSIM.push(new SIM(multisim.Iccid_MultiSim, multisim.Pet_Principal, multisim.Pet_MultiSim));
                });
                console.log(this.multiSIM);
                
                this.loadService.hideloading();
            });
        } catch {
            this.loadService.hideloading()
        }
    }

    /**
     * Cambia la SIM actual por otra marcada por el ICC
     * @param nuevaICCSIM Es el ICC nuevo que va a tener la línea.
     */
    public cambioSIM(nuevaICCSIM:string){
        try {
            this.loadService.showloading();
            const headers = new HttpHeaders({
                'user': this.cookies.get('user'),
                'token': this.cookies.get('token'),
            });
            const cuerpo = {
                "tercero": this.cookies.get('tercero'),
                "sim": nuevaICCSIM,
                "peticion": this.cookies.get('peticion')
    
            }
            this.http.post(environment.cambioSim, cuerpo, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();
                if (res.est == "TRUE") {
                    Swal.fire({
                        icon: "success",
                        title: "CAMBIO DE SIM",
                        text: "Cambio realizado con exito."
                    })
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "CAMBIO DE SIM",
                        text: res.des
                    })
                }
            }, error => {
                console.log(error);
            })
        } catch {
            this.loadService.hideloading()
        }
    }

    /**
     * Comprueba si el ICC es correcto.
     * @param ICC Es el ICC a comprobar.
     * @returns Devuelve una promesa que respuesta booleana, true si el ICC es correcto y false si no.
     */
    comprobarICCSIM(ICC:string):Promise<boolean>{
        return new Promise((resolve, rejects)=>{
            this.loadService.showloading();
            const headers = new HttpHeaders({
                'user': this.cookies.get('user'),
                'token': this.cookies.get('token'),
                'pass': this.cookies.get('pass'),
            });
            var cuerpo = {
                tercero: this.cookies.get('tercero'),
                iccId: ICC
            }
            try {
                this.http.post(environment.checksim, cuerpo, { headers }).toPromise().then(
                    (res: any) => {
                        if (res.iccid == 'TRUE') {
                            resolve(true);
                            Swal.fire({
                                icon: 'success',
                                title: 'ESTADO SIM CORRECTO',
                                text: 'La SIM introducida es correcta.',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                // background: '#F5F5F5',
                            });
                        } else {
                            resolve(false);
                            Swal.fire({
                                icon: 'error',
                                title: 'ERROR',
                                text: 'La nueva SIM no es correcta o se encuentra registrada.',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                            });
                        }
                        this.loadService.hideloading()
                    },
                    error => { // Error
                        rejects(error);
                        this.loadService.hideloading();
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Error al comprobar el ICC. Este error también se puede producir si no ha seleccionado una tarifa.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                    }
                );
            } catch {
                rejects(false);
                this.loadService.hideloading()
            }
        });
    }
}
