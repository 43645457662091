import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
import { resolve } from 'dns';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class PeticionServiceService {
  tercero: string;
  pass: string;
  token: string = "";
  datos:any;
  tokenBitStream:any="";
  tokenLocal:any="";
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public cookies: CookieService) {

  }

  peticionMarca(url: any, tipo: any = "post", headers: any = {}, parametros: any = {}) {
    this.token = this.cookies.get("tokenMarcaBlanca") && this.token != this.cookies.get("tokenMarcaBlanca") ? this.cookies.get("tokenMarcaBlanca") : this.token;
  
    let body = {
        "tercero": this.cookies.get("tercero"),
        "password": this.cookies.get("pass")
    };
  
    let response = "";
    headers.Authorization = 'Bearer ' + this.token;
    let option = {
        headers: headers
    };
  
    if (tipo == "post") {
        return new Promise((resolve, reject) => {
            try {
                this.http.post(url, parametros, option).subscribe({
                    next: (data: any) => {
                        resolve(data);
                    },
                    error: (err: any) => {
                      if ((err.status == 401 || err.status == 0) && (this.cookies.get("tercero") != "" && this.cookies.get("pass") != "")) {
                                this.authService.authMarcaBlanca(body).subscribe({
                                    next: (data: any) => {
                                        response = data;
                                        this.cookies.set("tokenMarcaBlanca", data.access_token);
                                        this.token = data.access_token;
                                        this.tercero = this.cookies.get("tercero");
                                        this.pass = this.cookies.get("pass");
                                        resolve(this.peticionMarca(url, tipo, headers, parametros));
                                    },
                                    error: (authErr) => {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'ERROR',
                                            text: 'No se ha podido conectar con marca blanca. Por favor, inténtelo de nuevo y en el caso de que el problema persista póngase en contacto con nosotros.',
                                            footer: 'Email del equipo de soporte:&nbsp;<a href="mailto:operadores@ptvtelecom.com">operadores@ptvtelecom.com</a>.',
                                            background: '#F5F5F5',
                                        });
                                        reject(authErr);
                                    }
                                });
                        
                        } else {
                            reject(err);
                        }
                    }
                });
            } catch (err) {
                reject(err);
            }
        });
    } else if (tipo == "get") {
        return new Promise((resolve, reject) => {
            try {
                this.http.get(url, option).subscribe({
                    next: (data: any) => {
                        resolve(data);
                    },
                    error: (err: any) => {
                        response = err;
                        if ((err.status == 401 || err.status == 0) && (this.cookies.get("tercero") != "" && this.cookies.get("pass") != "")) {
                            this.authService.authMarcaBlanca(body).subscribe({
                                next: (data: any) => {
                                    response = data;
                                    this.cookies.set("tokenMarcaBlanca", data.access_token);
                                    this.token = data.access_token;
                                    this.tercero = this.cookies.get("tercero");
                                    this.pass = this.cookies.get("pass");
                                    resolve(this.peticionMarca(url, tipo, headers, parametros));
                                },
                                error: (authErr) => {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'ERROR',
                                        text: 'No se ha podido conectar con marca blanca. Por favor, inténtelo de nuevo y en el caso de que el problema persista póngase en contacto con nosotros.',
                                        footer: 'Email del equipo de soporte:&nbsp;<a href="mailto:operadores@ptvtelecom.com">operadores@ptvtelecom.com</a>.',
                                        background: '#F5F5F5',
                                    });
                                    reject(authErr);
                                }
                            });
                        } else {
                            reject(err);
                        }
                    }
                });
            } catch (err) {
                reject(err);
            }
        });
    }
  }


  peticionMarcaLocal(url: any, tipo: any = "post", headers: any, parametros: any) {
    let body = {
      "tercero": this.cookies.get("tercero"),
      "password": this.cookies.get("pass")
    }

    let response = ""
    headers.Authorization = 'Bearer ' + this.tokenLocal
    let option = {
      headers: headers
    }
    if (tipo == "post") {
      var promise = new Promise((resolve, reject)=> {
        this.http.post(url, parametros, option)
       .subscribe({
          next: ((data: any) => {
            resolve(data);
          }),
          error: ((err: any) => {
  
            if (err.status == 401 || err.status == 0) {
              this.authService.authMarcaBlancaLocal(body).subscribe({
                next: ((data: any) => {
                  response = data
                  this.tokenLocal= data.access_token
                  this.tercero = this.cookies.get("tercero"),
                  this.pass = this.cookies.get("pass")
                  resolve(this.peticionMarca(url, tipo, headers, parametros))
                  
  
                })
              })
            }else{
              reject(err);
            }
          })
        })
      });

      return promise;


    } else if (tipo == "get") {

      var promise=new Promise((resolve,reject)=>{
        this.http.get(url, option).subscribe({
          next: ((data: any) => {
           resolve (data)
  
          }),
          error: ((err: any) => {
            response = err
            if (err.status == 401 || err.status == 0) {
              this.authService.authMarcaBlanca(body).subscribe({
                next: ((data: any) => {
                  response = data
                  this.token = data.access_token
                  this.tercero = this.cookies.get("tercero"),
                  this.pass = this.cookies.get("pass")
                  resolve(this.peticionMarca(url, tipo, headers, parametros))
                })
              })
            }else{
              reject(err)
            }
          })
        })
      })
      return promise;
    }

  }

  peticionBitStream(url: any, tipo: any = "post", headers: any, parametros: any){
    headers.Authorization = 'Bearer ' + this.tokenBitStream
    let option = {
      headers: headers
    }
    if (tipo == "post") {
      var promise = new Promise((resolve, reject)=> {
        this.http.post(url, parametros, option)
       .subscribe({
          next: ((data: any) => {
            resolve(data);
          }),
          error: ((err: any) => {
  
            if (err.status == 401 || err.status == 0) {
              this.authService.authBitStream().subscribe({
                next: ((data: any) => {
                  this.tokenBitStream = data.access_token
                  resolve(this.peticionBitStream(url, tipo, headers, parametros))  
                })
              })
            }else{
              reject(err);
            }
          })
        })
      });

      return promise;
    } else if (tipo == "get") {

      var promise=new Promise((resolve,reject)=>{
        this.http.get(url, option).subscribe({
          next: ((data: any) => {
           resolve (data)
          }),
          error: ((err: any) => {
           
            if (err.status == 401 || err.status == 0) {
              this.authService.authBitStream().subscribe({
                next: ((data: any) => {
                  
                  this.tokenBitStream = data.access_token
                  resolve(this.peticionBitStream(url, tipo, headers, parametros))
                })
              })
            }else{
              reject(err)
            }
          })
        })
      })
      return promise;
    } 
  }

}
