import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-gest-portabilidades-fijo-page',
    templateUrl: './gest-portabilidades-fijo-page.component.html',
    styleUrls: ['./gest-portabilidades-fijo-page.component.css']
})
export class GestPortabilidadesFIjoPageComponent implements OnInit {
    load: boolean
    data2
    data: any;
    disbleSelect: Boolean = false

    //====================== DATATABLE =======================================//
        @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    constructor(private cookie: CookieService, private http: HttpClient) {
        var dataTable = $("#dataTablePortaFijos").dataTable();
        dataTable.DataTable().destroy
    }

    ngOnInit(): void {
        this.getContratos();
    }
    ngOnDestroy(): void {
        // var dataTable = $("#dataTablePortaFijos").dataTable();
        // dataTable.DataTable().destroy
        // this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        var dataTable = $("#dataTablePortaFijos").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    /**
     * Retorna la gestion de portabilidades que existen bajo un contrato seleccionado
     * @param contratoSelected 
     */
        gestionPortabilidad(contratoSelected) {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });
        const body =
            // {
            //     "contrato": contratoSelected,
            // }
            {
                "contratoProv": contratoSelected,
                "codTercero": this.cookie.get("tercero")
                    // "codTercero": "228881"
            }

        this.data = null;
        const url = environment.gestionPortabilidadesFijos;
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading();
                if (Array.isArray(res)) {
                    this.data = res;
                    this.disbleSelect = false;
                } else {
                    if (res.Message == 'No contiene portabilidades pendientes') {
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: res.Message,
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        })
                        this.disbleSelect = false;
                        return
                    } else {
                        this.data = [];
                        // this.data.push(res);
                        this.data.push({
                            ​
                            cliente: res.cliente,
                            ​
                            dni: res.dni,
                            ​
                            estado: res.estado,
                            ​
                            fecha: res.fecha,
                            
                            hora: res.hora,
                            ​
                            peticion: res.peticion,
                            ​
                            telefono: res.telefono
                        });
                        this.disbleSelect = false;
                    }
                }
            }, error => {
                this.disbleSelect = false;
                this.hideloading();
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No existen portabilidades.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            })
        } catch {
            this.disbleSelect = false;
            this.data = null;
            this.hideloading();
        }
    }

    /**
     * Retorna un listado de contratos
     */
    getContratos() {
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            let body = {
                "username": this.cookie.get("user"),
                "password": this.cookie.get("pass")
            }
            this.data2 = null;
            const url = environment.getContratos;
            this.showloading()
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                } else {
                    this.data2 = [];
                    this.data2.push(res);
                }
                this.hideloading();
            }, error => {
                this.hideloading()
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No existen contratos.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     * Evento encargado de retornar el listado de portabilidades que existen bajo un contrato seleccionado
     * @param $event 
     */
    changeContratos($event) {
        this.disbleSelect = true
        let contratoOficial = $event.split("|")[0];
        let contratoProv = $event.split("|")[1];
        this.gestionPortabilidad(contratoProv);
        // this.gestionPortabilidad("70314"); //---->pruebas
    }

    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }
}