import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';

@Component({
    selector: 'app-ver-documentacion-fijo-page',
    templateUrl: './ver-documentacion-fijo-page.component.html',
    styleUrls: ['./ver-documentacion-fijo-page.component.css']
})
export class VerDocumentacionFijoPageComponent implements OnInit {
    advancedForm: UntypedFormGroup;
    loadModal: Boolean;
    data: any;
    listaDocsServer: any;
    telefonoSelected: String;
    formFiles: UntypedFormGroup;
    clienteEncontrado = false;
    constructor(private formBuilder: UntypedFormBuilder,
        private cookie: CookieService,
        private http: HttpClient,
        private router: Router,
        public loadingService: LoadingServiceService,
        private sanitizer: DomSanitizer) { }
    ngOnInit(): void {
        this.initFormGroup();
        this.listaDocsServer = null;
    }
    /**
     * Inicialización del formulario
     */
    initFormGroup() {
        this.advancedForm = this.formBuilder.group({
            'telf': ['', [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(9),
                Validators.pattern(/^[8||9]\d{8}$/gm)
            ]]
        })
        this.initForm()
    }
    /**
     * Busqueda de teléfono
     * @param value numero de teléfono
     * @returns 
     */
    async findNumber(value) {
        if (value.toString().length == 9) {
            this.listaDocsServer = null;
            this.clienteEncontrado = false;
            if (value.substring(0, 1) != "8" && value.substring(0, 1) != "9") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                return
            }
            this.showLoadModal();
            this.telefonoSelected = value;
            const body = {
                "tel": value,
                "codTercero": this.cookie.get("tercero")
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });
            const url = environment.getDocsFijo;
            this.showLoadModal();
            this.listaDocsServer = null;
            this.clienteEncontrado = false;
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.listaDocsServer = null;
                    this.hiddeModal();
                    this.cookie.set("telefono", value);
                    if (res.length) { //si hay archivos
                        // this.cookie.set("telefono", value)
                        this.clienteEncontrado = true;
                        this.listaDocsServer = [];
                        res.forEach(element => {
                            var ext = element.Archivo.split(".")[1];
                            this.listaDocsServer.push({ nameFile: element.Archivo, base64File: null, typeFile: (ext == "pdf") ? "pdf" : "image" });
                        });
                    } else {
                        this.clienteEncontrado = true;
                        this.listaDocsServer = null;
                        this.createWarningAlert("El número buscado no dispone de documentación.");
                    }
                }, error => {
                    let err = JSON.stringify(error);
                    let msgerror = JSON.parse(err).error.Error;
                    console.log(msgerror);
                    if (msgerror == 'El cliente no dispone de documentación') {
                        this.clienteEncontrado = true;
                        this.hiddeModal();
                        Swal.fire({
                            icon: "warning",
                            title: "SIN DOCUMENTACIÓN",
                            text: msgerror,
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        })
                    } else if (msgerror == 'Línea inexistente y/o no asociada a tercero') {
                        this.clienteEncontrado = false;
                        this.hiddeModal();
                        Swal.fire({
                            icon: "error",
                            title: "ERROR",
                            text: msgerror,
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        })
                    }
                })
            } catch {
                this.hiddeModal();
            }
        }
    }

    showLoadModal() {
        this.loadModal = true;
    }
    hiddeModal() {
        this.loadModal = false;
    }

    /**
     * Método para descargar del servidor los documentos subidos
     * @param nombreArchivoConExtension 
     */
    downLoadDocumentFromServer(nombreArchivoConExtension: string) {
        let url = environment.downloadDocsFijo;
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token"),
            "pass": this.cookie.get("pass")
        });

        let tercero = this.cookie.get("tercero");
        const body = {
            "codTercero": tercero,
            "tel": this.telefonoSelected,
            "docplusExt": nombreArchivoConExtension
        }
        let ext = nombreArchivoConExtension.split(".")[1];
        this.loadingService.archive = null;
        this.loadingService.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadingService.hideloading();
                if (res) {
                    switch (ext) {
                        case "jpeg":
                            this.loadingService.archive = {
                                base64: 'data:image/jpeg;base64,' + res.base64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }
                        case "jpg":
                            this.loadingService.archive = {
                                base64: 'data:image/jpeg;base64,' + res.base64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }
                            break;
                        case "png":
                            let pngBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res.base64);
                            this.loadingService.archive = {
                                base64: pngBase64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }
                            break;
                        case "pdf":
                            let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res.base64);
                            this.loadingService.archive = {
                                base64: pdfBase64,
                                name: nombreArchivoConExtension,
                                tipo: "pdf"
                            }
                            break;
                    }
                } else {
                    Swal.fire({
                        icon: "warning",
                        text: res.Error
                    })
                }
            }, error => {
                this.loadingService.hideloading();
                this.createWarningAlert(error.error.message);
            })
        } catch {
            this.loadingService.hideloading();
        }
    }

    /**
     * Subida de documentos
     * @param event 
     * @param t 
     * @returns 
     */
    setDoc(event, t: string) {
        const file = event.target.files[0];
        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) { //SI SE EXCEDE LOS 5MB
            this.createWarningAlert("El archivo supera el límite de 5Mb.", "ARCHIVO DEMASIADO GRANDE");
            // this.loadModal = false;
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let terceroOperador = this.cookie.get("tercero");
        let telefono = this.telefonoSelected;
        reader.onload = () => {
            const tiposDeDocumento = ["fileDocIdentidad", "fileCopia", "fileGrabacion"];
            if (tiposDeDocumento.includes(t)) {
                let doc = reader.result;
                let nombre = file.name.split(".")[0];
                let ext = file.name.split(".").pop();
                this.uplodaDocuments(doc, ext, terceroOperador, telefono, nombre);
            }
        };
    }
    /**
     * Inicializacion del formulario 
     */
    initForm() {
        this.formFiles = this.formBuilder.group({
            'fileDocIdentidad': ['', [Validators.required]],
            'fileCopia': ['', [Validators.required]],
            'portabilidad': ['', [Validators.required]],
            'fileGrabacion': ['', [Validators.required]],
            'telefono': ['', [Validators.required]]
        });
    }

    /**
     * Método encargado de subir al servicor documentacion del cliente bien sea en formato imagen o documento pdf
     * @param docFile base64 del fichero
     * @param extFile extension del fichero sin el punto -> jpg, png, pdf
     * @param nameFile nombre del fichwero sin la extension
     * @param datosPersonales nif del cliente
     */
    uplodaDocuments(docFile, extFile, tercero, telefono, nombreDoc) {
        let url = environment.uplodadDocsFijo;

        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token"),
            "pass": this.cookie.get("pass")
        });
        this.showLoadModal();
        const body = {
            "tipoMime": extFile,
            "tel": telefono,
            "nombre": nombreDoc,
            "contenido": docFile,
            "codTercero": tercero
        }

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hiddeModal();
                if (res.Status == "OK") {
                    this.loadingService.messageDocumentacion = null;
                    this.formFiles.reset();
                    Swal.fire({
                        title: "RESULTADO CORRECTO",
                        icon: "success",
                        text: "La operación se ha realizado con éxito."
                    });
                    // this.loadDocumentationDateNow(tercero, cuarto);
                    this.findNumber(this.telefonoSelected); //refrescar la lista con los archivos subidos
                } else {
                    this.createWarningAlert("El archivo supera el límite de 5Mb.", "ARCHIVO DEMASIADO GRANDE");
                }
            }, (error: any) => { //VALIDACION DE LA SUBIDA DE DOCUMENTOS POSIBLES ERRORES
                this.hiddeModal();
                if (error.error.message == "Falta el parametro DocumentBase64") {
                    this.createWarningAlert(error.error.message == "Falta el parametro DocumentBase64" ? "Debe subir un archivo." : error.error.message, "OPERACIÓN FALLIDA");
                } else if (error.error.message == "Falta el parametro codigoCliente") {
                    this.createWarningAlert(error.error.message == "Falta el parametro codigoCliente" ? "Debe seleccionar un cliente." : error.error.message, "OPERACIÓN FALLIDA");
                } else {
                    this.createWarningAlert(error.error.message, "OPERACIÓN FALLIDA");
                }
            })
        } catch {
            this.hiddeModal();
        }
    }

    /**
     * Método para la actualizacion del array de objetos obtenidos por el servidor
     * muestra el nombre del archivo el base64 del mismo como el tipo de archivo (imagen,texto o pdf) 
     * @param fileName nombre del archivo incluido su extensión [string]
     * @param base64F archivo en base 64 formateado para su visualización [string]
     * @param typeF tipo de archivo (imagen, texto, pdf) [string]
     */
    updateListArch(fileName, base64F, typeF) {
        this.listaDocsServer.forEach(element => {
            if (element.nameFile == fileName) {
                element.base64File = base64F;
                element.typeFile = typeF;
            }
        });
    }

    /**
     * Cerrar Modal de documentación
     */
    cerrarDocumentacion() {
        this.loadingService.archive = null;
    }

    createWarningAlert(text, title = "") {
        Swal.fire({
            title: title,
            icon: "warning",
            text: text,
        });
    }
}