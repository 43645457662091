import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';

@Component({
    selector: 'app-control-riesgo-component',
    templateUrl: './control-riesgo-component.component.html',
    styleUrls: ['./control-riesgo-component.component.css']
})
export class ControlRiesgoComponentComponent implements OnInit {
    data: any;
    registroBloqueo: UntypedFormGroup;
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        search: false,
        paging: false,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        order: false
    };

    dtTrigger: Subject<void> = new Subject<void>();
    limiteModel: NgModel;
    bloqueo: NgModel;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService
    ) {}

    ngOnInit(): void {
        this.registroBloqueo = this.formBuilder.group({
            'limiteForm': ['', ],
            'bloqueoForm': ['', ]
        })
        this.controlRiesgoLaunch();
        var dataTable = $("#datatableRiesgoMovil").dataTable();
        dataTable.DataTable().destroy;
    }

    ngAfterViewInit(): void {
        var dataTable = $("#datatableRiesgoMovil").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    /**
     * Actualización del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatableRiesgoMovil").dataTable();
        dataTable.DataTable().destroy;
        $('#datatableRiesgoMovil').DataTable({
            retrieve: true,
            search: false,
            paging: false
        });

        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
     * Evento encargado de activar o desactivar el control de riesgo de una linea
     */
    async controlRiesgoLaunch() {
        this.loadService.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass")
        });

        const body = {
            "terceroCliente": this.cookies.get("tercero"),
            "telefono": this.cookies.get("numero"),
        }
        this.data = null;
        const url = environment.getMostrarRiesgo;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();
                if (res.Limite && res.Bloquear) {
                    this.data = res;
                    this.updateDataTable();
                    this.initFormChanges(res.Limite, res.Bloquear);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: res.Error,
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                    this.data = null;
                    this.updateDataTable();
                }
            }, error => {
                this.loadService.hideloading();
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: error.Error,
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                this.data = null;
                this.updateDataTable();
            })
        } catch {
            this.updateDataTable();
            this.loadService.hideloading();
        }
    }

    guardarLimiteDeRiesgo() {
        const url = environment.getGuardarLimiteRiesgo;
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass")
        });

        var bloqueo = "0";
        (this.registroBloqueo.get("bloqueoForm").value) ? bloqueo = '1': bloqueo = '0';
        const body = {
            "terceroCliente": this.cookies.get("tercero"),
            "telefono": this.cookies.get("numero"),
            "limite": this.registroBloqueo.get("limiteForm").value,
            "bloqueo": bloqueo
        }

        this.loadService.showloading();
        this.http.post(url, body, { headers }).subscribe(
            (res: any) => {
                this.loadService.hideloading();
                Swal.fire({
                    icon: "success",
                    text: res.msg
                });
            },
            error => {
                this.loadService.hideloading();
                Swal.fire({
                    icon: "warning",
                    text: "No ha sido posible realizar el cambio."
                });
            }
        )

    }

    desbloquearLinea(linea, accion) {
        const url = environment.bloqueo;
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass")
        });

        const body = {
            "telefono": linea.Telefono,
            "accion": accion,
        }

        this.loadService.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe(
                (res: any) => {
                    this.loadService.hideloading();
                    Swal.fire({
                        icon: 'success',
                        text: res.Est,
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                    this.controlRiesgoLaunch(); //Actualizar listado de lineasBloqueadas
                },
                error => {
                    this.loadService.hideloading();
                    Swal.fire({
                        icon: "warning",
                        text: "No ha sido posible realizar el cambio."
                    });
                }
            )
        } catch {
            this.loadService.hideloading();
        }
    }

    initFormChanges(limite, bloqueo) {
        var bloquear = true;
        (bloqueo == '0') ? bloquear = false: bloquear = true;
        this.registroBloqueo = this.formBuilder.group({
            'limiteForm': [limite, ],
            'bloqueoForm': [bloquear, ]
        })
    }
}