import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { element } from 'protractor';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import moment from 'moment';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
    load: boolean = false
    data2: any;
    listFacturas: any
    facturaDetalle: any;

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        // dom: 'Bfrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
       /*  paging: false, */
        info: false,
        order: [],
        columnDefs: [
            {
                targets: 2, // Índice de la columna de fecha
                type: 'date',
                render: (data, type, row) => {
                    const [day, month, year] = data.split('/').map(Number);
                    const fecha = new Date(year, month - 1, day);
                    let dateTime = moment(fecha);
                    if (type == "sort") return dateTime.format('YYYY/MM/DD');
                    else return dateTime.format('DD/MM/YYYY');
                }
            }
        ]

    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    constructor(private cookies: CookieService, private http: HttpClient, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        // this.getContratos()
        this.getsInvoices();
    }

    /**
     * Retorna un listado de lineas 
     * @param $event 
     */
    getlineas($event) {
        let contratoProvisional = $event.split("|")[0]
        let contratoOficial = $event.split("|")[1]
        // this.getsInvoices($event);
        this.getsInvoices();
        // this.getinvoicesDetall("234060", "12081576", "FB", "9", "2021");
    }

    /**
     * Retorna un listado de facturas pertenecientes a un contrato
     * @param contratoOficial 
     * @param contratoProvisional 
     */
    getsInvoices() {
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        let body = {
            "dniCliente": this.cookies.get("user").toUpperCase()
        }
        this.listFacturas = null;
        this.showloading()
        const url = environment.obtenerFacturasPorOperador;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                // this.listFacturas = res
                if (res.length) {
                    this.listFacturas = []
                    res.forEach(element => {
                        this.listFacturas.push({
                            contratoOficial: element.contratoOficial,
                            numfact: element.numeroFactura,
                            fechafact: element.fechaEmision,
                            tipoFactura: element.tipoFactura,
                            importe: element.importe,
                            estado: element.estado,
                            mes: element.mes,
                            year: element.ano
                        })
                    });
                }
                this.hideloading();
            }, error => {
                this.listFacturas = null
                this.hideloading();
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No existen facturas.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Retorna un listado de contratos
     */
    getContratos() {
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            const body = {
                "tercero": this.cookies.get("tercero")
            }
            this.data2 = null;
            this.showloading()
            const url = environment.getContratos_;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                } else {
                    this.data2 = [];
                    this.data2.push(res)
                }
                this.hideloading();
            }, error => {
                this.hideloading();
            });
        } catch {
            this.hideloading()
        }
    }

    //_________________________________LOAD
    showloading() {
        this.load = true;
    }

    hideloading() {
        this.load = false;
    }

    /**
     * Método encargado de retornar el importe de la factura en formato string
     * @param importe 
     * @returns 
     */
    importeTostring(importe: string) {
        if (importe.split(".")[0] == "-") {
            return importe.split(".")[0] + "0." + importe.split(".")[1] + "€";
        } else {
            if (importe === null) {
                return "0.0 €";
            } else {
                return importe + " €";
            }
        }
    }

    /**
     * Retorna en el tipo de factura en string
     * @param type 
     * @returns 
     */

    typeFacturaString(type: string){
        let extType:string = "";
        switch (type){
            case 'FM':
                extType = "FACTURA MANUAL";
            break;
            case 'FB':
                extType ="FACTURA BONIFICADA";
            break;
            case 'FC':
            case 'SP':
                extType = "FACTURA CÍCLICA";
            break;

        }
        return extType;
    }

    /**
     * Evento encargado de retornar el base64 de una Factura
     * @param factura 
     */
    verFactura(factura) {
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            const body = {
                "numFact": factura.numfact,
                "year": factura.year,
                "month": parseInt(factura.mes) < 10 ? "0" + factura.mes : factura.mes,
                "type": factura.tipoFactura
            }
            this.facturaDetalle = null;
            this.showloading()
            const url = environment.verpdfFactura;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.facturaDetalle = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res[0].Factura);
                this.hideloading();
            }, error => {
                this.hideloading();
                this.facturaDetalle = null;
            });
        } catch {
            this.hideloading()
            this.facturaDetalle = null;
        }
    }

    /**
     * Evento encargado de cerrrar el modal en el que se visualiza una factura
     */
    closeInvoice() {
        this.facturaDetalle = null;
    }


}