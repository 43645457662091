import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ChangePageService } from 'src/app/services/change-page.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-lineafija',
    templateUrl: './lineafija.component.html',
    styleUrls: ['./lineafija.component.css']
})
export class LineafijaComponent implements OnDestroy, OnInit, AfterViewInit {

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject <void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    data: any;
    data2: any
    terceroaux: any;
    contratoaux: any;
    public load: Boolean = false;
    ter: any;
    con: any;
    linea = {
        tercero: '',
        contrato: ''
    };
    contrato = {
        username: '',
        password: ''
    };
    bloqueo = {
        'accion': '',
        'telefono': '',
        'usuario': '',
        'clave': '',
    };
    listContratos: any;
    selectContrato;
    constructor(private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private changePageService: ChangePageService,
        private cookie: CookieService,
    ) {
        var dataTable = $("#datatableFijo").dataTable();
        dataTable.DataTable().destroy;
    }
    ngAfterViewInit(): void {
        var dataTable = $("#datatableFijo").dataTable();
        dataTable.DataTable().destroy;
        this.dtTrigger.next();
    }


    ngOnDestroy(): void {
        if (this.dtTrigger) {
            this.dtTrigger.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.selectContrato = "0";
        this.getContratos();
        var dataTable = $("#datatableFijo").dataTable();
        dataTable.DataTable().destroy;

        if (this.changePageService.contratoFijoSeleccionado) {
            let splitted = this.changePageService.contratoFijoSeleccionado.split("|", 2);
            this.terceroaux = splitted[0];
            this.contratoaux = splitted[1];
            this.selectContrato = this.contratoaux + '|' + this.terceroaux;
            this.selectOption(this.contratoaux + '|' + this.terceroaux);
            // this.getlineas(this.terceroaux, this.contratoaux);
            this.changePageService.contratoFijoSeleccionado = null; //reset
        }
    }

    /**
     * Retorna un listado de contratos 
     */
    getContratos() {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        //__________________________________________Obteniendo contratos
        this.contrato = {
            "username": this.cookie.get("user"),
            "password": this.cookie.get("pass")
        }
        this.data2 = null;
        this.listContratos = null;
        const url2 = environment.getContratos;

        try {
            this.http.post(url2, this.contrato, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.listContratos = res;
                } else {
                    this.listContratos = [];
                    this.listContratos.push(res);
                }
            });
        } catch {
            this.hideloading();
        }
    }

    /**
     * Retorna un listado de lineas pertenecientes a un contrato y tercero
     * @param t tercero
     * @param c contrato
     */
    getlineas(t: any, c: any) {
        this.ter = t;
        this.con = c;
        this.showloading();
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            this.linea = {
                "tercero": t,
                "contrato": c
            }
            this.data = null;
            const url = environment.getLineasFijo;
            this.http.post(url, this.linea, { headers }).subscribe((res: any) => {
                (res.length) ? this.data = res:
                    this.updateDataTable();
                    // this.prueba();
                $('#datatableFijo').DataTable().ajax.reload();
                this.hideloading();
            }, error => {
                this.data = null;
                this.hideloading();
            });
        } catch {
            this.updateDataTable();
            this.hideloading();
        }
    }

    /**
     * Separa el valor del option en 2 partiendo del | y los introduce en un array
     * @param $event manda el evento cada vez que se elige una opcion
     */
    selectOption($event) {
        //getted from event
        var splitted = $event.split("|", 2);
        this.terceroaux = splitted[1];
        this.contratoaux = splitted[0];
        this.getlineas(this.terceroaux, this.contratoaux);
    }

    /**
     * evento encargado de cambiar de vista y retornar el consumo de una linea
     * @param telefono 
     */
    getConsumo(telefono: string) {
        this.changePage();
        // this.auth.gettelefono(telefono);
        this.cookie.set("telefono", telefono);
        this.router.navigate(['/consumofijo']);
    }

    /**
     * Evento encargado de cambiar de vista y retornar el detalle de una línea Fija
     * @param telefono 
     */
    getDetalle(telefono: string) {
        this.changePage();
        this.auth.gettelefono(telefono);
        this.router.navigate(['/detallelineafija'])
    }

    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }

    /**
     * Encargado de realizar el bloqueo sobre una línea
     * @param cond 
     * @param telefono 
     */
    getBloqueo(cond: any, telefono: any) {
        Swal.fire({
            title: 'MODIFICAR LÍNEA FIJA',
            text: "¿Está seguro de que desea cambiar el estado de la línea?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.showloading();
                try {
                    const headers = new HttpHeaders({
                        'user': this.cookie.get("user"),
                        'token': this.cookie.get("token"),
                        'pass': this.cookie.get("pass"),
                    });

                    if (cond == "B") {
                        this.bloqueo = {
                            'accion': "Bloquear",
                            'telefono': telefono,
                            'usuario': this.cookie.get("user"),
                            'clave': this.cookie.get("pass")
                        }
                        const url = environment.bloqueofija;
                        this.http.post(url, this.bloqueo, { headers }).subscribe((res: any) => {
                            this.data = res;
                            Swal.fire(
                                'Completado',
                                'La Línea fija ha sido modificada',
                                'success'
                            );
                            this.hideloading();
                            this.getlineas(this.ter, this.con);
                        });

                    } else if (cond == "D") {
                        this.bloqueo = {
                            'accion': "Desbloquear",
                            'telefono': telefono,
                            'usuario': this.cookie.get("user"),
                            'clave': this.cookie.get("pass")
                        }
                        const url = environment.bloqueofija;
                        this.http.post(url, this.bloqueo, { headers }).subscribe((res: any) => {
                            this.data = res;
                            Swal.fire(
                                'Completado',
                                'La Línea fija ha sido modificada',
                                'success'
                            );
                            this.hideloading();
                            this.getlineas(this.ter, this.con);
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Se ha producido un error al modificar la línea fija.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                    }
                } catch {
                    this.hideloading();
                }
            }
        })
    }

    /**
     * Cambia de vista y retorna los servicios de una linea Fija
     * @param telefono 
     */
    getServicios(telefono: string) {
        this.changePage();
        this.cookie.set("telefono", telefono);
        this.auth.gettelefono(telefono);
        this.router.navigate(['/servicios']);
    }

    /**
     * Actualización del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatableFijo").dataTable();
        dataTable.DataTable().destroy;
        $('#datatableFijo').DataTable({
            retrieve: true,
            search: true,
            paging: true,
            pageLength: 30
        });
        this.dtTrigger.next();
    }

    /**
     * Encargado de retornar la hora en formato hh:mm:ss
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: string) {
        let strHours: string = resHours;
        if (strHours.length > 5 && strHours.length < 7) {
            return strHours.substring(0, 2) + ":" + strHours.substring(2, 5) + ":" + strHours.substring(4, 6);
        } else {
            if (strHours.length > 4 && strHours.length < 6) {
                return "0" + strHours.substring(0, 1) + ":" + strHours.substring(1, 3) + ":" + strHours.substring(3, 5);
            } else {
                return resHours;
            }
        }
    }
    /**
     * Encargado de retornar la fecha en formato DD-MM-AAAA
     * @param dateString 
     * @returns 
     */
    dateTransform(dateString: string) {
        const DATE_TIME_FORMAT = 'DD-MM-YYYY';
        let date = String(parseInt(dateString) + 19000000);

        let year = date.substring(0, 4);
        let month = date.substring(4, 2)
        let day = date.substring(6, 2)
        return day + "-" + month + "-" + year;
    }

    /**
     * Método encargado de cambiar de panel
     */
    changePage() {
        this.changePageService.contratoFijoSeleccionado = this.terceroaux + "|" + this.contratoaux;
    }
}