import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-shared-group',
    templateUrl: './shared-group.component.html',
    styleUrls: ['./shared-group.component.css']
})
export class SharedGroupComponent implements OnInit {
    con: any;
    data;
    data2;
    linea;
    aux;
    contactoOficial;
    tercero;
    tarifasFamiliares;
    load;
    tarifaFamiliarSelected;
    @ViewChild('movil') movil: MatSidenav;
    @ViewChild('fijo') fijo: MatSidenav;
    @ViewChild('fibra') fibra: MatSidenav;
    @ViewChild('menu') settingsMenu: MatSidenav;
    lineasCliente;
    lineasAppendGroup = [];
    lineasAppendGroupPeticion = [];
    lineasExistentesEnOtrosGrupos: any = null;
    listPetCab = null;
    listPetCabAux = null;
    documentoCliente: string;

    constructor(
        private cookies: CookieService,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.lineasCliente = null;
        this.getContratos();
        this.lineasAppendGroup = null;
    }

    getlineas($event) {
        const splittedValues = $event.split('|');
        const evento = splittedValues[0];
        this.con = splittedValues[1];

        this.contactoOficial = evento;
        this.getGroupList(evento);
        this.getTarifasFamiliar(evento);
    }

    /**
     * devuelve el listado de contratos
     */
    getContratos() {
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            this.showloading();
            const url = environment.getContratosMovil;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                    this.aux = this.data2;
                    for (var i = 0; i < this.aux.length; i++) {
                        var con = this.aux[i];
                        this.tercero = con.id;
                    }
                } else {
                    this.data2 = [];
                    this.data2.push(res)
                    this.aux = this.data2;
                    for (var i = 0; i < this.aux.length; i++) {
                        var con = this.aux[i];
                        this.tercero = con.id;
                    }
                }
                this.hideloading();
            }, error => {
                this.hideloading();
            });
        } catch {
            this.hideloading();
        }
    }

    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }

    /**
     * Devuelve el listado de tarifas basadas en un contrato oficial
     * @param contratoOficial 
     */
    getTarifasFamiliar(contratoOficial) {
        try {
            this.tarifasFamiliares = null;
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            const body = {
                "contratoOficial": contratoOficial
            }

            let url = environment.getTarifaFamilar;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.tarifasFamiliares = [];
                    res.forEach(item => {
                        this.tarifasFamiliares.push({
                            cod: item.cod,
                            des: item.des.replace("@", "").replace("Tarifa Oroc", ""),
                            nom: item.nom.replace("@", "").replace("Tarifa Oroc", "")
                        })
                    })
                } else {
                    this.tarifasFamiliares = [];
                    this.tarifasFamiliares.push({
                        cod: res.cod,
                        des: res.des.replace("@", ""),
                        nom: res.nom.replace("@", "")
                    })
                }
            }, error => {
            })
        } catch {
        }
    }

    /**
     * Evento de selección de tarifa
     * @param $event 
     */
    selectTarifa($event) {

        var tarifa = $event.split(" @")[0];

        this.tarifaFamiliarSelected = tarifa.split(" ")[0].trim().replace(/\s/g, "");


        this.lineasAppendGroup.forEach(linea => {
            this.lineasCliente.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo })
        });
        this.lineasAppendGroup = []
    }

    /**
     * Busca y retorna las lineas moviles que posee un cliente
     * @param dni 
     */
    buscarLineas() {
        let documento: string = this.documentoCliente;
        let codigoTercero = this.cookies.get("tercero");
        const body = {
            "dniCuarto": documento.toUpperCase(),
            "tercero": codigoTercero,
            "contratoProvisional": this.con
        }
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const url = environment.checkMobileLines;
        this.showloading();
        this.lineasCliente = null;
              try {
                  this.http.post(url, body, { headers }).subscribe((res: any) => {
                      this.hideloading();
                      if (res.length == 0) { //______NO POSEE LINEAS
                          Swal.fire({
                              title: "INFO BÚSQUEDA",
                              icon: "warning",
                              text: "El cliente introducido no tiene líneas asociadas."
                          });
                          return;
                      }
                      this.lineasCliente = [];                    
                      if (res.length == 1) {
                          if (res[0].tarifa.indexOf("TBC_") != -1 && res[0].telf != "0" && (this.lineasExistentesEnOtrosGrupos != null && !this.lineasExistentesEnOtrosGrupos.includes(res[0].telf))) {
                              this.lineasCliente.push(res[0]);
                          }
                      }
                      //&& this.lineasExistentesEnOtrosGrupos.includes(element.telf) == false
                      res.forEach(element => {
                          this.lineasCliente.push(element);
                          // if (!this.lineasExistentesEnOtrosGrupos.includes(element.telf) && element.telf != "0" && element.tarifa.indexOf("TBC_") != -1) {
                          //     this.lineasCliente.push(element);
                          // }
                      });
                      if (!this.lineasCliente.length) {
                          this.lineasCliente = null;
                          Swal.fire({
                              title: "INFO BÚSQUEDA",
                              icon: "warning",
                              text: "El cliente introducido no tiene líneas asociadas."
                          });
                          return;
                      }
                  }, error => {
                      this.hideloading();
                  })
              } catch {
                  this.hideloading();
          }
    }
    /**
     * Retorna un listado de tarifas que pertenecen a un determinado Grupo
     * @param contrato 
     */
    getGroupList(contrato: String) {
        // this.lineasCliente = null
        this.lineasExistentesEnOtrosGrupos = null;
        this.listPetCab = null;
        this.listPetCabAux = null;

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        let body = {
            "contrato": contrato
        }
        const url = environment.getListSharedGroup;
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    this.lineasExistentesEnOtrosGrupos = [];
                    this.listPetCab = [];
                    this.listPetCabAux = [];

                    res.forEach(element => {
                        this.lineasExistentesEnOtrosGrupos.push(element.NUMERO);
                    });
                    this.hideloading();
                }
                this.hideloading();
            })
        } catch {
            this.lineasExistentesEnOtrosGrupos = null;
            this.listPetCab = null;
            this.listPetCabAux = null;
            this.hideloading();
        }
    }

    /**
     * Añade lineas al grupo
     * @param telefono 
     * @param titular 
     */
    appendGroup(linea) {
        if (this.lineasAppendGroup == null) { this.lineasAppendGroup = [] }
        if (this.lineasAppendGroupPeticion == null) { this.lineasAppendGroup = [] }



        if (this.tarifaFamiliarSelected.includes("DIS_DUO")) {

            if (this.lineasAppendGroup.length < 2) {
                this.lineasCliente = this.lineasCliente.filter(item => item.telf != linea.telf);
                this.lineasAppendGroup.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo })
                this.lineasAppendGroupPeticion.push({ linea: linea.telf, peticion: linea.pet, peticionCabecera: "0" })
            } else {
                Swal.fire({
                    title: "ESTADO DEL GRUPO",
                    icon: "warning",
                    text: "No es posible añadir más líneas al grupo. El límite es de 2 líneas."
                });
            }

        } else {
            if (this.lineasAppendGroup.length < 3) {
                this.lineasCliente = this.lineasCliente.filter(item => item.telf != linea.telf);
                this.lineasAppendGroup.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo })
                this.lineasAppendGroupPeticion.push({ linea: linea.telf, peticion: linea.pet, peticionCabecera: "0" })
            } else {
                Swal.fire({
                    title: "ESTADO DEL GRUPO",
                    icon: "warning",
                    text: "No es posible añadir más líneas al grupo. El límite es de 3 líneas."
                });
            }
        }
    }

    /**
     * Quita lineas que no deseamos añadir al grupo
     * @param linea 
     */
    removeLinesGroup(linea) {
        this.lineasAppendGroup = this.lineasAppendGroup.filter(item => item.telf != linea.telf);
        this.lineasAppendGroupPeticion = this.lineasAppendGroupPeticion.filter(item => item.linea != linea.telf);
        this.lineasCliente.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo })
    }

    /**Lanza el grupo de lineas 
     */
    launchCreateGroup() {
        if (this.lineasAppendGroupPeticion.length == 0) {
            Swal.fire({
                title: "INFO GRUPO",
                icon: "error",
                text: "Error al realizar la operación.",
            });
            return;
        }

        const body = {
            "contratoOficial": this.contactoOficial,
            "lineas": this.lineasAppendGroupPeticion,
            "tarifa": this.tarifaFamiliarSelected,
        }

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.startGroupLines;

        this.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (this.checkAddLines(res)) { //Se creó el grupo correctamente
                    Swal.fire({
                        title: "INFO GRUPO",
                        icon: "success",
                        text: "Líneas añadidas correctamente.",
                    });

                    this.lineasCliente = null;
                    this.lineasAppendGroup = null;

                } else { //No se pudo crear el grupo
                    Swal.fire({
                        title: "INFO GRUPO",
                        icon: "warning",
                        text: "Error:" + res[0].error,
                    });
                }
                this.hideloading();
            }, error => {
                Swal.fire({
                    title: "INFO GRUPO",
                    icon: "warning",
                    text: "Las líneas seleccionadas no se pueden añadir al grupo.",
                });
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Checkea la respuesta al añadir las líneas
     * @param array 
     * @returns 
     */
    checkAddLines(array): Boolean {
        try {
            var flag = false;
            let length = array.length;
            if (array[length - 1] == "Grupo creado correctamente." || array[length - 1].peticion.length) {
                flag = true;
            }

            return flag; //No hubo problemas al añadir las lineas al grupo
        } catch {
            return false
        }
    }
}