import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';

@Component({
    selector: 'app-upload-fijo-docs-page',
    templateUrl: './upload-fijo-docs-page.component.html',
    styleUrls: ['./upload-fijo-docs-page.component.css']
})
export class UploadFijoDocsPageComponent implements OnInit {
    load: boolean;
    subirDocumentacion = false;
    telefonoSelected = null;
    public verOpciones = true;
    clienteEncontrado = false;
    public listArchFromServe: any;
    formFiles: UntypedFormGroup;
    @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();

    constructor(private formBuilder: UntypedFormBuilder,
        private cookie: CookieService,
        public loadingService: LoadingServiceService,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.resetValues();
    }

    //__________________________________________CACHEAR_DOCUMENTO
    setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) { //SI SE EXCEDE LOS 5MB
            Swal.fire({
                title: "ARCHIVO DEMASIADO GRANDE",
                icon: "warning",
                text: "El archivo supera el límite de 5Mb."
            });
            // this.loadModal = false;
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);

        let terceroOperador = this.cookie.get("tercero");
        let telefono = this.cookie.get("telefono");
        reader.onload = () => {
            switch (t) {
                default:
                    let doc = reader.result;
                    let nombre = file.name.split(".")[0];
                    let ext = file.name.split(".").pop();
                    this.uplodaDocuments(doc, ext, terceroOperador, telefono, nombre);
                break;
            }
        };
    }

    /**
     * Método encargado de subir al servicor documentacion del cliente bien sea en formato imagen o documento pdf
     * @param docFile base64 del fichero
     * @param extFile extension del fichero sin el punto -> jpg, png, pdf
     * @param nameFile nombre del fichwero sin la extension
     * @param datosPersonales nif del cliente
     */
    uplodaDocuments(docFile, extFile, tercero, telefono, nombreDoc) {
        let url = environment.uplodadDocsFijo;

        this.showloading();
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token"),
            "pass": this.cookie.get("pass")
        });

        this.showloading();
        const body = {
            "tipoMime": extFile,
            "tel": telefono,
            "nombre": nombreDoc,
            "contenido": docFile,
            "codTercero": tercero
        }

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading();
                if (res.Status == "OK") {
                    this.loadingService.messageDocumentacion = null;
                    this.formFiles.reset();
                    Swal.fire({
                        title: "RESULTADO CORRECTO",
                        icon: "success",
                        text: "La operación se ha realizado con éxito."
                    });
                    // this.loadDocumentationDateNow(tercero, cuarto);
                } else {
                    this.createWarningAlert("El archivo supera el límite de 5Mb.", "ARCHIVO DEMASIADO GRANDE");
                }
            }, (error: any) => { //VALIDACION DE LA SUBIDA DE DOCUMENTOS POSIBLES ERRORES
                this.hideloading();
                if (error.error.message == "Falta el parametro DocumentBase64") {
                    this.createWarningAlert(error.error.message == "Falta el parametro DocumentBase64" ? "Debe subir un archivo." : error.error.message, "OPERACIÓN FALLIDA");
                } else if (error.error.message == "Falta el parametro codigoCliente") {
                    this.createWarningAlert(error.error.message == "Falta el parametro codigoCliente" ? "Debe seleccionar un cliente." : error.error.message, "OPERACIÓN FALLIDA");
                } else {
                    this.createWarningAlert(error.error.message, "OPERACIÓN FALLIDA");
                }
            })
        } catch {
            this.hideloading();
        }
    }

    initForm() {
        this.formFiles = this.formBuilder.group({
            'fileDocIdentidad': ['', [Validators.required]],
            'fileCopia': ['', [Validators.required]],
            'portabilidad': ['', [Validators.required]],
            'fileGrabacion': ['', [Validators.required]],
            'telefono': ['', [Validators.required]]
        });
    }

    showloading() {
        this.load = true;
    }

    hideloading() {
        this.load = false;
    }

    /**
     * Método para devolver la fecha actual en Formato 
     * @returns DD_MM_YYYY_hh_mm_ss actual del sistema
     */
    dateNowToString() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "_" + mes + "_" + date.getFullYear() + "_" + date.getHours() + "_" + date.getMinutes() + "_" + date.getSeconds();
    }

    uploadDocumentacion() {
        // this.verDocumentacion = false;   
        this.subirDocumentacion = true;
    }

    /**
     * Reinicializa el componente si el padre lo ordena
     */
    resetValues() {
        this.resetFormSubject.subscribe(response => {
            if (response) {
                this.subirDocumentacion = false;
                // this.verDocumentacion = false;
                this.verOpciones = true;
            }
        })
    }

    findNumber(value) {
        if (value.length == 9) {
            //No seleccionó un numero Fijo
            if (value.substring(0, 1) != "8" && value.substring(0, 1) != "9") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                return;
            }
            this.showloading();
            let codigoTercero = this.cookie.get("tercero")
            this.telefonoSelected = value;
            const body = {
                "tel": value,
                "codTercero": codigoTercero
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });
            const url = environment.getDocsFijo;
            this.showloading();
            this.listArchFromServe = null;

            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.listArchFromServe = null;
                    this.hideloading();
                    if (res.length) { //si hay archivos
                        this.clienteEncontrado = true;
                        Swal.fire({
                            icon: "success",
                            text: "El número ya tiene documentación."
                        });
                        this.cookie.set("telefono", this.telefonoSelected);
                    } else {
                        //no hay archivos
                        Swal.fire({
                            icon: "warning",
                            text: "El número buscado no dispone de documentación."
                        });
                        this.clienteEncontrado = true;
                        this.cookie.set("telefono", this.telefonoSelected);
                    }
                }, error => {
                    //no hay archivos
                    this.hideloading();
                    Swal.fire({
                        icon: "warning",
                        text: "El número buscado no dispone de documentación."
                    });
                    this.clienteEncontrado = true;
                    this.cookie.set("telefono", this.telefonoSelected);
                })
            } catch {
                this.hideloading();
                this.clienteEncontrado = null;
            }
        }
    }

    createWarningAlert(text, title=""){
        Swal.fire({
            title: title,
            icon: "warning",
            text: text,
        });
    }
}