import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';

@Component({
    selector: 'app-advanced-find',
    templateUrl: './advanced-find.component.html',
    styleUrls: ['./advanced-find.component.css']
})
export class AdvancedFindComponent implements OnInit, OnDestroy, AfterViewInit {
    advancedForm: UntypedFormGroup;
    loadModal: Boolean;
    data: any;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private cookie: CookieService,
        private http: HttpClient,
        private router: Router,
        private loadService: LoadingServiceService
    ) {}

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    ngOnInit(): void {
        this.initFormGroup()
        var dataTable = $("#datatableAdvanced").dataTable();
        dataTable.DataTable().destroy
    }

    ngAfterViewInit(): void {
        var dataTable = $("#datatableAdvanced").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
    * Inicializacion y validación del formulario
    */
    initFormGroup() {
        this.advancedForm = this.formBuilder.group({
            'telf': ['', [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(9),
                Validators.pattern(/^[6||7]\d{8}$/gm)
            ]]
        })
    }

    /**
     * Evento de busqueda de detalles de una línea Movil
     * @param value 
     * @returns 
     */
    async findNumber(value) {
        if (value.length == 9) {
            if (value.substring(0, 1) != "6" && value.substring(0, 1) != "7") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                return
            }
            await this.showLoadModal()
            let codigoTercero = this.cookie.get("tercero")
            const body = {

                "telefono": value,
                "tercero": codigoTercero
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });
            const url = environment.advancedFind
            this.showLoadModal();
            this.data = null
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    if (res.id) { //_________INVALIDO
                        Swal.fire({
                            title: "INFO DE BÚSQUEDA AVANZADA",
                            icon: "warning",
                            text: res.id
                        })
                    } //_______________END INVALIDO
                    else {
                        this.data = res;
                        this.updateDataTable()
                    }
                    this.hiddeModal()
                }, error => {
                    console.log(error);
                    this.hiddeModal()
                })
            } catch {
                this.hiddeModal()
            }
            // Swal.fire({
            //   title:"NUMERO INVALIDO",
            //   icon:"warning",
            //   text:"El número introducido no es válido para la busqueda"
            // })
        }
    }

    //______________________________MODAL ________________________________

    showLoadModal() {
        this.loadModal = true
    }
    hiddeModal() {
        this.loadModal = false
    }

    //______________________________ END MODAL ________________________________
    /**
     * Actulaización del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatableAdvanced").dataTable();
        dataTable.DataTable().destroy
        $('#datatableAdvanced').DataTable({
            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    /**
     * Ecargado de setear cookies y pasar los parametros a la página AccionesLineasMóviles
     * @param peticion 
     * @param activacion 
     * @param tarifa 
     * @param titular 
     * @param dni 
     * @param numero 
     * @param multisim 
     * @param cambio 
     * @param hora 
     * @param dobleGb 
     * @param NextTarifa 
     * @param fechaActivacion 
     * @param contratoOf 
     * @param cprovisional 
     */
    setLinea(peticion, activacion, tarifa, titular, dni, numero, multisim, cambio, hora, dobleGb, NextTarifa, fechaActivacion, contratoOf, cprovisional,bloqueoPorRobo) {
        this.cookie.set("peticion", peticion);
        this.cookie.set("activacion", activacion);
        this.cookie.set("tarifa", tarifa);
        this.cookie.set("titular", titular);
        this.cookie.set("dni", dni);
        this.cookie.set("numero", numero);
        // this.cookie.set("multisim", multisim);
        this.cookie.set("cambio", cambio);
        this.cookie.set("hora", this.timeTransform(hora));
        this.cookie.set("contratoF", contratoOf);
        this.cookie.set("dobleGb", dobleGb);
        this.cookie.set("nextTarifa", NextTarifa);
        this.cookie.set("activacion", fechaActivacion);
        this.cookie.set("multisim", multisim);
        this.cookie.set("cprovisional", cprovisional);
        this.cookie.set("robo",bloqueoPorRobo);
        (this.cookie.get("nextTarifa") == "") ? this.loadService.cambioTarifaEnCurso = false: this.loadService.cambioTarifaEnCurso = true

        //Para info SIM
        // this.cookie.set("tercero", this.tercero);        
        this.router.navigate(['accioneslineamovil'], { queryParams: { con: cprovisional }});
    }

    /**
     * Evento para ver los servicios móviles que posee una línea
     * @param peticion 
     * @param numero 
     */
    servicios(peticion, numero) {
        this.cookie.set("peticion", peticion);
        this.cookie.set("numero", numero);
        this.router.navigate(['serviciosmovil']);
    }

    /**
     * FUNCION ENCARGADA DE RETORNA LA HORA EN FORMATO LEGIBLE POR EL USUSARIO HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: string) {
        if (resHours.length > 5 && resHours.length < 7) {
            return resHours.substr(0, 2) + ":" + resHours.substr(2, 2) + ":" + resHours.substr(4, 2);
        } else {
            if (resHours.length > 4 && resHours.length < 6) {
                return "0" + resHours.substr(0, 1) + ":" + resHours.substr(1, 2) + ":" + resHours.substr(3, 2);
            } else {
                return resHours;
            }
        }
    }
}