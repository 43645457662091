import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ComentarIncidenciaComponent } from '../comentar-incidencia/comentar-incidencia.component';
import { AsignaTecnicosService } from 'src/app/services/asigna-tecnicos.service';
import { ModalAsignaHorasComponent } from '../modal-asigna-horas/modal-asigna-horas.component';
import { DataTableDirective } from 'angular-datatables';
import { CreateIncidenciasModalComponent } from '../create-incidencias-modal/create-incidencias-modal.component';
@Component({
  selector: 'app-incidencias-list',
  templateUrl: './incidencias-list.component.html',
  styleUrls: ['./incidencias-list.component.css']
})
export class IncidenciasListComponent implements OnInit {
  public incidencias: any;
  public error: string = 'No existen incidencias para este contrato';
  public loading: boolean = true;
  comentariosModelRef: any;
  modalAsignaHora: any
  dtOptions = {
    dom: 'frtip',//solo busqueda
    language: {
      url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
    },
    info: false,
    retrieve: true

  };
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private marcaBlanca: PeticionServiceService,
    private dialog: MatDialog,
    private asignaTec: AsignaTecnicosService,
  ) { }

  ngOnInit(): void {
    this.getIncidencias();
  }

  pruebaModelo() {
    this.dialog.open(CreateIncidenciasModalComponent, {
      height: '70vh',
      width: '60vw',
      data:{
        "contrato_oficial":this.data.contrato
      }
    });
  }
  
  async getIncidencias() {
    await this.marcaBlanca.peticionMarca(environment.visualizarIncidenciasMarcaBlanca + this.data.contrato, 'get', {}, {}).then(
      (data: any) => {
        this.incidencias = data.detalle.Incidencia;
        if (!Array.isArray(this.incidencias)) {
          if (this.incidencias == undefined) {
            this.incidencias = null;
          } else {
            this.incidencias = [this.incidencias];
          }
        }
        this.toogleLoading();
      },
      (error: any) => {
        this.incidencias = null;
        this.error = error.error.message;
        this.toogleLoading();
      }
    );
  }
  addComentario(incidencia: string) {
    this.comentariosModelRef = this.dialog.open(ComentarIncidenciaComponent, {
      height: '35vh',
      width: '50vw',
      data: {
        'incidencia': incidencia
      }
    });
  }
  toogleLoading() {
    this.loading = !this.loading;
  }

  async  obtenerTecnico(numInci,fecha,hora){
    let asignaHoras=false;

    if (parseInt(hora) == 0 && parseInt(fecha) == 0) {
      asignaHoras = true
    }

    let param = {
      "cod_provincia": this.data.cod_provincia,
      "cod_municipio": this.data.cod_municipio,
      "cod_calle": this.data.cod_calle,
      "finca": this.data.finca,
      "bis": this.data.vis,
      "fecha_vigencia": this.data.fecha_vigencia
    }

    
    await this.asignaTec.obtenerTecnico(param).then((data:any)=>{
      
    }).catch((err:any)=>{
        console.log(err);
        
    })
    this.modalAsignaHora = this.dialog.open(ModalAsignaHorasComponent, {
      height: '40vh',
      width: '50vw',
      data: {
        incidencia: numInci,
        fecha: fecha,
        hora: hora,
        asignaHoras: asignaHoras
      }
    })
  }
}
