import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-consumofijo',
    templateUrl: './consumofijo.component.html',
    styleUrls: ['./consumofijo.component.css']
})
export class ConsumofijoComponent implements OnInit {
    uDetails: user;
    data;data2: any;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };
    dtTrigger: Subject<void> = new Subject<void>();
    aux: any
    public load: Boolean = false;
    // tlf: any
    // linea = {
    //     telefono: ''
    // };

    constructor(private http: HttpClient,
        private auth: AuthService, private cookie: CookieService) {}

    ngOnInit(): void {
        // this.tlf = this.auth.tlf
        // this.uDetails = this.auth.uLoged;
        this.getconsumo()
    }
        //__________________________________________OBTENIENDO DETALLES DE CONSUMO
    async getconsumo() {
        await this.showloading()
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });                       

            var linea = {
                "telefono": this.cookie.get('telefono')
            }
            const url = environment.getConsumofijo;
            this.http.post(url, linea, { headers }).subscribe((res: any) => {
                this.data = res;
                this.aux = res;
                for (var i = 0; i < this.aux.length; i++) {
                    var con = this.aux[i];
                }
                this.dtTrigger.next();
                this.hideloading()
            });
        } catch {
            await this.hideloading()
        }
    }

    //_______________________________________________MOSTRAMOS EL LOADING
    showloading() {
        this.load = true
    }
    //_______________________________________________OCULTAMOS EL LOADING
    hideloading() {
        this.load = false
    }
}