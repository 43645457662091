//______________________________________________________________________FORMAT DATEPICKER________________________________________________________________________________________
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            let day: string = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            let year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return date.toDateString();
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

//_____________________________________________________________________END FORMAT DATEPICKER____________________________________________________________________________



import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { IpServiceService } from 'src/app/ip-service.service';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { RepositoryDocumentationService } from 'src/app/services/repository-Documentation.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import { LoadingServiceService } from '../loading-service.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create-client',
    templateUrl: './create-client.component.html',
    styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {


    poblacion;
    provincia;
    codigopostal;
    pais: any;
    data4;
    data5: any
    direccion: string;
    title = 'rou';
    //Local Variable defined
    formattedaddress = " ";
    options = {
        componentRestrictions: {
            country: ["ES"]
        },
        types: ['address']
    }
    uDetails: user;
    iccresponse: any
    public country: Boolean = false;
    public load: Boolean = false;
    public prepago: Boolean = false;
    public coste: Boolean = false;
    public portar: Boolean = false;
    public iccres: Boolean = false;
    public iccresdos: Boolean = false;
    registroForm: UntypedFormGroup;
    data;
    data2;
    data3;
    data6;
    data7: any;
    // registrolineaform: FormGroup;
    documentosGroup: UntypedFormGroup;
    aux: any;
    nif;
    tiponif;
    idcliente;
    nombre;
    apellido1;
    apellido2;
    fechanac;
    tipocliente: any;
    contratoOficial;
    cuarto;
    tecnologia;
    portabilidad;
    numero;
    tarifa;
    bono: any;
    doc1;
    doc2;
    doc3: any;
    tipo1;
    tipo2;
    tipo3: any;
    nombre1;
    nombre2;
    nombre3: any;
    paisGuardado: any;
    encontrado;
    msglinea: boolean;
    listArchivosServer: any
    terceroaux;
    contratoaux;
    cuartoaux: any;
    isDisableSelectPais: boolean;
    disablebtnBuscarNif: boolean;
    isDisabledBuscarLineas: boolean;
    cuartoCliente: string
    numeroCalle: string;
    clienteEncontrado = false





    Tarifa = {
        tercero: '',
        contratoP: ''
    }

    contrato = {
        username: '',
        password: '',
    };

    setlinea = {
        "tercero": '',
        "contratoProvisional": '',
        "tarifa": '',
        "portabilidad": '',
        "iccNuevo": '',
        "cuarto": '',
        "iccPortabilidad": '',
        "telefono": '',
        "bonoMinutos": '',
        "bonoMegas": ''
    }

    setDocument = {
        "nombreArchivo": '',
        "codigoTercero": '',
        "contenido": '',
        "codigoCuarto": '',
    }

    setusuario = {
        "nif": '',
        "tiponif": '',
        "idcliente": '',
        "tipocliente": '',
        "nombre": '',
        "apellido1": '',
        "apellido2": '',
        "fechanac": '',
        "direccion": '',
        "codigopostal": '',
        "poblacion": '',
        "provincia": '',
        "ip": '',
        "pais": ''
    };


    MY_FORMATS = {
        parse: {
            dateInput: 'LL'
        },
        display: {
            dateInput: 'YYYY-MM-DD',
            monthYearLabel: 'YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'YYYY'
        }
    };
    ext1: any;
    ext2: any;
    ext3: any;
    mesSelectedFile: number;
    yearSelectedFile: number
    archBase64FromServe: any;
    hayCopiaDelContrato: boolean = false;
    hayCopiaDelDni: boolean = false;
    currentTime = new Date().valueOf();
    ocultarAntigua1sim = true;
    puedeExportarse = false;
    paisModel
    clientecreado = false

    constructor(public repositoryService: RepositoryDocumentationService,
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private datePipe: DatePipe,
        private cookie: CookieService,
        private ip: IpServiceService,
        private loading: LoadingServiceService,
        private sanitizer: DomSanitizer

    ) {
        this.encontrado = false;
        this.repositoryService.listArchFromServe = null;



    }


    ipAddress: string;
    public listLineasMobiles: any
    public loadModal: boolean

    numerotlf: UntypedFormControl
    ngOnInit(): void {







        this.getPais();
        this.paisModel = "0"
        this.getIP(); //Registramos la ip del cliente
        this.uDetails = this.auth.uLoged;
        this.validacionDeFormularios()


        //__________________________________________Obteniendo contratos
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        this.contrato = {
            "username": this.cookie.get("user"),
            "password": this.cookie.get("pass")
        }

        const url2 = environment.getContratosMovil;
        /* environment.getContratos */
        var currentIp = (this.ipAddress) ? currentIp = this.ipAddress : currentIp = "255.255.255.0";
        let body = {
            ip: currentIp
        }
        this.http.post(url2, body, { headers }).subscribe((res: any) => {
            if (Array.isArray(res)) {
                this.data2 = res;
                this.aux = this.data2;
                for (var i = 0; i < this.aux.length; i++) {
                    var con = this.aux[i];
                }
            } else {
                this.data2 = [];
                this.data2.push(res)
                this.aux = this.data2;
                for (var i = 0; i < this.aux.length; i++) {
                    var con = this.aux[i];
                }
            }
        }, error => {
            console.log(error);

        });
    }


    //__________________________________________CACHEAR_DOCUMENTO
    setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) { //SI SE EXCEDE LOS 5MB

            Swal.fire({
                title: "Resultado de la Subida",
                icon: "warning",
                text: "Supera Los limites de 5Mb"
            })
            this.loadModal = false;
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let terceroOperador = this.cookie.get("tercero");
        let telefono = this.cookie.get("telefono");




        reader.onload = () => {



            switch (t) {
                case "DocIdentidad":

                    this.doc1 = reader.result;
                    this.nombre1 = file.name.split(".")[0];
                    this.ext1 = file.name.split(".").pop();

                    this.hayCopiaDelDni = true;
                    this.repositoryService.uplodaDocuments(this.doc1, this.ext1, terceroOperador, telefono, this.nombre1);

                    break;
                case "Copia":
                    this.doc2 = reader.result;
                    this.nombre2 = file.name.split(".")[0];
                    this.ext2 = file.name.split(".").pop();
                    this.hayCopiaDelContrato = true;


                    this.repositoryService.uplodaDocuments(this.doc2, this.ext2, terceroOperador, telefono, this.nombre2);


                    break;
                case "grabacion":
                    this.doc3 = reader.result;
                    this.nombre3 = file.name.split(".")[0];
                    this.ext3 = file.name.split(".").pop();

                    this.repositoryService.uplodaDocuments(this.doc3, this.ext3, terceroOperador, telefono, this.nombre3);

                    break;


            }
        };
    }


    /**
     * Evento encargado de retornar el contrato para ser firmado por el cliente
     * @param nameFile 
     * @returns 
     */
    exportarContratoMovilEnPdf(nameFile) {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });



        const url = environment.getContratoSubrrogacion

        try {
            this.http.get(url, { headers }).subscribe((res: any) => {

                this.downloadData(res.fileBase64, nameFile + ".pdf");

            }, error => {
                this.hiddeLoadModal();
                console.log(error);


            })
        } catch {
            this.hiddeLoadModal();
        }




    }

    /**
     * Descarga del contrato
     * @param base64 
     * @param nombre 
     */
    downloadData(base64, nombre) {

        const linkSource = "data:application/pdf;base64," + base64;

        let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        var a = document.createElement("a"); //Create <a>
        a.href = linkSource; //Image Base64 Goes here
        a.download = nombre; //File name Here
        a.click(); //Downloaded file

    }
    /**
     * Util para devolver la fecha de nacimiento en formato DD/MM/YYYY
     * @param DateToFormat Fecha en formato String a formatear
     * @returns 
     */
    formatingFecha(DateToFormat) {
        let date = new Date(DateToFormat);

        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "/" + mes + "/" + date.getFullYear();

    }


    /**
     * Método para devolver la fecha actual en Formato 
     * @returns DD/MM/YYYY actual del sistema
     */
    dateNow() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "/" + mes + "/" + date.getFullYear();
    }



    //_________________________________VALIDADOR_DE_NIF
    isNif(documento) {
        var nif = documento;
        var lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
        var isNif = false;
        if (nif.length == 9) {
            var letter = nif.substring(8, 9);
            var number = parseInt(nif.substring(0, 8));
            if (!isNaN(number) && isNaN(parseInt(letter))) {
                if (lettersEnd[number % 23] === letter) {
                    isNif = true;
                }
            }
        }
        return isNif;
    }

    //_________________________VALIDADOR_DE_NIE
    isNie(documento) {
        var nie = documento; //Z4138590K
        var isNie = false;
        var i;
        var nieRegex = /^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]/
        var num = [];
        if (nieRegex.test(nie)) {
            for (i = 0; i < 9; i++) {
                var aux = i;
                num[i] = nie.substr(i, aux++);
            }
            var letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
            var dniTransform = nie.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
            if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
                isNie = true;
            }
        }
        return isNie;
    }

    //_________________________VALIDADOR_DE_CIF
    validaCif(documento) {
        var isCif = false;
        if (documento == "") {
            isCif = false;
            return isCif;
        }
        var texto = documento;
        var pares = 0;
        var impares = 0;
        var suma;
        var ultima;
        var unumero;
        var uletra = new Array("J", "A", "B", "C", "D", "E", "F", "G", "H", "I");
        var xxx;

        texto = texto.toUpperCase();

        var regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g);
        if (!regular.exec(texto)) { }

        ultima = texto.substr(8, 1);

        for (var cont = 1; cont < 7; cont++) {
            xxx = (2 * parseInt(texto.substr(cont++, 1))).toString() + "0";
            impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
            pares += parseInt(texto.substr(cont, 1));
        }
        xxx = (2 * parseInt(texto.substr(cont, 1))).toString() + "0";
        impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

        suma = (pares + impares).toString();
        unumero = parseInt(suma.substr(suma.length - 1, 1));
        unumero = (10 - unumero).toString();
        if (unumero == 10) unumero = 0;

        if (ultima == unumero || ultima == uletra[unumero]) {
            isCif = true;
            return isCif;
        } else {
            isCif = false;
            return isCif;
        }
    }

    //_______________________________________________Loading
    showloading() {
        this.loadModal = true
    }
    hideloading() {
        this.loadModal = false
    }

    //_________________________________CREAR_USUARIO_CON_VALIDACION_NIF/CIF/NIE_Y_VALIDACIÓN_DE_FECHA
    formatDate() {
        var d = new Date(),
            month = (d.getMonth() + 1).toString(),
            day = d.getDate().toString(),
            year = (d.getFullYear() - 18).toString();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var fecha = year + month + day;

        return fecha;

    }

    //_________________________EXTRACTOR_DEL_TIPO_DE_CLIENTE
    guardaTipoCliente(letra: string) {
        this.tipocliente = letra;
    }

    //_________________________CREAR/EDITAR_USUARIOS
    async crearUsuario(llamada: any) {
        var paiscode


        if (this.registroForm.get('tiponif').value == "N" || this.registroForm.get('tiponif').value == "C") {
            paiscode = 0
        } else if (this.registroForm.get('tiponif').value == "P") {
            paiscode = this.registroForm.get('pais').value
        }


        if (this.formatDate() > this.datePipe.transform(this.registroForm.get('fechanac').value, 'yyyyMMdd')) {
            if (!this.cookie.get("ip")) {

            }

            var valor = true;
            var tipo = this.registroForm.get('tiponif').value;

            switch (tipo) {
                case 'N':
                    var valorNIF = this.isNif(this.registroForm.get('nif').value);

                    var valorNIE = this.isNie(this.registroForm.get('nif').value);

                    if ((valorNIF === true) || (valorNIE === true)) {
                        valor = true;
                    }
                    break;

                case 'C':
                    var valorCIF = this.validaCif(this.registroForm.get('nif').value);

                    if (valorCIF === true) {
                        valor = true;
                    }
                    break;

                case 'P':
                    var valorOTRO = this.registroForm.get('nif').value;
                    if (valorOTRO.length <= 30) {
                        valor = true;
                    } else {
                        valor = false;
                    }
                    break;

                default:
                    break;
            }

            if (valor === true) {
                // let promise = new Promise < void > ((resolve, reject) => {
                this.data = {
                    nif: this.registroForm.get('nif').value,
                    tipoNif: this.registroForm.get('tiponif').value,
                    tercero: this.cookie.get("tercero"),
                    contratoProvisional: this.contratoaux,
                    nombre: this.registroForm.get('nombre').value,
                    apellido1: this.registroForm.get('apellido1').value,
                    apellido2: this.registroForm.get('apellido2').value,
                    fechaNacimiento: this.datePipe.transform(this.registroForm.get('fechanac').value, 'yyyy/MM/dd'),
                    direccion: this.registroForm.get('direccion').value,
                    codigopostal: this.registroForm.get('cp').value,
                    poblacion: this.registroForm.get('poblacion').value,
                    provincia: this.registroForm.get('provincia').value,
                    // direccion: this.direccion + this.cp,
                    tipocliente: this.registroForm.get('tipocliente').value,
                    pais: this.paisModel
                }

                const headers = new HttpHeaders({
                    'user': this.cookie.get("user"),
                    'pass': this.cookie.get("pass"),
                    'token': this.cookie.get("token")
                });

                const url = environment.setCliente;

                if (!this.encontrado || llamada == 2) {
                    this.showloading()
                    try {
                        this.http.post(url, this.data, { headers }).subscribe(
                            (res: any) => { // Success
                                this.hideloading()
                                this.data3 = res;
                                if (this.data3.error) {
                                    console.log(res.error);
                                    if (res.error.indexOf("Ya existe codigo para este cliente:") != -1) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'ERROR',
                                            text: 'El usuario ' + this.nombre + ' ' + this.apellido1 + ' ' + this.apellido2 + ' ya existe en el sistema.',
                                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                            background: '#F5F5F5',
                                        })
                                        return;
                                    }
                                    if (llamada == 1) { //______________________________________________CREACION
                                        this.documentosGroup.enable(); //Listo para subir documentacion
                                        this.puedeExportarse = true; //Puede exportarse en pdf para ser firmado

                                        Swal.fire({
                                            icon: 'success',
                                            title: 'COMPLETADO',
                                            text: 'El usuario ' + this.nombre + ' ' + this.apellido1 + ' ' + this.apellido2 + ' ha sido registrado correctamente.',
                                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                            background: '#F5F5F5',
                                        })

                                        this.clientecreado = true


                                    }


                                    // else {
                                    //     this.documentosGroup.enable(); //Listo para subir documentacion
                                    //     this.puedeExportarse = true; //Puede exportarse en pdf para ser firmado
                                    //     Swal.fire(

                                    //         'Completado',
                                    //         'Usuario ' + this.nombre + ' ' + this.apellido1 + ' ' + this.apellido2 + 'creado correctamente.',
                                    //         'success'
                                    //     )
                                    // }
                                    // this.clientecreado = true


                                }

                            },
                            (error: any) => { // Error

                                var messageError = ""
                                this.hideloading()
                                console.log(error);
                                this.clientecreado = false

                                Swal.fire({
                                    icon: 'error',
                                    title: 'ERROR',
                                    text: "Faltan campos por rellenar del formulario. Revise los datos.",
                                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                    background: '#F5F5F5',
                                })
                            }

                        );
                    } catch {
                        this.hideloading();
                        // console.error(exception)
                    }

                } else { //________________________USUARIO EXISTE
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'El usuario ya existe.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    })
                }



            } else { //________________DOCUMENTO IDENTIDAD INVÁLIDO
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El documento de identidad no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
            }


        } else {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'La fecha introducida es incorrecta',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            })
        }

    }

    /**
     * Obtenemos el codigo postal, la población, provincia y pais de la direccion
     * @param address recibe una direccion
     */
    public AddressChange(address: any) {
        if (address.address_components) {

            var paisSeleccionado: string = ""
            this.paisModel = "34";
            for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {

                const componentType = component.types[0];

                switch (componentType) {
                    case "route":
                        {
                            this.direccion = `${component.long_name}`;
                            break;
                        }
                    case "street_number":
                        {
                            this.numeroCalle = `${component.long_name}`;
                            break;
                        }

                    case "postal_code":
                        {
                            this.codigopostal = `${component.long_name}`;
                            break;
                        }

                    case "locality":
                        this.poblacion = `${component.long_name}`;
                        break;

                    case "administrative_area_level_2":
                        {
                            this.provincia = `${component.long_name}`;
                            break;
                        }

                    case "country":
                        paisSeleccionado = `${component.long_name}`;
                        break;


                }
            }

            var paisSelect = (document.getElementById("paisSelect")) as HTMLSelectElement
            const select = paisSelect;
            const value = select.value;
            // const desc = select.selectedOptions[0].text = paisSeleccionado.toUpperCase();


            this.data7.forEach(element => {
                if (element.desc == paisSeleccionado.toUpperCase()) {
                    this.pais = element.codi;
                }
            });
            this.direccion += ", " + this.numeroCalle

        }


    }

    //_________________________________OBTENCION_IP_DE_LA_COOKIE
    getIP() {
        this.ipAddress = this.cookie.get("ip")
        /* this.ip.getIPAddress().subscribe((res: any) => {
          this.ipAddress = res.ip;
          
        }); */
    }

    //_________________________________CARGANDO DATOS DE TARIFA
    selectOptionTarifa($event) {
        this.registroForm.enable();
        this.isDisableSelectPais = false;
        this.disablebtnBuscarNif = false;
        this.isDisabledBuscarLineas = false;
        // this.registrolineaform.enable()
        //getted from event
        var splitted = $event.split("|", 2);

        this.terceroaux = splitted[0];
        this.contratoaux = splitted[1];
        //getted from binding
        this.getTarifas(this.terceroaux, this.contratoaux)
    }

    //_________________________________CARGANDO DATOS DE TARIFA
    /**
     * Retorna un listado de tarifas aplicables a una línea Movil
     */
    async getTarifas(tercero, contratop) {
        await this.showloading()
        let promise = new Promise<void>((resolve, reject) => {
            this.Tarifa = {
                tercero: tercero,
                contratoP: contratop

            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.getTarifaMovil;
            try {
                this.http.post(url, this.Tarifa, { headers }).toPromise().then(
                    res => { // Success
                        this.data6 = res
                        if (this.data6.id) {
                            this.hideloading()
                            Swal.fire({
                                icon: 'error',
                                title: 'ERROR',
                                text: 'Se ha producido un error al cargar las tarifas.',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                            })
                        } else {
                            for (var i = 0; i < this.data6.length; i++) {
                                var con = this.data6[i];
                            }
                            resolve();
                            this.hideloading()
                        }



                    },
                    msg => { // Error

                        reject(msg);
                        this.hideloading();
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Se ha producido un error al cargar las tarifas.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        })


                    }
                );
            } catch {
                this.hideloading
            }
        });
        return promise;
    }



    //_________________________________BUSCAR_USUARIO

    // TODO: Revisar la función async getUsuario() ya que no se utiliza en ningun lugar. 
    /*    async getUsuario() {
           this.nif = this.nif.toUpperCase()
           await this.showloading()
               // let promise = new Promise < void > ((resolve, reject) => {
   
           this.data4 = {
               nif: this.nif
   
           }
   
   
   
           const headers = new HttpHeaders({
               'user': this.cookie.get("user"),
               'token': this.cookie.get("token"),
               'pass': this.cookie.get("pass"),
           });
           const url = environment.getUsuario;
           this.http.post(url, this.data4, { headers }).subscribe(
               (res: any) => { // Success
   
   
                   this.data5 = res;
                   if (this.data5.id) {
                       this.hideloading()
                       Swal.fire({
                           icon: 'error',
                           title: 'ERROR',
                           text: 'Debe introducir un DNI válido.',
                           footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                           background: '#F5F5F5',
                       })
                       this.clienteEncontrado = false
                   } else {
   
                       var documento;
                       if (this.isNif(res.doc)) {
                           documento = "N"
   
                       } else if (this.isNie(res.doc)) {
                           documento = "N"
   
                       } else if (this.validaCif(res.doc)) {
                           documento = "C"
   
                       } else {
                           documento = "P"
                       }
   
   
                       var tcliente;
                       if (this.data5.tcli == "R") {
                           tcliente = "R"
   
                       } else if (this.data5.tcli == "S") {
                           tcliente = "S"
                       } else if (this.data5.tcli == "A") {
                           tcliente = "A"
                       }
   
                       if (this.data5.fecha) {
   
   
                           var splitted = this.data5.fecha.split("/", 3);
                           var mes = splitted[0];
                           var dia = splitted[1];
                           var ano = splitted[2];
                           var formatmes = Number(mes)
                           var formatdia = Number(dia)
                           var formatano = Number(ano)
                       }
                       //PredeterminadaMente el pais será España
                       var paisSelect = (document.getElementById("paisSelect")) as HTMLSelectElement
                       var sel = paisSelect.selectedIndex; //obtengo la opcion seleccionada
                       var opt = paisSelect.options[sel]; //obtengo el option seleccionado
                       var paisString = (opt).text = "España";
                       (opt).value = "34";
   
   
                       for (var i = 0; i < this.aux.length; i++) {
                           var con = this.aux[i];
                       }
                       this.registroForm.patchValue({
                           apellido1: this.data5.ape1,
                           apellido2: this.data5.ape2,
                           nombre: this.data5.nom,
                           //idcliente: this.data5.con,
                           codigopostal: this.data5.cp,
                           direccion: this.data5.dir,
                           fechanac: new Date(formatano, formatmes - 1, formatdia),
                           tiponif: documento,
                           poblacion: this.data5.poblacion,
                           provincia: this.data5.localidad,
                           tipocliente: tcliente,
                           pais: this.data5.pais
                       })
                       this.cookie.set("cuarto", this.data5.cod);
                       this.cuartoaux = this.data5.cod
                           // this.registroForm.controls['apellido1'].disable()
                           // this.registroForm.controls['apellido2'].disable()
                           // this.registroForm.controls['nombre'].disable()
                           // this.registroForm.controls['apellido1'].disable()
                           // this.registroForm.controls['tipocliente'].disable()
                           // this.registroForm.controls['tiponif'].disable()
                       this.encontrado = true;
                       this.clienteEncontrado = true
                           // resolve();
                       this.hideloading()
                   }
               },
               msg => { // Error
                   Swal.fire({
                           icon: 'error',
                           title: 'ERROR',
                           text: 'Se ha producido un error al cargar el usuario.',
                           footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                           background: '#F5F5F5',
                       })
                       // reject(msg);
                   this.hideloading()
               });
           // });
           // return promise;
       } */


    //_________________________________CARGAR_DATOS_DE_PAÍS
    async getPais() {
        await this.showloading()
        let promise = new Promise<void>((resolve, reject) => {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.getPais;
            this.http.post(url, {}, { headers }).toPromise().then(
                res => { // Success
                    this.data7 = res
                    for (var i = 0; i < this.data7.length; i++) {
                        var con = this.data7[i];
                    }
                    this.hideloading()
                    resolve();
                },
                msg => { // Error

                    reject(msg);
                    this.hideloading();
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'Se ha producido un error al cargar los países.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    })
                }
            );
        });
        return promise;
    }

    //________________________________________HABILITA/DESHABILITA_LOS_PAISES_POR_LA_VARIABLE_TipoNIF

    needPais(tipo) {
        //getted from event
        if (tipo == "P") {
            this.registroForm.controls['pais'].enable();
        } else {
            this.registroForm.controls['pais'].enable();
        }

    }
    //________________________________________HABILITA/DESHABILITA_EL_NUMERO_DE_PORTABILIDAD_SI_SE_SELECCIONA_"Con_portabilidad"




    showLoadModal() {
        this.loadModal = true
    }

    hiddeLoadModal() {
        this.loadModal = false
    }
    initForm() {
        // 
        this.registroForm.disable();
        // this.documentosGroup.disable();
        this.registroForm.controls['idcliente'].enable();
        this.disablebtnBuscarNif = true;
        this.isDisableSelectPais = true;

    }

    /**
     * comprueba si un telefono es portable o no
     * @param telefono movil a portar
     */
    comprobarNumeroAMigrar(telefono: String) {

        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'pass': this.cookie.get("pass"),
            'token': this.cookie.get("token")
        })
        const body = {
            "telefono": telefono
        }


        let url = environment.checkFreeNumberMobile
        return this.http.post(url, body, { headers })

    }

    /**
     * Inicializacion y validación del formulario
     */
    validacionDeFormularios() {


        this.registroForm = this.formBuilder.group({
            'nif': ['', [Validators.required]], // nif: this.registroForm.get('nif').value
            'tiponif': ['', [Validators.required]], // tipoNif: this.registroForm.get('tiponif').value
            'idcliente': ['', [Validators.required]], // tercero: this.registroForm.get('idcliente').value
            'tipocliente': ['', [Validators.required]], // tipocliente: this.registroForm.get('tipocliente').value
            'nombre': ['', [Validators.required]], // nombre: this.registroForm.get('nombre').value
            'apellido1': ['', [Validators.required]], // apellido1: this.registroForm.get('apellido1').value
            'apellido2': ['', [Validators.required]], // apellido2: this.registroForm.get('apellido2').value
            'fechanac': ['', [Validators.required]], // fechaNacimiento: this.datePipe.transform(this.registroForm.get('fechanac').value, 'yyyyMMdd')
            'direccion': ['', [Validators.required]], // direccion: this.registroForm.get('direccion').value
            'cp': ['', [Validators.required]], // codigopostal: this.registroForm.get('codigopostal').value
            'poblacion': ['', [Validators.required]], // poblacion: this.registroForm.get('poblacion').value
            'provincia': ['', [Validators.required]], // provincia: this.registroForm.get('provincia').value
            'pais': [''], // provincia: this.registroForm.get('provincia').value

        });




        this.documentosGroup = this.formBuilder.group({
            'fileDocIdentidad': ['',],
            'fileCopiaContrato': ['',],
            'fileGrabacion': ['',],
        });

        this.initForm();



    }

    //__________________________________________________________COMPROBAR PORTABILIDAD DE NUMERO 
    /**
     * Comprueba si un telefono es portable
     * @param value telefono
     */
    async changeNumberTelf(value) {
        this.iccres = false
        this.iccresdos = false
        if (value.length == 9) {
            this.showloading();
            this.comprobarNumeroAMigrar(value).subscribe(
                (res: any) => {

                    this.hideloading();
                    if (res.id) {

                        if (res.id = "E001") { //NO SE PUEDE PORTAR
                            this.msglinea = false;
                            this.iccresponse = 'El número móvil no es portable'
                            this.iccres = true
                            // this.esPortable = false
                        }

                    } else if (res.tel) {
                        this.msglinea = true;
                        this.iccresponse = 'El número móvil es totalmente portable';
                        this.iccresdos = true
                        // this.esPortable = true
                    } //END PORTABILIDAD VALIDA
                    // this.alertICC()

                }

            )

        }
    }

    //TODO: Revisar qya que este metodo no se utiliza
    /* realizarCambio() {
        // console.log(this.dataClientExist);

        this.showloading()
        const headers = new HttpHeaders({
            'user': this.cookie.get('user'),
            'token': this.cookie.get('token'),
            'pass': this.cookie.get('pass'),
        });

        let tercero = this.cookie.get('tercero');
        let cuarto = this.cookie.get('cuarto')
        let numero = this.cookie.get('numero')

        const body = {
            "tercero": tercero,
            "cuarto": cuarto,
            "telefono": numero

        }


        const url = environment.cambioTitular
        this.http.post(url, body, { headers }).subscribe((res: any) => {
            this.hideloading()

            if (res.est == "TRUE") {

                Swal.fire({
                    icon: "success",
                    text: "TITULARIDAD MODIFICADA CON ÉXITO"

                })

                this.loading.titular = this.registroForm.get("nombre") + " " + this.registroForm.get("apellido1") + " " + this.registroForm.get("apellido2");
                this.loading.dni = this.registroForm.get("nif") + "";
            } else { //FALLO EN EL CAMBIO
                Swal.fire({
                    icon: "warning",
                    text: res.des
                })
            }


        }, error => {




            console.log(error);
            this.hideloading()
        })
    } */











}