import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';

@Component({
    selector: 'app-advanced-find-fijo',
    templateUrl: './advanced-find-fijo.component.html',
    styleUrls: ['./advanced-find-fijo.component.css']
})
export class AdvancedFindFijoComponent implements OnInit {
    advancedForm: UntypedFormGroup;
    loadModal: Boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private cookie: CookieService,
        private http: HttpClient,
        private router: Router,
        private loadService: LoadingServiceService,
        private auth: AuthService
    ) {}

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bfrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true
    };

    dtTrigger: Subject<void> = new Subject<void>();

    data: any;
    data2: any
    uDetails: user
    terceroaux: any;
    contratoaux: any;
    public load: Boolean = false;
    ter: any;
    con: any;
    linea = {
        tercero: '',
        contrato: ''
    };
    contrato = {
        username: '',
        password: ''
    };
    bloqueo = {
        'accion': '',
        'telefono': '',
        'usuario': '',
        'clave': '',
    };
    listContratos: any;
    //====================END DATATABLE =====================================//
    ngOnInit(): void {
        this.initFormGroup()
        var dataTable = $("#datatableAdvancedFijo").dataTable();
        dataTable.DataTable().destroy

    }

    ngAfterViewInit(): void {
        var dataTable = $("#datatableAdvancedFijo").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
    * Validación del formulario
    */
    initFormGroup() {
        this.advancedForm = this.formBuilder.group({
            'telf': ['', [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(9),
                Validators.pattern(/^[8||9]\d{8}$/gm)
            ]]
        })
    }

    /**
     * Busqueda de detalles de una línea Fija
     * @param value 
     * @returns 
     */
    async findNumber(value) {
        if (value.length == 9) {
            if (value.substring(0, 1) != "8" && value.substring(0, 1) != "9") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                return
            }
            await this.showLoadModal()
            let codigoTercero = this.cookie.get("tercero")
            const body = {

                "telefono": value,
                "tercero": codigoTercero
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });
            const url = environment.buscarLineaFija
            this.showLoadModal();
            this.data = null
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.hiddeModal()
                    if (res.id) { //_________INVALIDO
                        Swal.fire({
                            title: "INFORMACIÓN DE LA BÚSQUEDA AVANZADA",
                            icon: "warning",
                            text: res.id
                        })
                    } //_______________END INVALIDO
                    else if (res.Error) {
                        Swal.fire({
                            title: "INFORMACIÓN",
                            icon: "error",
                            text: res.Error
                        })
                        return
                    } else {
                        this.data = res;
                        this.updateDataTable()
                    }
                    this.hiddeModal()
                }, error => {
                    console.log(error);
                    this.hiddeModal()
                })
            } catch {
                this.hiddeModal()
            }
            // Swal.fire({
            //   title:"NUMERO INVALIDO",
            //   icon:"warning",
            //   text:"El número introducido no es válido para la busqueda"
            // })
        }
    }

    //______________________________MODAL ________________________________
    showLoadModal() {
        this.loadModal = true
    }
    hiddeModal() {
        this.loadModal = false
    }

    //______________________________ END MODAL ________________________________

    /**
     * Actualizacion del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatableAdvancedFijo").dataTable();
        dataTable.DataTable().destroy
        $('#datatableAdvancedFijo').DataTable({
            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    /**
     * Retorno de la fecha en formato DD-MM-AAAA
     * @param dateString 
     * @returns 
     */
    dateTransform(dateString: string) {
        const DATE_TIME_FORMAT = 'DD-MM-YYYY';
        let date = String(parseInt(dateString) + 19000000);

        let year = date.substr(0, 4);
        let month = date.substr(4, 2)
        let day = date.substr(6, 2)
        return day + "-" + month + "-" + year;
    }

    /**
     * FUNCION ENCARGADA DE RETORNA LA HORA EN FORMATO LEGIBLE POR EL USUSARIO HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: string) {
        if (resHours.length > 5 && resHours.length < 7) {
            return resHours.substr(0, 2) + ":" + resHours.substr(2, 2) + ":" + resHours.substr(4, 2);
        } else {
            if (resHours.length > 4 && resHours.length < 6) {
                return "0" + resHours.substr(0, 1) + ":" + resHours.substr(1, 2) + ":" + resHours.substr(3, 2);
            } else {
                return resHours;
            }
        }
    }

    /**
     * Evento de bloqueo de una linea Fija
     * @param cond 
     * @param telefono 
     */
    async getBloqueo(cond: any, telefono: any) {
        Swal.fire({
            title: 'MODIFICAR LÍNEA FIJA',
            text: "¿Está seguro de que desea cambiar el estado de la línea?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.showLoadModal()
                try {
                    const headers = new HttpHeaders({
                        'user': this.cookie.get("user"),
                        'token': this.cookie.get("token"),
                        'pass': this.cookie.get("pass"),
                    });

                    if (cond == "B") {
                        this.bloqueo = {
                            'accion': "Bloquear",
                            'telefono': telefono,
                            'usuario': this.cookie.get("user"),
                            'clave': this.cookie.get("pass")
                        }
                        const url = environment.bloqueofija;
                        this.http.post(url, this.bloqueo, { headers }).subscribe((res: any) => {
                            this.data = res;
                            Swal.fire(
                                'Completado',
                                'La Línea fija ha sido modificada',
                                'success'
                            )
                            this.hiddeModal()
                            this.getlineas(this.ter, this.con);
                        });
                    } else if (cond == "D") {
                        this.bloqueo = {
                            'accion': "Desbloquear",
                            'telefono': telefono,
                            'usuario': this.cookie.get("user"),
                            'clave': this.cookie.get("pass")
                        }
                        const url = environment.bloqueofija;
                        this.http.post(url, this.bloqueo, { headers }).subscribe((res: any) => {
                            this.data = res;
                            Swal.fire(
                                'Completado',
                                'La Línea fija ha sido modificada',
                                'success'
                            )
                            this.hiddeModal();
                            this.getlineas(this.ter, this.con);
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Se ha producido un error al modificar la línea fija.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        })
                    }
                } catch {
                    this.hiddeModal()
                }
            }
        })
    }
    /**
     * Evento para retornar los servicios de una línea Fija
     * @param telefono 
     */
    getServicios(telefono: string) {
        this.cookie.set("telefono", telefono)
        this.auth.gettelefono(telefono);
        this.router.navigate(['/servicios'])
    }
    /**
     * Evento encargado de retornar el consumo de una linea Fija
     * @param telefono 
     */
    getConsumo(telefono: string) {
        // this.auth.gettelefono(telefono);
        this.cookie.set("telefono", telefono);
        this.router.navigate(['/consumofijo'])
    }
    /**
     * Evento que se encarga de retornar los detalles de una línea Fija
     * @param telefono 
     */
    getDetalle(telefono: string) {
        this.auth.gettelefono(telefono);
        this.router.navigate(['/detallelineafija'])
    }

    /**
     * Retorna el listado de lineas bajo un contrato y tercero
     */
    async getlineas(t: any, c: any) {
        this.ter = t;
        this.con = c;
        await this.showLoadModal()
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            this.linea = {
                "tercero": t,
                "contrato": c
            }
            this.data = null;
            const url = environment.getLineasFijo;
            this.http.post(url, this.linea, { headers }).subscribe((res: any) => {
                // this.updateDataTable();
                (res.length) ? this.data = res.Registro: this.data = res.Registro;
                //___________________________________DATATABLE ________________________________//
                this.updateDataTable()
                    //________________________________END DATATABLE_________________________________-//
                this.hiddeModal()
            }, error => {
                this.data = null;
                this.updateDataTable();
                this.hiddeModal()
            });
        } catch {
            // this.updateDataTable();
            await this.hiddeModal()
        }
    }
}