import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class CambioTitularService {
    buscar = false;
    data;
    load;
;
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bfrtip',
        buttons: false,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true
    };

    dtTrigger: Subject<void> = new Subject<void>();
    constructor(private cookie: CookieService, private http: HttpClient) {}

    /**
     * Buscar el titular del telefono
     */
    findNumber(value) {
        if (value.length == 9) {
            if (value.substring(0, 1) != "7" && value.substring(0, 1) != "6") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                return;
            }
            this.showloading();
            const body = {
                "telefono": value,
                "tercero": this.cookie.get("tercero")
            }

            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });

            const url = environment.advancedFind;
            this.buscar = false;
            this.data = null;
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.hideloading();
                    if (res) { //VALIDO
                        this.data = res[0];
                        this.cookie.set("telefono", value); //seteamos la cookie de telefono
                        this.updateDataTable()
                    } //_______________ENDVALIDO
                }, error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'No existe el número de telefono introducido.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                    this.hideloading();
                })
            } catch {
                this.hideloading();
            }
        }
    }
    /**Buscar numero fijo */
    findNumberFijo(value) {
        if (value.length == 9) {
            if (value.substring(0, 1) != "8" && value.substring(0, 1) != "9") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                return;
            }
            this.showloading();
            const body = {
                "telefono": value,
                "tercero": this.cookie.get("tercero")
            }

            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });

            const url = environment.buscarLineaFija;
            this.buscar = false;
            this.data = null;
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.hideloading();
                    if (res) {
                        if (res.Error) {
                            Swal.fire({
                                title: "INFO",
                                icon: "error",
                                text: res.Error
                            });
                            return;
                        }
                        this.data = res;
                        this.cookie.set("telefono", value);
                        this.updateDataTable();
                    }
                }, error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'No existe el número de telefono introducido.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                    this.hideloading();
                })
            } catch {
                this.hideloading();
            }
        }
    }
    updateDataTable() {
        var dataTable = $("#datatableTitFijos").dataTable();
        dataTable.DataTable().destroy;
        $('#datatableTitFijos').DataTable({
            retrieve: true,
            search: false,
            paging: false
        });
        this.dtTrigger.next();
    }

    buscarUsuario() {
        this.buscar = true;
    }

    showloading() {
        this.load = true;
    }

    hideloading() {
        this.load = false;
    }
}