import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forEach } from 'jszip';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-incidencias-modal',
  templateUrl: './create-incidencias-modal.component.html',
  styleUrls: ['./create-incidencias-modal.component.css']
})
export class CreateIncidenciasModalComponent implements OnInit {
  observaciones: string;
  public loadModal: boolean;
  private tokenMarcaBlanca: string;
  registroForm: UntypedFormGroup;
  form: any;
  telefonoFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(/^\d{9}$/) // Expresión regular para 9 dígitos numéricos
  ]);
  seleccionFormControl = new UntypedFormControl('', [
    Validators.required // Verificar si se ha seleccionado algo
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private http: HttpClient,
    private cookie: CookieService,
    private marcaBlanca: PeticionServiceService,
    private dialogRef: MatDialogRef<CreateIncidenciasModalComponent>
  ) { }

  ngOnInit(): void {
    this.obtenerBuzonIncidencias();
    this.compruebaInput();
  }

  //_______________________________________________Loading
  showloading() {
    this.loadModal = true;
  }
  hideloading() {
    this.loadModal = false;
  }
  /**
  * Crea un Swal para errores
  */
  createErrorAlert(text, title = 'ERROR', html?) {
    Swal.fire({
      icon: 'error',
      title: title,
      background: '#F5F5F5',
      text: text,
      footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
      html: html
    });
  }

  compruebaInput() {
    const myButton = document.getElementById('botonCrear');
  
    if ((this.seleccionFormControl.value && !this.seleccionFormControl.invalid) && (!this.telefonoFormControl.invalid)) {
      myButton.classList.remove('botonDisable');
    } else {
      myButton.classList.add('botonDisable');
    }
  }


  /* El fragmento de código que proporcionó define una propiedad `opciones` como una matriz de cualquier
  tipo `any[]` y declara una función asincrónica `obtenerBuzonIncidencias()` que es responsable de
  recuperar datos. A continuación se muestra un desglose
  de lo que hace la función: */
  opciones: any[];
  async obtenerBuzonIncidencias() {
    const headers = {
      'Authorization': 'Bearer ' + this.tokenMarcaBlanca,
    }

    await this.marcaBlanca.peticionMarca(environment.getBuzonIncidencias, 'get', headers).then((data: any) => {
      this.opciones = data.buzones.Técnicas;
    });
  }

  async crearIncidencia() {
    if (this.telefonoFormControl.invalid || this.telefonoFormControl.pristine) {
      return;
    }

    const payload = {
      'delegacion': 0,
      'socio_procono': 0,
      'contrato_oficial': this.data.contrato_oficial,
      'telefono': this.telefonoFormControl.value,
      'codigo_incidencia': this.seleccionFormControl.value,
      'empleado': this.cookie.get("tercero"),
      'texto': this.observaciones
    };

    const headers = {
      'Authorization': 'Bearer ' + this.tokenMarcaBlanca,
    }
    try {
      await this.marcaBlanca.peticionMarca(environment.crearIncidencia, 'post', headers, payload).then((data: any) => {
        if (data.status == true) {
          // Muestra un Swal con el mensaje de exito
          Swal.fire({
            title: data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Confirmar',
          }).then((swalResponse) => {
            if (swalResponse.isConfirmed) {
              this.dialogRef.close();
            }
          });
        }
      });
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      // Muestra un Swal con el mensaje de error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.message
      });
    }
  }

  get campoTelefonoInvalido(): boolean {
    return this.telefonoFormControl.valid && this.telefonoFormControl.touched;
  }
}
