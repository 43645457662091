import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-gestion-portabilidades-page',
    templateUrl: './gestion-portabilidades-page.component.html',
    styleUrls: ['./gestion-portabilidades-page.component.css']
})
export class GestionPortabilidadesPageComponent implements OnInit, AfterViewInit, OnDestroy {
    load: boolean
    data2
    data: any;
    disbleSelect: Boolean = false

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Blfrtip',
        buttons: false,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    contratosModel
    //====================END DATATABLE =====================================//
    constructor(private cookie: CookieService, private http: HttpClient) {
        var dataTable = $("#dataTablePortabilidad").dataTable();
        dataTable.DataTable().destroy
    }

    ngOnInit(): void {
        this.getContratos();
        this.contratosModel = "0"
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        var dataTable = $("#dataTablePortabilidad").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }


    /**
     * Retorna el listado de portabilidades que existen bajo un contrato 
     * @param contratoSelected 
     */
    gestionPortabilidad(contratoSelected) {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });
        const body = {
            "contrato": contratoSelected,
            "tercero": this.cookie.get("tercero"),
            "dni": this.cookie.get("user"),
        }

        this.data = null;
        const url = environment.gestionPortabilidad;
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    this.data = res;
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'No existen portabilidades.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    })
                }
                this.disbleSelect = false;
                this.hideloading();
            }, (error: any) => {
                this.disbleSelect = false;
                this.hideloading();
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'No existen portabilidades.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
            })
        } catch {
            this.disbleSelect = false;
            this.data = null;
            this.hideloading();
        }
    }


    /**
     * Retorna el listado de contratos
     */
    getContratos() {
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            const url = environment.getContratosMovil;
            this.showloading()
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                } else {
                    this.data2 = [];
                    this.data2.push(res)
                }
                this.hideloading();
            }, error => {
                this.hideloading()
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     * Evento encargado de retornar las portabilidades que existen bajo un contrato seleccionado
     * @param $event 
     */
    changeContratos($event) {
        this.disbleSelect = true
        this.gestionPortabilidad($event);
        // this.gestionPortabilidad("70314"); //---->pruebas
    }

    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }

    /**
     * FUNCION ENCARGADA DE RETORNA LA HORA EN FORMATO LEGIBLE POR EL USUSARIO HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: number) {
        let strHours: string = resHours.toString();
        if (strHours.length > 5 && strHours.length < 7) {
            return strHours.substr(0, 2) + ":" + strHours.substr(2, 2) + ":" + strHours.substr(4, 2);
        } else {
            if (strHours.length > 4 && strHours.length < 6) {
                return "0" + strHours.substr(0, 1) + ":" + strHours.substr(1, 2) + ":" + strHours.substr(3, 2);
            } else {
                return resHours;
            }
        }
    }

    /**
     * Retornala fecha en formato dd-mm-aaaa
     * @param strDate 
     * @returns 
     */
    dateTransform(strDate: String) {
        let year = strDate.substr(0, 4);
        let month = strDate.substr(4, 2);
        let day = strDate.substr(6, 2);
        return day + "-" + month + "-" + year;
    }
}