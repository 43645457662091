import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-control-bajas-page',
    templateUrl: './control-bajas-page.component.html',
    styleUrls: ['./control-bajas-page.component.css']
})
export class ControlBajasPageComponent implements OnInit, AfterViewInit, OnDestroy {
    load: boolean = false;
    listBajas: any = null

    //====================== DATATABLE =======================================//
        @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//

    constructor(private cookie: CookieService,
        private http: HttpClient) {
        var dataTable = $("#dataTableBajas").dataTable();
        dataTable.DataTable().destroy
    }

    ngOnDestroy(): void {
        // var dataTable = $("#dataTableBajas").dataTable();
        // dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        var dataTable = $("#dataTableBajas").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
            // this.dtTrigger.next();
    }
    ngOnInit(): void {
        this.getBajas();
        var dataTable = $("#dataTableBajas").dataTable();
        dataTable.DataTable().destroy
    }

    /**
     * Retorna el listado de bajas
     */
    async getBajas() {
        let tercero = this.cookie.get("tercero");
        const body = {
            "tercero": tercero
        }

        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        const url = environment.controlBajas
        this.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {;
                if (res) {
                    this.listBajas = res;
                } else {
                    Swal.fire({
                        title: "INFO BAJAS",
                        icon: "warning",
                        text: "El operdador actual no dispone de bajas.",
                    })
                }
                this.updateDataTable();
                this.hideloading();
            }, error => {
                Swal.fire({
                    title: "INFO BAJAS",
                    icon: "warning",
                    text: "El operdador actual no dispone de bajas.",
                })
                this.hideloading();
            })
        } catch {
            this.updateDataTable();
            this.hideloading();
        }
    }

    //___________________________________________LOADING
    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }
    //________________________________________END_LOADING
    /**
     * Actualización del dataTable
     */
    updateDataTable() {
        var dataTable = $("#dataTableBajas").dataTable();
        dataTable.DataTable().destroy
        $('#dataTableBajas').DataTable({

            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }


    /**
     * Retorna la fecha en formato DD-MM-AAAA
     * @param dateString 
     * @returns 
     */
    dateTransform(dateString: string) {
        const DATE_TIME_FORMAT = 'DD-MM-YYYY';
        let date = String(parseInt(dateString) + 19000000);

        let year = date.substr(0, 4);
        let month = date.substr(4, 2)
        let day = date.substr(6, 2)
        return day + "-" + month + "-" + year;
    }
}