import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-down-shared-group-page',
    templateUrl: './down-shared-group-page.component.html',
    styleUrls: ['./down-shared-group-page.component.css']
})
export class DownSharedGroupPageComponent implements OnInit, OnDestroy, AfterViewInit {
    data
    data2
    linea
    aux
    contratoOficial
    contactoProvisional
    tercero
    tarifasFamiliares
    load
    curretPeticionCabecera
    tarifaFamiliarSelected
    tarifaSelected: string;
    list: any;
    listPetCab = null;
    listPetCabAux = null;
    tarifasUpdate = null;
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        search: true,
        columnDefs: [{
            targets: [1]
        }],
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        buttons: false,
        retrieve: true,
        paging: true,
        pagingType: 'simple_numbers'
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    lineasCliente
    lineasAppendGroup = []
    lineasAppendGroupPeticion = []
    constructor(
        private cookies: CookieService,
        private http: HttpClient
    ) {
        var dataTable = $("#dataTableBajasGrupo").dataTable();
        dataTable.DataTable().destroy
    }

    ngAfterViewInit(): void {
        var dataTable = $("#dataTableBajasGrupo").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
            // this.dtTrigger.next();
    }
    ngOnInit(): void {
        this.lineasCliente = null;
        this.getContratos();
        this.lineasAppendGroup = null;
        var dataTable = $("#dataTableBajasGrupo").dataTable();
        dataTable.DataTable().destroy
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
     * Evento encargado de retornar los grupos bajo un contrato seleccionado
     * @param $event 
     */
    changeContratos($event) {
        /* this.ter = t;
        this.con = c; */
        this.contratoOficial = $event.split("|")[0];
        this.contactoProvisional = $event.split("|")[1];
        this.getGroupList(this.contratoOficial);
        // this.getTarifasFamiliar($event)
    }

    /**
     * devuelve el listado de contratos
     */
    
    getContratos() {
        this.showloading()
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            const url = environment.getContratosMovil;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                } else {
                    this.data2 = [];
                    this.data2.push(res)
                }
                this.hideloading();
            }, error => {
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     * Retorna el listado de gruposCompartidos
     * @param contrato 
     */
    getGroupList(contrato: String) {
        this.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        let body = {
            "contrato": contrato
        }
        const url = environment.getListSharedGroup;
        this.list = null;
        this.listPetCab = null;
        this.listPetCabAux = null;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    this.list = [];
                    this.listPetCab = [];
                    this.listPetCabAux = [];
                    // this.list = res;
                    // res.forEach(element => {
                    //     this.list.push(
                    //         {
                    //             CONTRATO: element.CONTRATO,
                    //             PETICION: element.PETICION,
                    //             NUMERO: element.NUMERO,
                    //             PET_HIJA: element.PET_HIJA,
                    //             TARIFA: element.TARIFA.trim(),
                    //             TITULAR: element.TITULAR.trim(),
                    //             CAMBIOTARIFA: null,
                    //         }
                    //     )
                    // });
                    this.getTarifas();
                    this.hideloading();
                    var count = 0;
                    res.forEach(item => {
                        if (!this.listPetCabAux.includes(item.PETICION)) {
                            this.listPetCab.push({
                                peticionCabecera: item.PETICION,
                                tarifa: item.TARIFA,
                                titular: String(item.TITULAR).trim(),
                                color: this.randomColor()
                            });
                            this.listPetCabAux.push(item.PETICION);
                        }
                    })
                    this.listPetCab.forEach(element => {
                        //_________________________________PUSH ENCABEZADO ______________________
                        this.list.push(
                            {
                                CONTRATO: null,
                                PETICION: element.peticionCabecera,
                                NUMERO: null,
                                PET_HIJA: null,
                                TARIFA: null,
                                TITULAR: null,
                                CAMBIOTARIFA: null,
                            }
                        )
                            //______________________END PUSH ENCABEZADO
                        res.forEach(item => {
                            if (element.peticionCabecera == item.PETICION) {
                                this.list.push(
                                    {
                                        CONTRATO: item.CONTRATO,
                                        PETICION: item.PETICION,
                                        NUMERO: item.NUMERO,
                                        PET_HIJA: item.PET_HIJA,
                                        TARIFA: item.TARIFA.trim(),
                                        TITULAR: item.TITULAR.trim(),
                                        CAMBIOTARIFA: null,
                                    }
                                )
                            }
                        });
                    });
                    //___________________________________DATATABLE ________________________________//
                    this.updateDataTable();
                    //________________________________END DATATABLE_________________________________-//
                }
                this.hideloading();
            })
        } catch {
            this.updateDataTable();
            this.list = null;
            this.listPetCab = null;
            this.listPetCabAux = null;
            this.hideloading();
        }
    }

    //___________________________________________LOADING
    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }
        //________________________________________END_LOADING

    /**
     * Evento encargado de gestionar la tarifa compartida seleccionada
     * @param $event 
     */
    selectTarifa($event) {
        this.tarifaFamiliarSelected = $event.split("@")[0].trim().replace(/\s/g, "");
    }

    /**
     * Añade lineas al grupo
     * @param telefono 
     * @param titular 
     */
    appendGroup(linea) {
        if (this.lineasAppendGroup == null) { this.lineasAppendGroup = [] }
        if (this.lineasAppendGroupPeticion == null) { this.lineasAppendGroup = [] }

        if (this.lineasAppendGroup.length < 3) {
            //Elimino de la lista los elementos que quiero añadir
            this.lineasCliente = this.lineasCliente.filter(item => item.telf != linea.telf);
            this.lineasAppendGroup.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo })
            this.lineasAppendGroupPeticion.push({ linea: linea.telf, peticion: linea.pet, peticionCabecera: "0" })
        } else { //_______________________HA EXCEDIDO EL MAXIMO DE LINEAS
            Swal.fire({
                title: "ESTADO DEL GRUPO",
                icon: "warning",
                text: "No es posible añadir mas líneas al grupo. El límite es de 3 líneas."
            })
        }
    }

    /**
     * Quita lineas que no deseamos añadir al grupo
     * @param linea 
     */
    removeLinesGroup(linea) {
        this.lineasAppendGroup = this.lineasAppendGroup.filter(item => item.telf != linea.telf);
        this.lineasAppendGroupPeticion = this.lineasAppendGroupPeticion.filter(item => item.linea != linea.telf);
        this.lineasCliente.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo })
    }

    updateDataTable() {
        var dataTable = $("#dataTableBajasGrupo").dataTable();
        dataTable.DataTable().destroy
        $('#dataTableBajasGrupo').DataTable({
            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    async getTarifas() {
        const body = {
            "contratoP": this.contactoProvisional,
            "tercero": this.cookies.get("tercero")
        }

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.getTarifaMobile
        this.tarifasUpdate = [];
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.tarifasUpdate = res;
            })
        } catch {
            this.hideloading();
        }
    }

    changeTarifa($event) {
        let tarifa = $event.split("|")[0];
        let peticion = $event.split("|")[1];
        this.list.forEach(element => {
            if (element.PETICION == peticion) {
                element.CAMBIOTARIFA = tarifa;
            }
        });
    }

    darDeBaja(telefono: String) {
        var peticion = "";
        var tarifa = "";

        var sePuedeDardeBaja: Boolean = true;
        this.list.forEach(element => {
            if (element.NUMERO == telefono) {
                peticion = element.PET_HIJA;
                if (element.CAMBIOTARIFA == null) {
                    sePuedeDardeBaja = false
                } else {
                    tarifa = element.CAMBIOTARIFA
                }
            }
        });

        if (sePuedeDardeBaja) {
            this.showloading();
            const body = {
                "Tarifa": tarifa,
                "codTercero": this.cookies.get("tercero"),
                "peticion": peticion,
                "telefono": telefono
            }
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            const url = environment.bajaLineGroup
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    // this.tarifasUpdate = res;
                    this.hideloading();
                    if (res) {
                        Swal.fire({
                            title: "INFORMACIÓN",
                            icon: "success",
                            text: "La baja se ha realizado correctamente."
                        })
                        this.getGroupList(this.contratoOficial);
                        // this.forzarCambioTarifa(peticion, telefono); //fUERZA EL CAMBIO Y DA DE BAJA
                    }
                }, error => {
                    this.hideloading();
                    Swal.fire({
                        title: "Error",
                        icon: "error",
                        text: error.original
                    })
                })
            } catch {
                this.hideloading();
                Swal.fire({
                    title: "ERROR",
                    icon: "error",
                    text: "Error desconocido, contacte con el servicio técnico."
                })
            }
            // await this.launchUpdateTarifa(telefono);
        } else {
            Swal.fire({
                title: "INFO",
                icon: "warning",
                text: "Se debe seleccionar una tarifa antes de proceder a la baja."
            })
        }
    }

    rowColor(peticionCabecera: String) {
        var color = "";
        this.listPetCab.forEach(element => {
            if (element.peticionCabecera == peticionCabecera) {
                color = element.color;
            }
        });
        return color;
    }

    randomColor(): String {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    forzarCambioTarifa(peticionHija) {
        const body = {
            "peticion": peticionHija,
            "tercero": this.cookies.get("tercero"),
        }
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.getForzarCambioTarifa;
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading();
            }, error => {
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }
}