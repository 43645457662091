import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lista-servicios-activos',
  templateUrl: './lista-servicios-activos.component.html',
  styleUrls: ['./lista-servicios-activos.component.css']
})

export class ListaServiciosActivosComponent implements OnInit {
  loading: boolean = true;
  servicios: any = null;

  dtOptions = {
    // dom: 'Bfrtip',
    language: {
      url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
    },
    retrieve: true,
    columnDefs:[
      {
        target:[4],
        visible:false
      }
    ]

  };
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private marcaBlanca: PeticionServiceService,
    private route: Router,
  ) {

  }

  ngOnInit(): void {
    this.getServiciosActivos();
  }

  async getServiciosActivos() {
    const payload = {
      "id_contrato": this.data.contrato,
    }
    this.loading=true
    await this.marcaBlanca.peticionMarca(environment.recuperarServiciosActivosMarcaBlanca, 'post', {}, payload).then(
      (data: any) => {
        if (data.length != 0) {
          this.servicios = data;
          this.tratarServicios(this.servicios);
          // recorremos todos los servicios, si no hay ningún botón para esa columna la ocultaremos
          for (const ele of data) {
            if(ele.estado=='C' || ele.estado=='P' || ele.estado=='9'){
             this.dtOptions = {
                // dom: 'Bfrtip',
                language: {
                  url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
                },
                retrieve: true,
                columnDefs:[
                  {
                    target:[4],
                    visible:true
                  }
                ]
              };
            }
          }          
        }
      }).catch(() => {
        this.mostrarSwal('El número de contrato no es válido', 'error');        
      }).finally(() => { this.loading=false});
  }

  toogleLoading() {
    this.loading = !this.loading;
  }

  addServicios() {
    this.route.navigateByUrl('/crear-pedidos-marca-blanca', {
      state: [
        this.data.tipo_cliente,
        this.data.contrato,
        this.data.tercero
      ]
    });
  }

  tratarServicios(servicios: any) {
    this.servicios = servicios.map((item: any) => {
      item.estado = item.estado.trim();
      return item;
    });
  }

  async bloquearDesbloequear(ser) {
    this.loading=true;

    let bloqDes: boolean;
    
    if (ser.EstBloq == 'Bloqueado') {
      bloqDes = false;
    } else {
      bloqDes = true;
    }

    const payload = {
      "bloqueo": bloqDes,
      "id_pedido": ser.Peticion
    }
    await this.marcaBlanca.peticionMarca(environment.bloquearDesbloquearServicioMarcaBlanca, 'post', {}, payload).then(
      (data: any) => {
        this.getServiciosActivos();
      }).catch(() => {
        this.mostrarSwal('El estado del servicio no ha podido modificarse', 'error');        
      }).finally(() => { this.toogleLoading(); });
  }  

  async bajaPedido(servicio:any){    
    this.loading=true;
    const payload = {
      "tercero":servicio.Tercero,
      "contratoprovisional":servicio.Cttprv,
      "seccion":servicio.Seccion,
      "linea":servicio.Linea
    }
    await this.marcaBlanca.peticionMarca(environment.bajaPedido, 'post', {}, payload).then(
      async (data: any) => {
        this.mostrarSwal(data.mensaje, 'success');
        await this.getServiciosActivos();
      }).catch((err) => {        
        this.mostrarSwal(err.error.mensaje, 'error');        
      });
    this.loading=false;      
  }

  async rechazaPedido(servicio:any){
    this.loading=true;
    let payload={
      'numeroParte': servicio.NumParte,
      'tercero': servicio.Tercero,
      'contratoprovisional': servicio.Cttprv,
      'seccion': servicio.Seccion,
      'linea': servicio.Linea,
    }      
    await this.marcaBlanca.peticionMarca(environment.rechazaPedido, 'post', {}, payload).then(
      async (data:any)=>{
        this.mostrarSwal(data.mensaje, 'success');
        await this.getServiciosActivos();
      }).catch((err) => {        
        this.mostrarSwal(err.error.mensaje, 'error');
      });
    this.loading=false;
  }

  mostrarSwal(title:string, icon:any): void {
    Swal.fire({
      title: title,
      icon: icon,
      showCancelButton: false,
      confirmButtonColor: '#00b1aa',
      confirmButtonText: 'Confirmar',
    });
  }
}
