import { HttpClient, HttpHeaders, HttpParameterCodec } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { createSubcription } from 'src/app/model/createSubcription';
import { NormaliceAddres } from 'src/app/model/NormalizedAddress';
import { prepareCoverageModel } from 'src/app/model/prepareCoverage';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ModalService } from './modal.service';


@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {
    public dataOptions: any;
    public load: boolean = false
    public locales: any = []
    public localSelected: number;
    public displayHidden = false

    public _createSubcriptionModel: createSubcription
    public _normalizedAddress: NormaliceAddres
    public _prepareCoverage: prepareCoverageModel
        //----------Show Wiews--------------------------//

    public _poblacionSelected: Boolean = false
    public comunidadAutonoma: string


    public streetTypeString: String
        //---------End -Show Wiews--------------------------//

    //-------------Listas Endpoints ------------------//
    public listProvincias: any
    public listPueblos: any
    public listCalles: any
        //-----------End Listas Endpoints ------------------//
    protected codProvinciaSelected: String
    protected codPoblacion: String;
    accountForm: UntypedFormGroup;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private cookie: CookieService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        public modalservice: ModalService,
        ) {}
    ngOnInit(): void {
        this.iniAndResetValues() //Metodo para reinicializar el objeto Principal
        this.listProvincias = this.getProvincias()
        this.accountForm = this.formBuilder.group({
            'country': ['', [Validators.required]],
            'county': ['', [Validators.required]],
            'state': ['', [Validators.required]],
            'city': ['', [Validators.required]],
            'street': ['', [Validators.required]],
            'postalCode': ['', [Validators.required, Validators.max(99999)]],
            'number': ['', [Validators.required, Validators.max(999)]],
        })
    }

    /**
     * Mostrar el progressbar
     */
    showLoading() {
        this.load = true
    }
    /**
     * Ocultar el progressbar
     */
    hiddeloading() {
        this.load = false
    }
    /**
     * Método encargado de realizar la peticion al EndPoint \normalizedAddress con los valores proporcionados en el formulario
     * Muestra un Módal incluyendo las direcciones posibles segun las obtenidas por los datos de usuario en el Formulario
     */
    normalizedAddress() {
        this.showLoading()

        this._prepareCoverage.street = this.modalservice._prepareCoverage.street

        this.streetTypeString = this.modalservice._prepareCoverage.street
        this._prepareCoverage.postalCode = this.accountForm.controls['postalCode'].value.toString();
        this._prepareCoverage.number = this.accountForm.controls['number'].value.toString();
        this._prepareCoverage.settlementType = parseInt(this.modalservice._prepareCoverage.settlementType).toString()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });

        const url = environment.normalizedAddress
        this.modalservice.lislocales = null;

        // return
        try {
            this.http.post(url, this._prepareCoverage, { headers }).subscribe(
                (response: any) => {
                    if (!response.ERROR) { //No Hay Errores
                        this.hiddeloading()
                        this.locales = [] //Array de locales disponibles
                        var counter = 0;
                        this.modalservice.lislocales = [];
                        if (response.length) {
                            //Si existe mas de un local en la direccion
                            response.forEach(local => {
                                this.modalservice.lislocales.push(local);
                                //---------------------------Contenido del Local-----------------------------------//
                            });
                        } else { ///Esta dirección  solo posee un local
                            this.locales.push(response)
                            this.modalservice.lislocales.push(response);
                        }

                        //---------------------------Contenido del Local HTML MODAL -----------------------------------//
                    } else { ///Fallo en la direccion proporcionada
                        this.hiddeloading()
                        Swal.fire({
                            title: 'ERROR',
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#00b1aa',
                            confirmButtonText: 'Confirmar',
                            text: "La dirección proporcionada no se encuentra en el sistema. Revise los datos introducidos.",
                        })
                    }
                }, error => { //Error en la validacion de los parametros introducidos
                    var errorView = '<ul class="errorView">';
                    if (error.error.country) {
                        errorView += '<li>El Pais introducido no el válidos </li>'
                    }
                    if (error.error.state) {
                        errorView += '<li>La comunidad autónoma introducida no es válida </li>'
                    }
                    if (error.error.county) {
                        errorView += '<li>La provincia introducida no es válida</li>'
                    }
                    if (error.error.city) {
                        errorView += '<li>La población introducida no es válida</li>'
                    }
                    if (error.error.street) {
                        errorView += '<li>El nombre de la via introducida no es valida </li>'
                    }
                    if (error.error.settlementType) {
                        errorView += '<li>La seleccion de tipo de via no es la correcta</li>'
                    }
                    if (error.error.postalCode) {
                        errorView += '<li>El código postal no es válido</li>'
                    }
                    if (error.error.number) {
                        errorView += '<li>El número de la via no es valido</li>'
                    }
                    errorView += '</ul>'
                    Swal.fire({
                        title: 'ERRORES ENCONTRADOS',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Confirmar',
                        html: errorView
                    })
                    this.hiddeloading()
                })
        } catch {
            this.hiddeloading()
        }
    }

    /**
     * Funcion encargada de extraer la posicion del [ ARRAY ]en la que se encuentra
     *  el domicilio donde se desea comprobar la cobertura
     */
    // selectDomicilio(): number {
    //     var position = 0
    //     var checkLocal = (document.getElementsByName("local")) //Capto el campo select
    //     checkLocal.forEach(element => {

    //         var elemento = element as HTMLInputElement
    //             // elemento as HTMLFormControlsCollection
    //         if (elemento.checked) {

    //             position = parseInt(elemento.value)
    //         }
    //     });
    //     return position;
    // }

    /**
     * Método encargado de devolver la lista con todas las provincias disponibles
     * @returns retorna un array con la lista de las posibles provincias que podemos seleccionar
     */
    getProvincias() {
        const endpointProvincias = environment.getProvincias
        var provincias = [];
        this.http.get(endpointProvincias).subscribe(
            (res: any) => {
                res.forEach(element => {
                    provincias.push(element)
                });
            })
        return provincias;
    }

    /**
     * Funcion encargada de capturar el evento change del campo select del elemento county
     * @param $event retorna el valor del campo options seleccionado
     */
    changeProvinciaSelected($event) {
        this.listPueblos = null
            //--------------------------------//
        let separar = $event.split("|");
        let codProvinciaSelected = separar[0]
        this.comunidadAutonoma = separar[1]
        this._prepareCoverage.state = separar[2]
            //--------------------------------//
        this._prepareCoverage.county = this.comunidadAutonoma
        this.codProvinciaSelected = codProvinciaSelected

        this.listPueblos = this.getPoblation(codProvinciaSelected);
    }
    /**
     * Método encargado de devolver los pueblos de una provincia especifica
     * @param codProvincia parametro de entrada para poder realizar la peticion
     * @returns
     */
    getPoblation(codProvincia: string) {
        const body = {
            "provincia": codProvincia
        }
        const endpointProvincias = environment.getPoblacion
        var pueblos = [];
        this.http.post(endpointProvincias, body).subscribe(
            (res: any) => {
                res.forEach(element => {
                    pueblos.push(element)
                });
            })
        return pueblos;
    }
    /**
     * Funcion encargada de capturar el evento change del campo select del elemento city
     * @param $event retorna el valor del campo options seleccionado
     */
    changePoblacionSelected($event) {
        let separe = $event.split("|")
        this._prepareCoverage.county = this.comunidadAutonoma
        this.codPoblacion = separe[0]
        this._prepareCoverage.city = separe[1]
            // if(this._prepareCoverage.street){
            //     this.iniAndResetValues()
            // }
    }

    /**
     * Metodo encargado de devolver las posibles calles que posee una direccion
     * @returns  array con las posibles calles
     */
    buscarCoincidenciasLaunch() {
        this.showLoading()
        const body = {
            "provincia": this.codProvinciaSelected,
            "pueblo": this.codPoblacion,
            "calle": this.accountForm.controls['street'].value
        }
        const endpointProvincias = environment.getStreets

        try {
            this.http.post(endpointProvincias, body).subscribe(
                (res: any) => {
                    this.hiddeloading();
                    if (res.length) {
                        this.modalservice.listCoincidencias = res;
                    } else {
                        Swal.fire({
                            title: 'SIN COINCIDENCIAS',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#00b1aa',
                            confirmButtonText: 'Ok',
                            text: "No existen coincidencias para la busqueda."
                        })
                    }
                }, error => {
                    this.hiddeloading()
                    Swal.fire({
                        title: 'ERROR',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Confirmar',
                        showCloseButton: true,
                        text: "La dirección proporcionada no se encuentra en el sistema. Revise los datos.",
                    })
                })
        } catch {
            this.hiddeloading();
        }
    }

    /**
     * Método encargado de retornar el valor del radiobutton seleccionado en el modal
     * @returns nombre de la via
     */
    selectCalleModal(): string {
        return this.accountForm.controls['street'].value;
    }

    //Metodo encargado de Reinicializar el Modelo del Formulario
    iniAndResetValues() {
        this._prepareCoverage = new prepareCoverageModel()
        this._prepareCoverage.country = "SPAIN"
    }
}
