import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';
import { DataTableDirective } from 'angular-datatables';


@Component({
    selector: 'app-historic-bonos',
    templateUrl: './historic-bonos.component.html',
    styleUrls: ['./historic-bonos.component.css']
})
export class HistoricBonosComponent implements OnInit {
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true
    };

    listHistoric: any = null
    constructor(private http: HttpClient, private cookie: CookieService, private loadingServ: LoadingServiceService) {}

    ngOnInit(): void {
        this.historicoDeBonos()
    }

    /**
     * Retorna un listado de bonos usados por una línea
     */
    historicoDeBonos() {

        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass")
        });
        const body = {

            "peticion": this.cookie.get("peticion"),

        }
        let url = environment.historicBonos;

        this.loadingServ.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.loadingServ.hideloading()
                    if (res.length == 0) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'No se han encontrado bonos para la linea seleccionada.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        })
                        this.loadingServ.historicBonos = false
                        return
                    }

                    this.listHistoric = res;
                }, (error: any) => {
                    this.loadingServ.hideloading()
                }

            )
        } catch {
            this.loadingServ.hideloading()
        }

    }

}