//______________________________________________________________________FORMAT DATEPICKER________________________________________________________________________________________
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {

            let day: string = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            let year = date.getFullYear();
            return `${day}-${month}-${year}`;
        } else {
            let monthsYear = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO",
                "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
            let year = date.getFullYear();
            let mes = monthsYear[date.getMonth().toString()]
            return `${mes} ${year}`;
        }
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { RepositoryDocumentationService } from 'src/app/services/repository-Documentation.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-init',
    templateUrl: './init.component.html',
    styleUrls: ['./init.component.css'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class InitComponent implements OnInit {
    con: any;
    poblacion;
    provincia;
    codigopostal;
    pais: any;
    title = 'rou';
    options = {
        componentRestrictions: {
            country: ["ES"]
        },
        types: ['address']
    }
    uDetails: user;
    public load: boolean = false;
    registroForm: UntypedFormGroup;
    data;
    data2;
    data3;
    data4;
    data5;
    data7: any;
    registrolineaform: UntypedFormGroup;
    aux: any;
    nif;
    tiponif;
    idcliente;
    nombre;
    apellido1;
    apellido2;
    botonCrearEstado = false;
    fechanac;
    tipocliente: any;
    direccion;
    cp;
    tipoCalle: any;
    contratoOficial;
    cuarto;
    tecnologia;
    portabilidad;
    numero;
    tarifa;
    previousNifValue: string;
    bono: any;
    doc1;
    doc2;
    doc3: any;
    tipo1;
    tipo2;
    tipo3: any;
    nombre1;
    nombre2;
    nombre3: any
    encontrado;
    contrato = {
        username: '',
        password: ''
    };
    setlinea = {
        "contratoOficial": '',
        "cuarto": '',
        "tecnologia": '',
        "portabilidad": '',
        "numero": '',
        "tarifa": '',
        "bono": ''
    }

    ext1: any;
    ext2: any;
    ext3: any;
    currentTime = new Date().valueOf();
    paisModel
    setusuario: boolean
    numeroCalle: String = "1";
    contratoModel
    longitudDocumento: number;
    cuartoaux: any;
    portar: boolean;

    constructor(
        public repositoryService: RepositoryDocumentationService, 
        private sanitizer: DomSanitizer, 
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private auth: AuthService,
        // private datePipe: DatePipe,
        private cookie: CookieService,) 
    {
        this.repositoryService.listArchFromServe = null
        this.encontrado = false;
    }

    ngOnInit(): void {
        this.tarifa = '0';
        this.bono = '0';
        this.longitudDocumento = 30;
        this.getPais();
        this.uDetails = this.auth.uLoged;
        this.getListContratos();
        this.initFormValidate();
        this.contratoModel = "0";
        this.paisModel = "0";
        this.portabilidad = "N";

        this.registroForm.get('nif')?.valueChanges.subscribe(value => {
            if(value) {
                this.nif = this.registroForm.get('nif').value;
                this.registroForm.get('nif')?.setValue(value.toUpperCase(), { emitEvent: false });
            }                 
        });
        this.registrolineaform.disable();
    }

    onNifKeyUp($event) {
        const currentValue = $event;
        if (currentValue !== this.previousNifValue) {
            this.nombre = "";
            this.direccion = "";
            this.apellido1 = "";
            this.poblacion = "";
            this.apellido2 = "";
            this.provincia = "";
            this.codigopostal = "";
            this.registroForm.get('fechanac').reset();
            this.registroForm.enable();
            this.registroForm.markAsUntouched();
            this.encontrado = false;
        }
    }

    /**
     * Obtiene el listado de contratos
     */
    getListContratos() {
        //__________________________________________Obteniendo contratos
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        this.contrato = {
            "username": this.cookie.get("user"),
            "password": this.cookie.get("pass")
        }
        const url2 = environment.getContratos;
        this.aux = null;
        try {
            this.http.post(url2, this.contrato, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.aux = res;
                } else {
                    this.aux = [];
                    this.aux.push(res);
                }
            });
        } catch {
            this.hideloading();
        }
    }

    /***
     * Evento al seleccionar el contrato
     */
    getContratoSelected(value) {
        if (value == 0) {
            this.registroForm.reset({
                contratoForm: '0',
                pais: '0'
            });
            this.registroForm.disable();           
            this.registroForm.controls['contratoForm'].enable();  
            this.registroForm.controls['fechanac'].disable();  
            this.encontrado = false;
             this.botonCrearEstado = false;
            const element = document.getElementById('erroRadioDocumento');
            element.style.border = 'none'
            const element2 = document.getElementById('erroRadioCliente');
            element2.style.border = 'none'
            this.registrolineaform.disable();
            this.registrolineaform.reset({
                bono: '0',
                tarifa: '0'
            });

        } else {
            const splittedValues = value.split('|');
            this.con = splittedValues[1];
            this.registroForm.enable();
            this.contratoOficial = splittedValues[0];
            this.registrolineaform.enable();

            //Reset de los campos.
            this.registroForm.get('nif').reset();
            this.registroForm.get('nombre').reset();
            this.registroForm.get('direccion').reset();
            this.registroForm.get('apellido1').reset();
            this.registroForm.get('poblacion').reset();
            this.registroForm.get('apellido2').reset();
            this.registroForm.get('fechanac').reset();
            this.registroForm.get('codigopostal').reset();
            this.registroForm.get('provincia').reset();
            this.registroForm.get('tiponif').reset()
            this.registroForm.get('tipocliente').reset();
            this.encontrado = false;
            this.registroForm.controls['fechanac'].enable();  
            this.botonCrearEstado = true;
        }
    }

    /**
     * Validador de campos del formulario
     */
    initFormValidate() {        
        this.registrolineaform = this.formBuilder.group({
            'tecnologia': ['TRUNK', [Validators.required]],
            'portabilidad': ['N', [Validators.required]],
            'numero': ['',], //no requerido ya que puede ser por portabilidad o no
            'tarifa': ['0', []],
            'bono': ['0', []],
            'fileCopia': ['',], //no requerido por que se puede subir a posteriori la documentacion
            'fileDocIdentidad': ['',], //no requerido por que se puede subir a posteriori la documentacion
            'fileGrabacion': ['',], //no requerido por que se puede subir a posteriori la documentacion
        });

        this.registroForm = this.formBuilder.group({
            'nif': ['', [Validators.required, Validators.maxLength(this.longitudDocumento)]],
            'tiponif': ['', [Validators.required]],
            'contratoForm': ['', [Validators.required]],
            'nombre': ['', [Validators.required]],
            'apellido1': ['', [Validators.required]], //No requeridos en caso de ser autonomo o otro tipo de cliente
            'apellido2': ['', [Validators.required]], //No requeridos en caso de ser autonomo o otro tipo de cliente
            'fechanac': [{ value: '', disabled: true}, [Validators.required]],
            'direccion': ['', [Validators.required]],
            'codigopostal': ['', [Validators.required]],
            'tipocliente': ['', [Validators.required]],
            'poblacion': ['', [Validators.required]],
            'provincia': ['', [Validators.required]],
            'pais': ['', [Validators.required]],
        });
        this.registroForm.disable();
        this.registroForm.controls['contratoForm'].enable();
    }

    //______________________________________________________________CREANDO LINEA
    setLineas() {
        /* if (this.validaCamposLinea() == false) { */
            //===================VALIDACION===========================
            if (this.validateLinea() != "TODO CORRECTO") {
                this.createErrorAlert(this.validateLinea());
                return
            }
            //===================END VALIDACION===========================
            this.setlinea = {
                contratoOficial: this.contratoOficial,
                cuarto: this.cookie.get("cuarto"),
                tecnologia: this.registrolineaform.get('tecnologia').value,
                portabilidad: this.registrolineaform.get('portabilidad').value,
                numero: (this.registrolineaform.get('portabilidad').value == 'S') ? this.registrolineaform.get('numero').value : '0',
                tarifa: this.registrolineaform.get('tarifa').value,
                bono: this.registrolineaform.get('bono').value
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            // if (this.registrolineaform.valid) {
            const url = environment.setLineasFijo;
            try {
                this.showloading();
                this.http.post(url, this.setlinea, { headers }).subscribe(
                    (res: any) => { // Success
                        this.hideloading();
                        //Si existe documentacion adjuntada se subirá
                        this.subirDocumentacionSiExiste(res.Registro.Numero);
                        if (this.registrolineaform.get('portabilidad').value == "N") { //Sin portabilidad
                            this.hideloading();
                            Swal.fire({
                                icon: 'success',
                                title: 'COMPLETADO',
                                text: 'Se ha creado una línea con número: (' + res.Registro.Numero + ') y número de incidencia: (' + res.Registro.inci + '). ¿Desea exportar el contrato en formato PDF?',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                                denyButtonText: "No, lo haré mas adelante",
                                showDenyButton: true,
                                showConfirmButton: true,
                                confirmButtonText: "Si, deseo exportarlo"
                            }).then(response => {
                                if (response.isConfirmed) {
                                    this.exportarContratoFijoEnPdf(res.Registro.Numero);
                                    this.portabilidadClick("S");
                                }
                                this.registrolineaform.reset();
                                this.registroForm.reset();
                            })
                            this.registrolineaform.get('DocIdentity').reset();
                            this.registrolineaform.get('Copia').reset();
                            this.registrolineaform.get('grabacion').reset();
                            this.registrolineaform.reset();
                            this.initFormValidate();

                        } else { //Con portabilidad
                            this.hideloading();
                            Swal.fire({
                                icon: 'success',
                                title: 'COMPLETADO',
                                text: 'Correcto. Se ha creado la línea con portabilidad en el teléfono: ' + res.Registro.Numero + ' ¿Desea exportar el contrato en formato PDF?',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                                denyButtonText: "No, lo haré mas adelante",
                                showDenyButton: true,
                                showConfirmButton: true,
                                confirmButtonText: "Si, deseo exportarlo"
                            }).then(response => {
                                if (response.isConfirmed) {
                                    //se descargará el contrato en pdf
                                    this.exportarContratoFijoEnPdf();
                                    this.portabilidadClick("S");
                                }
                                this.registrolineaform.reset();
                                this.registroForm.reset();
                            })
                        }
                    },
                    (respuesta: any) => { // Error
                        this.hideloading();
                        let mensaje = "Se ha producido un error al crear la línea";
                        if (respuesta.error.Registro.Error) {
                            mensaje = respuesta.error.Registro.Error;
                        }
                        this.createErrorAlert(mensaje);
                    });
            } catch {
                this.hideloading();
            }
       /*  } */
    }

    //__________________________________________ENVIAR_DOCUMENTO
    setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) { //SI SE EXCEDE LOS 5MB

            Swal.fire({ title: "ARCHIVO DEMASIADO GRANDE", icon: "warning", text: "El archivo supera el límite de 5Mb." })
            this.load = false;
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // let terceroOperador = this.cookie.get("tercero");
        // let cuartoCliente = this.cookie.get("cuarto");
        reader.onload = () => {
            switch (t) {
                case "DocIdentidad":
                    this.doc1 = reader.result;
                    this.nombre1 = file.name.split(".")[0];
                    this.ext1 = file.name.split(".").pop();
                    break;
                case "Copia":
                    this.doc2 = reader.result;
                    this.nombre2 = file.name.split(".")[0];
                    this.ext2 = file.name.split(".").pop();
                    break;
                case "grabacion":
                    this.doc3 = reader.result;
                    this.nombre3 = file.name.split(".")[0];
                    this.ext3 = file.name.split(".").pop();
                    break;
            }
        };
    }


    /**
     * Sube documentacion o crea el path si el cliente ha dado de alta a una linea movil
     */
    subirDocumentacionSiExiste(telefono: String) {
        let terceroOperador = this.cookie.get("tercero");
        if (this.doc1) {
            this.repositoryService.uplodaDocumentsFijo(this.doc1, this.ext1, terceroOperador, telefono, this.nombre1);
        }
        if (this.doc2) {
            this.repositoryService.uplodaDocumentsFijo(this.doc2, this.ext2, terceroOperador, telefono, this.nombre2);
        }
        if (this.doc3) {
            this.repositoryService.uplodaDocumentsFijo(this.doc3, this.ext3, terceroOperador, telefono, this.nombre3);
        }
    }

    /**
     * Retorna la fecha en formato dd_mm_yyyy_hh_mm_ss para los archivos subidos
     */
    dateNowToString() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "_" + mes + "_" + date.getFullYear() + "_" + date.getHours() + "_" + date.getMinutes() + "_" + date.getSeconds();
    }

    //_________________________COMPROBAR_OPERADOR
    loadXML() {
        return this.http.get(environment.cOperador + this.registrolineaform.get('numero').value, { responseType: 'text' })
    }

    parseData(data: any) {
        let parser: DOMParser = new DOMParser();
        let xmlDoc = parser.parseFromString(data, "text/xml");
        let elements: HTMLCollection;
        elements = xmlDoc.getElementsByTagName("Registro");
        if (elements[0]) {
            let nombre: any = elements[0].getAttribute("Operador");
            if (nombre) {
                Swal.fire('Búsqueda completada del Operador', nombre, 'info')
            }
        }
        return true;
    }

    cOperador() {
        if (this.registrolineaform.get('numero').value != null) {
            this.loadXML()
                .subscribe(res => {
                    if (this.parseData(res)) {
                        this.parseData(res);
                    }
                })
            setTimeout(() => { }, 2000);
        } else {
            this.createErrorAlert('Debe introducir el número previamente.');
        }
    }

    //_________________________________VALIDADORES
    isNif(documento) {
        var lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
        var isNif = false;
        if (documento.length == 9) {
            var letter = documento.substring(8, 9);
            var number = parseInt(documento.substring(0, 8));
            if (!isNaN(number) && isNaN(parseInt(letter))) {
                if (lettersEnd[number % 23] === letter) {
                    isNif = true;
                }
            }
        }
        return isNif;
    }

    isNie(documento) {
        var isNie = false;
        var num = [];
        if (documento.match('^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]')) {
            for (let i = 0; i < 9; i++) {
                var aux = i;
                num[i] = documento.substr(i, aux++);
            }
            var letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
            var dniTransform = documento.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
            if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
                isNie = true;
            }
        }
        return isNie;
    }

    validaCif(documento) {
        var isCif = false;
        if (documento == "") {
            return isCif;
        }
        var texto = documento;
        var pares = 0;
        var impares = 0;
        var suma;
        var ultima;
        var unumero;
        var uletra = new Array("J", "A", "B", "C", "D", "E", "F", "G", "H", "I");
        var xxx;
        texto = texto.toUpperCase();
        var regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g);

        if (!regular.exec(texto)) { }
        ultima = texto.substr(8, 1);
        for (var cont = 1; cont < 7; cont++) {
            xxx = (2 * parseInt(texto.substr(cont++, 1))).toString() + "0";
            impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
            pares += parseInt(texto.substr(cont, 1));
        }
        xxx = (2 * parseInt(texto.substr(cont, 1))).toString() + "0";
        impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

        suma = (pares + impares).toString();
        unumero = parseInt(suma.substr(suma.length - 1, 1));
        unumero = (10 - unumero).toString();
        if (unumero == 10) unumero = 0;
        if (ultima == unumero || ultima == uletra[unumero]) {
            isCif = true;
            return isCif;
        } else {
            return isCif;
        }
    }

    //_______________________________________________Loading
    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }

    //_________________________________CREAR_USUARIO_NIF/CIF/NIE
    crearUsuario() {
        if (this.validaCamposCliente() == false) {
            let valor = false;
            let data = {};
            switch (this.registroForm.get('tiponif').value) {
                case 'N':
                    if (this.isNif(this.registroForm.get('nif').value)) { valor = true; this.paisModel = "34" }
                    if (this.isNie(this.registroForm.get('nif').value)) { valor = true; this.paisModel = String(this.registroForm.get('pais').value) }
                    break;
                case 'C':
                    if (this.validaCif(this.registroForm.get('nif').value)) { valor = true; this.paisModel = "34" }
                    break;
                case 'P':
                    if (this.registroForm.get('nif').value.length <= 30) {
                        valor = true
                        this.paisModel = String(this.registroForm.get('pais').value);
                    }
                    break;
                default:
                    break;
            }

            if (valor === true) {
                data = JSON.parse(JSON.stringify(this.registroForm.value));
                data['ip'] = this.cookie.get("ip");
                data['tercero'] = this.cookie.get("tercero");
                delete data['idcliente'];
                delete data['tiponif'];
                delete data['fechanac'];
                if (this.registroForm.get('tiponif').value !== undefined) {
                    data['tipoNif'] = this.registroForm.get('tiponif').value;
                    let fechaNacimiento = this.registroForm.get('fechanac').value
                    data['fechaNacimiento'] = fechaNacimiento.getFullYear() + "/" + (fechaNacimiento.getMonth() + 1) + "/" + fechaNacimiento.getDate();
                }
                data['pais'] = this.paisModel;
                data['contratoProvisional'] = this.con;
            } else {
                this.createErrorAlert('El documento de identidad no es válido.');
            }

            if (this.validacionDatosUsuario(this.registroForm.value) !== '1') {
                this.createErrorAlert(this.validacionDatosUsuario(this.registroForm.value));
                return;
            }
            if (this.getUsuario(false) == undefined) {
                this.showloading();
                const headers = new HttpHeaders({
                    'user': this.cookie.get("user"),
                    'token': this.cookie.get("token"),
                    'pass': this.cookie.get("pass"),
                });
                const url = environment.setCliente;
                this.http.post(url, data, { headers }).subscribe((res: any) => {
                    if (!res.Pedido) {
                        this.createErrorAlert("NIF o Nombre no identificado por AEAT");
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'COMPLETADO',
                            text: 'El usuario ' + data['nombre'] + ' ' + data['apellido1'] + ' ' + data['apellido2'] + ' ha sido registrado correctamente.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                        this.registroForm.reset();
                        this.getContratoSelected(0);
                    }
                    this.hideloading();
                });
            };
        }
    }

    editarUsuario() {
        let modifiedFields = ['nif', 'direccion', 'codigopostal', 'poblacion', 'provincia']
        const dataModified = {};
        for (let field of modifiedFields) {
            if (field == 'nif') {
                dataModified['documento'] = this.registroForm.controls[field].value;
            } else if (field == 'codigopostal') {
                dataModified['codPostal'] = this.registroForm.controls[field].value;
            }
            dataModified['tercero'] = this.cookie.get('tercero');
            dataModified['contratoProvisional'] = this.con;
            dataModified[field] = this.registroForm.controls[field].value;
        }


        this.showloading();
        const headers = new HttpHeaders({
            'pass': this.cookie.get("pass"),
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const url = environment.modifyCliente;
        this.http.post(url, dataModified, { headers }).subscribe((res: any) => {
            if (res.error) {
                this.createErrorAlert("No se ha podido modificar el usuario");
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'COMPLETADO',
                    text: 'El usuario ' + this.registroForm.controls['nombre'].value + ' ' + this.registroForm.controls['apellido1'].value + ' ' + this.registroForm.controls['apellido2'].value + ' ha sido modificado correctamente.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                this.registroForm.reset();
                this.getContratoSelected(0);
            }
            this.hideloading();
        });
    }

    //_________________________________DIRECCIÓN CON GOOGLE
    AddressChange(address: any) {
        if (address.address_components) {
            var paisSeleccionado: String = ""
            for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
                const componentType = component.types[0];
                switch (componentType) {
                    case "route":
                        this.direccion = `${component.long_name}`;
                        break;
                    case "street_number":
                        this.numeroCalle = `${component.long_name}`;
                        break;
                    case "postal_code":
                        this.codigopostal = `${component.long_name}`;
                        break;
                    case "locality":
                        this.poblacion = `${component.long_name}`;
                        break;
                    case "administrative_area_level_2":
                        this.provincia = `${component.long_name}`;
                        break;
                    case "country":
                        paisSeleccionado = `${component.long_name}`;
                        break;
                }
            }
            this.direccion += ", " + this.numeroCalle;
        }
        var paisSelect = (document.getElementById("pais")) as HTMLSelectElement
        const select = paisSelect;
        const value = select.value;
        const desc = select.selectedOptions[0].text = paisSeleccionado.toUpperCase();

        this.data7.forEach(element => {
            if (element.desc == paisSeleccionado.toUpperCase()) {
                this.pais = element.codi;
            }
        });
    }

    //_________________________________BUSCAR USUARIO

    getUsuario(showAlert: boolean) {

        // this.nif = this.registroForm.get('nif').value;

        console.log(this.nif);

        if (this.nif == null || this.nif == '') {
            const NIF = this.registroForm.get('nif');
            this.remarcarErrores(NIF);
        } else {
            this.showloading();
            /* if(!this.isNie(this.nif) && !this.isNif(this.nif) && !this.validaCif(this.nif) && !(this.nif.length <= 30)){
                this.createErrorAlert('Introduzca un NIF/NIE/CIF válido.');
                this.hideloading()
                return false;
            } */
            const body = {
                "nif": this.nif,
                "tercero": this.cookie.get('tercero'),
                "contratoProvisional": this.con
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            const url = environment.getUsuario;
            this.http.post(url, body, { headers }).subscribe((res: any) => {// Success
                if (res.id) {
                    this.hideloading();
                    if (showAlert) this.createErrorAlert('Usuario no encontrado.');
                    return false;
                } else {
                    this.cookie.set('cuarto', res.cod) //seteado del usuario
                    let infoSociedad = false, nom, ape1, ape2, documento, tcliente;
                    if (this.isNif(res.doc) || this.isNie(res.doc)) {
                        documento = "N";
                    } else if (this.validaCif(res.doc)) {
                        documento = "C";
                    } else if (!this.validaCif(res.doc) && !this.validaCif(res.doc) && !this.isNif(res.doc)) {
                        documento = "P";
                    }

                    if (res.tcli == "R") {
                        tcliente = "R";
                    } else if (res.tcli == "S") {
                        tcliente = "S";
                    } else if (res.tcli == "A") {
                        tcliente = "A";
                    }

                    /* if (res.fecha2) {
                        let splitted = res.fecha2.split("/");
                        let mes = splitted[1];
                        let dia = splitted[0];
                        let ano = splitted[2];
                        var formatmes = Number(mes)
                        var formatdia = Number(dia)
                        var formatano = Number(ano)                        
                    } */
                    if (res.fecha2) {
                        let fecha = res.fecha2;

                        let ano = fecha.substring(0, 4);
                        let mes = fecha.substring(4, 6);
                        let dia = fecha.substring(6, 8);                        
                        
                        var formatano = Number(ano);
                        var formatmes = Number(mes);
                        var formatdia = Number(dia);
                    }
                    //PredeterminadaMente el pais será España
                    this.paisModel = "34"
                    // var paisSelect = (document.getElementById("paisSelect")) as HTMLSelectElement
                    // var sel = paisSelect.selectedIndex; //obtengo la opcion seleccionada
                    // var opt = paisSelect.options[sel]; //obtengo el option seleccionado
                    // var paisString = (opt).text = "España";
                    // (opt).value = this.paisModel

                    this.registroForm.patchValue({
                        apellido1: res.tcli == 'C' ? '' : res.ape1,
                        apellido2: res.tcli == 'C' ? '' : res.ape2,
                        nombre: res.tcli == 'C' ? res.des : res.nom,
                        codigopostal: res.cp,
                        direccion: res.dir,
                        fechanac: new Date(formatano, formatmes - 1, formatdia),
                        tiponif: documento,
                        poblacion: res.poblacion,
                        provincia: res.localidad,
                        tipocliente: tcliente,
                        pais: this.paisModel
                    })
                    let disabledFields = ['nombre', 'apellido1', 'apellido2', 'fechanac', 'tipocliente', 'tiponif'];
                    for (let field of disabledFields) {
                        this.registroForm.controls[field].disable();
                    }
                    this.cookie.set("cuarto", res.cod);
                    this.cuartoaux = res.cod

                    this.encontrado = true;
                    this.hideloading();
                    this.previousNifValue = this.nif;
                    if (this.registroForm.status == "VALID") {
                        // console.log(this.registroForm);
                        this.botonCrearEstado = true;
                    }
                    return true;
                }
            });
        }
    }

    remarcarErrores(valor) {
        valor.markAsUntouched();
        setTimeout(() => {
            valor.markAsTouched();
        });
    }
    //_________________________________CARGAR DATOS DE PAÍS
    getPais() {
        this.showloading()
        let promise = new Promise<void>((resolve, reject) => {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.getPais;
            try {
                this.http.post(url, {}, { headers }).toPromise().then(
                    res => { // Success
                        this.data7 = res
                        for (var i = 0; i < this.data7.length; i++) {
                            var con = this.data7[i];
                        }
                        this.hideloading()
                        resolve();
                    },
                    msg => { // Error
                        reject(msg);
                        this.hideloading();
                        this.createErrorAlert('Se ha producido un error al cargar los países.')
                    }
                );
            } catch {
                this.hideloading();
            }
        });
        return promise;
    }

    /**
     * Función encargada de crear con los datos del formulario un archivo pdf; este mismo  será el contrato del cliente
     * @param nameFile nombre que recibirá el archivo sin contar la extensión
     * @returns 
     */
    exportarContratoFijoEnPdf(numSinPortabilidad?) {
        let nombre = this.registroForm.get("nombre").value;
        let apellido1 = this.registroForm.get("apellido1").value;
        let apellido2 = this.registroForm.get("apellido2").value;

        let nombrePDF = 'Contrato_' + nombre + '_' + apellido1 + '_' + apellido2 + '.pdf';

        if (!this.registroForm.valid) {
            this.createErrorAlert('Debe rellenar todos los datos para poder exportar el contrato en formato PDF.');
            return
        }
        if (!this.registrolineaform.valid) {
            this.createErrorAlert('Debe rellenar todos los datos de la línea para poder exportar el contrato en formato PDF.');
            return
        }

        if (!this.registroForm.value.nif) { // SI no se ha seleccionado ningun Nif del cliente 
            Swal.fire({
                text: "Debe seleccionar un cliente.",
                icon: 'warning',
                showConfirmButton: true,
            })
            return
        }
        let datos = {
            nombre: this.registroForm.get("nombre").value,
            apellido1: this.registroForm.get("apellido1").value,
            apellido2: this.registroForm.get("apellido2").value,
            nif: this.registroForm.get("nif").value,
            direccion: this.registroForm.get("direccion").value,
            cp: this.registroForm.get("codigopostal").value,
            poblacion: this.registroForm.get("poblacion").value,
            provincia: this.registroForm.get("provincia").value,
            tecnologia: this.registrolineaform.get("tecnologia").value,
            portabilidad: this.registrolineaform.get("portabilidad").value,
            numero: this.registrolineaform.get("numero").value,
        }
        this.repositoryService.exportarContratoFijoEnPDF(datos, nombrePDF, numSinPortabilidad);
    }
    portabilidadClick(value) {
        this.registrolineaform.controls['portabilidad'].disable();
        value == "S" ? this.portar = true : this.portar = false
    }

    validateLinea() {
        if (!this.contratoOficial) {
            return "Debe seleccionar un contrato previamente";
        } else if (!this.cookie.get("cuarto")) {
            return "Debe tener un cliente para dar de alta a la linea";
        } else if (this.registrolineaform.get('portabilidad').value == "S" && !this.registrolineaform.get('numero').value) {
            return "Debe introducir el número a portar";
        } else if (!this.registrolineaform.get('tarifa').value) {
            return "Debe seleccionar previamente una tarifa";
        } else if (!this.registrolineaform.get('bono').value) {
            return "Debe seleccionar previamente una bono";
        }
        return "TODO CORRECTO"
    }

    /**
     * Descarga del base del contrato para retornar el PDF
     */
    downloadData(base64, nombre) {
        const linkSource = "data:application/pdf;base64," + base64;
        let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        var a = document.createElement("a"); //Create <a>
        a.href = linkSource; //Image Base64 Goes here
        a.download = nombre; //File name Here
        a.click(); //Downloaded file
    }

    /**
     * Validación al crear o modificar a un cliente
     * Devuelve '1' si no hay ningun error
     */
    validacionDatosUsuario(data) {
        let messagesError = "";

        if (this.contratoOficial == undefined) {
            return messagesError = "FALTA POR SELECCIONAR EL CONTRATO";
        }

        for (const element in data) {
            if (data[element] == undefined) {
                if (element == 'tipocliente') { return messagesError = "FALTA POR INTRODUCIR EL CAMPO 'TIPO DE CLIENTE'"; }
                if (element == 'fechanac') { return messagesError = "FALTA POR INTRODUCIR EL CAMPO 'FECHA DE NACIMIENTO'"; }
                return messagesError = "FALTA POR INTRODUCIR EL CAMPO '" + element.toUpperCase() + "'";
            }
        }
        return "1";
    }

    /**
     * Crea un Swal para errores
     */
    createErrorAlert(text, title = 'ERROR', html?) {
        Swal.fire({
            icon: 'error',
            title: title,
            background: '#F5F5F5',
            text: text,
            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
            html: html
        })
    }

    needPais(tipo) {
        //getted from event
        if (tipo == "P") {
            this.registroForm.controls['pais'].enable();
        } else {
            this.registroForm.controls['pais'].enable();
        }
    }

    /**
     * Comprueba que tipo de documento se ha seleccionado para modificar la longitud del input donde se introduce el documento
     */
    checkNifType() {
        switch (this.tiponif) {
            case 'N':
            case 'C':
                this.longitudDocumento = 9;
                break;
            case 'P':
                this.longitudDocumento = 30;
                break;
        }
        this.registroForm.controls['nif'].setValidators([Validators.maxLength(this.longitudDocumento)]);
    }

    guardaTipoCliente(letra: string) {
        this.tipocliente = letra;
    }

    /**
     * Calcula la fecha de nacimiento de una persona la cual, a día de hoy sería mayor de edad
     */
    obtenerFechaMayorEdad() {
        const today = new Date();
        let mayoriaEdad = 18;
        let minFechaNac = new Date();
        minFechaNac.setMonth(today.getMonth() - (mayoriaEdad * 12));
        minFechaNac.setDate(minFechaNac.getDate() - 1);
        return minFechaNac;
    }

    validaCamposCliente() {
        var result = false;
        if (this.registroForm.invalid) {
            const validacion1 = document.getElementById('erroRadioDocumento');
            const validacion2 = document.getElementById('erroRadioCliente');
            if (this.registroForm.get('tiponif').value == null || this.registroForm.get('tiponif').value == undefined) {
                validacion1.classList.remove('errorParpadeo');
                void validacion1.offsetWidth;
                validacion1.classList.add('errorParpadeo');
                result = true;
            } else { validacion1.classList.remove('errorParpadeo'); }

            if (this.registroForm.get('tipocliente').value == null || this.registroForm.get('tipocliente').value == undefined) {
                validacion2.classList.remove('errorParpadeo');
                void validacion2.offsetWidth;
                validacion2.classList.add('errorParpadeo');
                result = true;
            } else { validacion2.classList.remove('errorParpadeo'); }

            Object.values(this.registroForm.controls).forEach(control => {
                if (control.invalid) { this.remarcarErrores(control); }
            });
            result = true;
        }
        return result;
    }

    validaCamposLinea() {
        var result = false;
        const validacionTarifa = document.getElementById('tarifaSelect');
        if (this.tarifa == 0) {
            validacionTarifa.classList.remove('errorParpadeo');
            void validacionTarifa.offsetWidth;
            validacionTarifa.classList.add('errorParpadeo');
            result = true;
        }

        const validacionBono = document.getElementById('bonoSelect');
        if (this.bono == 0) {
            validacionBono.classList.remove('errorParpadeo');
            void validacionBono.offsetWidth;
            validacionBono.classList.add('errorParpadeo');
            result = true;
        }
        return result;
    }
}