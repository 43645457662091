import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ModalService } from './modal-white-brand.service';
import { CookieService } from 'ngx-cookie-service';
import { prepareCoverageModel } from 'src/app/model/prepareCoverage';



@Component({
  selector: 'app-create-account-white-brand',
  templateUrl: './create-account-white-brand.component.html',
  styleUrls: ['./create-account-white-brand.component.css']
})
export class CreateAccountWhiteBrandComponent implements OnInit, AfterViewInit {
  public static PROVINCIAS=2000;
  public static MUNICIPIOS=2001;
  whiteBrandForm;
  public listProvincias=[];
  public listMunicipio=[];
  carga: boolean=false;
  cargaMunicipio: boolean=false;
  private accessToken: string;
  private refreshToken: string;
  muestraMunicipio: boolean = false;
  muestraBuscador: boolean = false;
  private comunidadAutonoma:string;
  private municipio:string;
  private provincia:string;

  @ViewChild('body')  bodyElemento: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalServiceWhiteBrand: ModalService,
    private cookie: CookieService,
    private http: HttpClient
  ) { }
  
  ngAfterViewInit(): void {
    this.bodyElemento.nativeElement.offsetParent.scrollTop=0;
  }

  ngOnInit(): void {
    this.modalServiceWhiteBrand._prepareCoverage.street=null;
    this.initEndPointBitstream();
    this.whiteBrandForm = this.formBuilder.group({
            'provincia': ['', [Validators.required]],
            'municipio': ['', [Validators.required]],
            'buscadorInput': ['', [Validators.required]],
            'numero': ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.max(999)]],
            'planta':['', [Validators.pattern('[0-9]+'), Validators.max(999)]],
            'puerta':['', [Validators.max(999)]],
            'portal':['', [Validators.max(999)]],
            'escalera':['', [Validators.pattern('[0-9]+'), Validators.max(999)]],
            'local':['', [Validators.pattern('[0-9]+'), Validators.max(999)]],
    })
  }

  /**
   * Hace una llamada al enppoint y devuelve las provincias en un array
   */
  getProvincias(){ 
    const headers={
      "accept": "application/json",
      "Authorization": "Bearer "+this.accessToken
    }
    var provincias = [];
    this.http.get(environment.getProvinciasBitstream, {headers}).subscribe(
        (res: any) => {
            res.forEach(element => {
                provincias.push(element)
            });
            this.ocultarLoading();
        });
    return provincias;
  }

  /**
   * Hace una llamada al enppoint y devuelve las municipios en un array
   */
   getMunicipios(idProvincia: number){
    this.cargaMunicipio=true;
    this.muestraMunicipio=false;
    const headers={
      "accept": "application/json",
      "Authorization": "Bearer "+this.accessToken
    }
    var municipios = [];
    this.http.get(environment.getMunicipiosBitstream+idProvincia, {headers}).subscribe(
        (res: any) => {
            res.forEach(element => {
              municipios.push(element);
            });
          this.cargaMunicipio=false;
          this.muestraMunicipio=true;
        });
    return municipios;
  }

  getCoincidencias(){
    this.mostrarLoading();
    const headers={
      "accept": "application/json",
      "Authorization": "Bearer "+this.accessToken
    }
    try {
        this.http.get(environment.getCalleBitstream+this.modalServiceWhiteBrand.codProvinciaSelected+'/'+this.modalServiceWhiteBrand.codMunicipioSelected+'/'+this.whiteBrandForm.controls['buscadorInput'].value, { headers }).subscribe(
            (res: any) => {
                this.ocultarLoading();
                this.modalServiceWhiteBrand._prepareCoverage.street="";                
                if (res.length) {
                    this.modalServiceWhiteBrand.listCoincidencias = res;
                } else {
                    Swal.fire({
                        title: 'SIN COINCIDENCIAS',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Ok',
                        text: "No existen coincidencias para la busqueda."
                    })
                }
            }, error => {
                this.ocultarLoading()
                Swal.fire({
                    title: 'ERROR',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Confirmar',
                    showCloseButton: true,
                    text: "La dirección proporcionada no se encuentra en el sistema. Revise los datos.",
                })
            })
    } catch {
        this.ocultarLoading();
    }
  }

  seleccionado(codigoSelect: number, valor){
    let valores=valor.split('|');
    switch(codigoSelect){
      case CreateAccountWhiteBrandComponent.PROVINCIAS:
        if(valor.length){
          this.comunidadAutonoma=valores[1];
          this.provincia=valores[2];
          this.modalServiceWhiteBrand.codProvinciaSelected=valores[0];
          this.listMunicipio=this.getMunicipios(valores[0]);
        }else{
          this.comunidadAutonoma="";
          this.provincia="";
          this.listMunicipio=null;
          this.muestraMunicipio = false;
          this.muestraBuscador = false;
          this.whiteBrandForm.controls['municipio'].value='';
          this.whiteBrandForm.controls['buscadorInput'].value='';
          this.modalServiceWhiteBrand._prepareCoverage.street=null;
        } 
        break;
      case CreateAccountWhiteBrandComponent.MUNICIPIOS:
        if(valor.length){
          this.municipio=valores[1];
          this.modalServiceWhiteBrand.codMunicipioSelected=valores[0];
          this.muestraBuscador = true;
        }else {
          this.municipio="";
          this.muestraBuscador = false;
          this.whiteBrandForm.controls['buscadorInput'].value='';
          this.modalServiceWhiteBrand._prepareCoverage.street=null;
        }
        
        break;
      default:
        break;
    }
  }
  mostrarLoading(){
    this.carga=true;
  }
  ocultarLoading(){
    this.carga=false;
  }

  initEndPointBitstream(){
    this.mostrarLoading();
    const body = {
      "email": "ptvtelecom@ptvtelecom.com",
      "password": "tZ516$"
    };
    const loginEnpoint=environment.loginBitstream;
    this.http.post(loginEnpoint, body).subscribe((res: any)=>{
      this.accessToken=res.access_token;
      this.refreshToken=res.refresh_token;
      this.listProvincias=this.getProvincias();
    },(error)=>{
      console.error(error);
    });
  }

  siguienteClick(){
    if(this.comprobarValidezDatos()){
      this.modalServiceWhiteBrand.accessToken=this.accessToken;
      this.modalServiceWhiteBrand.numero=this.whiteBrandForm.controls['numero'].value.toString();
      this.modalServiceWhiteBrand.datosDireccion={
        'provincia':this.provincia,
        'municipio':this.municipio,
        'tipoVia':this.modalServiceWhiteBrand._prepareCoverage.Tipo,
        'calle':this.modalServiceWhiteBrand._prepareCoverage.street,
        'numero':this.whiteBrandForm.controls['numero'].value.toString(),
        'planta':this.whiteBrandForm.controls['planta'].value.toString(),
        'puerta':this.whiteBrandForm.controls['puerta'].value.toString(),
        'portal':this.whiteBrandForm.controls['portal'].value.toString(),
        'escalera':this.whiteBrandForm.controls['escalera'].value.toString(),
        'local':this.whiteBrandForm.controls['local'].value.toString(),
      };
      this.modalServiceWhiteBrand.siguiente();
    }
  }

  /**
   * Comprueba que los datos obligatorios esten escrito y los que no tengan el formato esperado
   */
  comprobarValidezDatos(){
    var esValido=true;
    var errorView = '<ul class="errorView">';    
    if(this.whiteBrandForm.controls['numero'].status=='INVALID'){
      errorView += '<li>El numero no es valido</li>';
      esValido=false;
    }
    if(this.whiteBrandForm.controls['planta'].status=='INVALID'){
      errorView += '<li>La planta no es valida</li>';
      esValido=false;
    }
    if(this.whiteBrandForm.controls['escalera'].status=='INVALID'){
      errorView += '<li>La escalera no es valida</li>';
      esValido=false;
    }
    if(this.whiteBrandForm.controls['local'].status=='INVALID'){
      errorView += '<li>El local no es valido</li>';
      esValido=false;
    }
    errorView += '</ul>'
    if(esValido) return esValido;
    else{
      Swal.fire({
          title: 'ERRORES ENCONTRADOS',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: errorView
      });
      return esValido;
    }
  }
}
