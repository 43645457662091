import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
    selector: 'app-modal-domicilios',
    templateUrl: './modal-domicilios.component.html',
    styleUrls: ['./modal-domicilios.component.css']
})
export class ModalDomiciliosComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {

        search: false,
        paging: true,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        order: false
    };
    @Input()
    public modalService: ModalService

    dtTrigger: Subject<void> = new Subject<void>();
    constructor() {}

    ngOnInit(): void {}

}