import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-advanced-find-riesgo',
    templateUrl: './advanced-find-riesgo.component.html',
    styleUrls: ['./advanced-find-riesgo.component.css']
})
export class AdvancedFindRiesgoComponent implements OnInit, OnDestroy, AfterViewInit {
    loadModal: boolean
    advancedForm: UntypedFormGroup;
    data: any
    registroBloqueo: UntypedFormGroup;

    limiteModel: NgModel
    bloqueo: NgModel
    telefonoSeleccionado
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bflrtip',
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,        
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();

    constructor(private formBuilder: UntypedFormBuilder,
        private cookie: CookieService,
        private http: HttpClient) {}

    ngOnInit(): void {
        this.initFormGroup()
        this.registroBloqueo = this.formBuilder.group({
            'limiteForm': ['', ],
            'bloqueoForm': ['', ]
        })
        var dataTable = $("#datatableAdvancedRiesgo").dataTable();
        dataTable.DataTable().destroy
    }
    ngAfterViewInit(): void {
        var dataTable = $("#datatableAdvancedRiesgo").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    /**
     * Actualizaciópn del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatableAdvancedRiesgo").dataTable();
        dataTable.DataTable().destroy
        $('#datatableAdvancedRiesgo').DataTable({

            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
     * Evento encargado de buscar los detalle de una línea Movil
     * @param value telefono Movil
     * @returns 
     */
        async findNumber(value) {
        if (value.length == 9) {
            if (value.substring(0, 1) != "7" && value.substring(0, 1) != "6") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                return
            }
            await this.showLoadModal()
            let codigoTercero = this.cookie.get("tercero")
            const telefonoMovil = value;
            const body = {
                "telefono": telefonoMovil,
                "terceroCliente": codigoTercero
            }

            this.telefonoSeleccionado = telefonoMovil;
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });
            const url = environment.getMostrarRiesgo
            this.showLoadModal();
            this.data = null
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.data = res;
                    this.updateDataTable()
                    this.initFormChanges(res.Limite, res.Bloquear)
                    if (res.Limite && res.Bloquear) {
                        // this.data = []
                    } else {
                        this.hiddeModal();
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: res.Error,
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        })
                        this.data = null
                        this.updateDataTable()
                    }
                    this.hiddeModal()
                }, error => {
                    this.data = null
                    this.updateDataTable()
                    this.hiddeModal()
                })
            } catch {
                this.data = null
                this.updateDataTable()
                this.hiddeModal()
            }
        }
    }

    //______________________________MODAL ________________________________

    showLoadModal() {
        this.loadModal = true
    }
    hiddeModal() {
        this.loadModal = false
    }
    /**
     * Validación del formulario
     */
    initFormGroup() {
        this.advancedForm = this.formBuilder.group({
            'telf': ['', [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(9),
                Validators.pattern(/^[6||7]\d{8}$/gm)
            ]]
        })
    }

    /**
     * Encargado de actualizar el limite y bloqueo de una linea
     * @param limite 
     * @param bloqueo 
     */
    initFormChanges(limite, bloqueo) {
        var bloquear = true;
        (bloqueo == '0') ? bloquear = false: bloquear = true;
        this.registroBloqueo = this.formBuilder.group({
            'limiteForm': [limite, ],
            'bloqueoForm': [bloquear, ]
        })
    }

    //__________________________________GUARDAR CONTROL DE RIEGO__________________________________
    async guardarLimiteDeRiesgo() {
        const url = environment.getGuardarLimiteRiesgo
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass")
        });
        var bloqueo = "0";

        (this.registroBloqueo.get("bloqueoForm").value) ? bloqueo = '1': bloqueo = '0';

        let decimal = this.registroBloqueo.get("limiteForm").value.split(".")[1];
        var limite = this.registroBloqueo.get("limiteForm").value;

        var decimales = "";
        if (decimal < 6) {
            for (var i = 1; i < (6 - decimal.length); i++) {
                decimales += "0";
            }
            limite += decimales;
        }

        // return;
        const body = {
            "terceroCliente": this.cookie.get("tercero"),
            "telefono": this.telefonoSeleccionado,
            "limite": limite,
            "bloqueo": bloqueo
        }

        this.showLoadModal();
        this.http.post(url, body, { headers }).subscribe(
            (res: any) => {
                this.hiddeModal()
                Swal.fire({
                    icon: "success",
                    text: res.msg
                })
            },
            error => {
                this.hiddeModal()
                Swal.fire({
                    icon: "warning",
                    text: "No ha sido posible realizar el cambio."
                })
            }
        )
    }

    /**
     * Evento encargado de realizar el desbloqueo de una linea
     * @param linea 
     * @param accion 
     */
    async desbloquearLinea(linea, accion) {
        const url = environment.bloqueo
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass")
        });
        const body = {
            // "terceroCliente": this.cookies.get("tercero"),
            "telefono": linea.Telefono,
            "accion": accion,
        }
        this.showLoadModal();
        try {
            this.http.post(url, body, { headers }).subscribe(
                (res: any) => {
                    this.hiddeModal()
                    Swal.fire({
                        icon: 'success',
                        text: res.Est,
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    })
                    this.findNumber(linea.Telefono); //Actualizar listado de lineasBloqueadas
                },
                error => {
                    this.hiddeModal()
                    Swal.fire({
                        icon: "warning",
                        text: "No ha sido posible realizar el cambio."
                    })
                }
            )
        } catch {
            this.hiddeModal();
        }
    }
}