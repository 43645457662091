import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PeticionServiceService } from '../services/peticion-service.service';
import { MatDialog } from '@angular/material/dialog';
import { WrongPassportModalComponent } from '../pages/wrong-passport-modal/wrong-passport-modal.component';

@Component({
  selector: 'app-crear-pedidos-marca-blanca',
  templateUrl: './crear-pedidos-marca-blanca.component.html',
  styleUrls: ['./crear-pedidos-marca-blanca.component.css']
})
export class CrearPedidosMarcaBlancaComponent implements OnInit {
  public carga:boolean=false;
  public textoCarga:string='Cargando ...'
  public whiteBrandForm;
  public servicios:any;
  public loadingServicios:boolean=true;
  public telefonoServicio:boolean=false;
  public televisionServicio:boolean=false;
  public zapiServicio:boolean=false;
  private serviciosSeleccionados:number=0;
  public packSugerido:any='Solo se pueden seleccionar packs dobles o triples';
  public packSeleccionado:any={};
  public resumen:any = [];
  public total:number=0;
  public displayAdicionales:boolean=false;
  public serviciosAdicionales:any;
  private serviciosAdicionalesSeleccionados:any = {};
  public packsSeleccionados:any=[];
  private tipoCliente:string='R';
  private tokenMarcaBlanca:string;
  private cod_contrato:string;
  public serviciosAsociables:any=[];
  private portabilidad:any={};
  public modalPortabilidad:string='';
  nPortabilidad:string="";
  apellido1Portabilidad:string="";
  apellido2Portabilidad:string="";
  documentoPortabilidad:string="";
  numeroPortabilidad:string="";
  private codTercero:any='';
  private tiposTercero:any={
    'R':'residencial',
    'S':'empresarial',
    'A':'empresarial',
  }
  private adicionalesDelPack:any=[];
  dtOptions = {
    lengthMenu:[15,20,30,50,100,'All'],
    // order:[[2,'asc']]
  };  
  tipoDocumento:string='';
  private wrongPassportModalRef:any;
  constructor(
    private route: Router,
    private formBuilder: UntypedFormBuilder,
    private marcaBlanca: PeticionServiceService,
    private dialog:MatDialog,
  ) { 
    if(this.route.getCurrentNavigation().extras.state!=undefined){
      this.tipoCliente=this.route.getCurrentNavigation().extras.state[0];
      this.cod_contrato=this.route.getCurrentNavigation().extras.state[1];
      this.codTercero=this.route.getCurrentNavigation().extras.state[2];
    }else this.route.navigateByUrl('/create-account-white-brand');
  }

  ngOnInit(): void {
    this.whiteBrandForm = this.formBuilder.group({
      'internet':[''],
      'television':[''],
      'telefonoServicio':[''],
      'zapi':[''],
    });
    if(Object.keys(this.tiposTercero).includes(this.tipoCliente)) this.tipoCliente=this.tiposTercero[this.tipoCliente];
    this.getServicios();
  }
  async sugerirPack(){
    const payload={
      'modalidad':this.tipoCliente,
      'internet':this.whiteBrandForm.controls['internet'].value!=''?this.whiteBrandForm.controls['internet'].value:'0',
      'television':this.whiteBrandForm.controls['television'].value!=''?this.whiteBrandForm.controls['television'].value:(this.televisionServicio)?'1':'0',
      'telefono':this.whiteBrandForm.controls['telefonoServicio'].value!=''?this.whiteBrandForm.controls['telefonoServicio'].value:(this.telefonoServicio)?'1':'0',
      'zapi':this.whiteBrandForm.controls['zapi'].value!=''?this.whiteBrandForm.controls['zapi'].value:(this.zapiServicio)?'1':'0',
      'cod_tercero':this.codTercero,
    };
    const headers={
      'Authorization':'Bearer '+this.tokenMarcaBlanca,
    }
    await this.marcaBlanca.peticionMarca(environment.obtenerPacksMarcaBlanca,'post',headers,payload).then((data:any)=>{
      this.packSugerido=Array.isArray(data)?data:[data];
      if(this.packSugerido.length>1){
        this.adicionalesDelPack=this.packSugerido.filter((element:any, key:number)=>{
          return key>0;
        });
        this.packSugerido=[this.packSugerido[0]];
      }else{
        this.adicionalesDelPack=[];
      }
      if(this.packSugerido.length==0){
        this.packSugerido='No existe un pack con esas características';
      }
    },(error:any)=>{
      console.log(error);
        Swal.fire({
          title: 'Error',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: error.error.message ?? error.error.detalle
        });
    });
  }
  async getServicios(){
    const payload={
      'modalidad':this.tipoCliente,
    };
    const headers={
      "accept": "application/json",
      "Authorization": "Bearer "+this.tokenMarcaBlanca,
      'Content-Type': 'application/json' 
    };
    await this.marcaBlanca.peticionMarca(environment.obtenerServiciosMarcaBlanca,'post',headers,payload).
    then((res:any)=>{
      this.servicios=res;
      this.loadingServicios=false;
    },(error:any)=>{
        this.ocultarCarga();
        Swal.fire({
          title: 'Error',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: error.error.message ?? error.error.detalle
        });
    });
  }
  /**
   * Muestra la carga
   */
  mostrarCarga(){
    this.carga=true;
  } 
  /**
   * Oculta la carga
   */
  ocultarCarga(){
    this.carga=false;
  }
  async displayServiciosAdicionales(){
    this.textoCarga='Obteniendo Servicios Adicionales...';
    this.mostrarCarga();
    const headers={
      'Authorization':'Bearer '+this.tokenMarcaBlanca,
    }
    const payload={
      'modalidad':this.tipoCliente,
    };
    await this.marcaBlanca.peticionMarca(environment.obtenerServiciosAdicionalesMarcaBlanca,'post',headers,payload).then((data:any)=>{
      this.serviciosAdicionales=data;
      this.ocultarCarga();
      this.displayAdicionales=true;
      if(this.serviciosAdicionales.length==0){
        this.serviciosAdicionales='No hay servicios adicionales que ofrecer en este momento';
      }
    },(error:any)=>{
      console.error(error);
          this.ocultarCarga();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Confirmar',
            html: error.error.message ?? error.error.detalle
          });
    });
  }
  toggleTelefono(){
    this.telefonoServicio=!this.telefonoServicio;
    if(this.telefonoServicio) this.serviciosSeleccionados++;
    else this.serviciosSeleccionados--;
    this.checkServicios();
  }
  toggleTelevision(){
    this.televisionServicio=!this.televisionServicio;
    if(this.televisionServicio) this.serviciosSeleccionados++;
    else this.serviciosSeleccionados--;
    this.checkServicios();

  }
  toogleZapi(){
    this.zapiServicio=!this.zapiServicio;
    if(this.zapiServicio) this.serviciosSeleccionados++;
    else this.serviciosSeleccionados--;
    this.checkServicios();

  }
  changeInternet(){
    if(this.whiteBrandForm.controls['internet'].value!=''){ 
      this.serviciosSeleccionados++;

      if(!this.checkDefaultValues()) this.serviciosSeleccionados--;
    }else{ 
      this.serviciosSeleccionados--;
    }
    this.checkServicios();
  }
  changeTelefono(){
    if(this.whiteBrandForm.controls['telefonoServicio'].value!=''){ 
      this.serviciosSeleccionados++;

      if(!this.checkDefaultValues()) this.serviciosSeleccionados--;
    }else{
      this.serviciosSeleccionados--;
    }
    this.checkServicios();
  }
  changeTelevision(){
    if(this.whiteBrandForm.controls['television'].value!=''){ 
      this.serviciosSeleccionados++;

      if(!this.checkDefaultValues()) this.serviciosSeleccionados--;
    }else{
      this.serviciosSeleccionados--;
    }
    this.checkServicios();
  }
  changeZapi(){
    if(this.whiteBrandForm.controls['zapi'].value!=''){ 
      this.serviciosSeleccionados++;

      if(!this.checkDefaultValues()) this.serviciosSeleccionados--;
    }else{
      this.serviciosSeleccionados--;
    }
    this.checkServicios();
  }
  checkServicios(){
    if(this.tipoCliente == ''){
      this.packSugerido='Selecciona el tipo de cliente';
    } 
    else if(this.serviciosSeleccionados >=2){
      this.sugerirPack();
    }else{
      this.packSugerido='Solo se pueden seleccionar packs dobles o triples';
    }
  }
  checkDefaultValues(){
    var notDefaultValue=0;
    if(this.whiteBrandForm.controls['internet'].value!=''){
      notDefaultValue++;
    }
    if(this.whiteBrandForm.controls['television'].value!='' || this.televisionServicio){
      notDefaultValue++;
    }
    if(this.whiteBrandForm.controls['telefonoServicio'].value!='' || this.telefonoServicio){
      notDefaultValue++;
    }
    if(this.whiteBrandForm.controls['zapi'].value!='' || this.zapiServicio){
      notDefaultValue++;
    }
    return this.serviciosSeleccionados == notDefaultValue;
  }
  seleccionarServicio(servicio:any){
    if(servicio.adicional_fijo!=1 && servicio.adicional_movil!=1){
      this.addToResumen(servicio);
      let seleccionable = this.serviciosAsociables.find(seleccion => seleccion.id == servicio.id);
      if(!seleccionable){
        this.serviciosAsociables.push(servicio);
      }
    }else{
      if(!this.resumen.includes(servicio)){
        Swal.fire({
          title:'Error',
          text:'Este servicio require que selecciones el pack al que va asociado',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
        });
      }
    }
  }
  quitarDisplayServiciosAdicionales(){
      this.displayAdicionales=false;
  }
  asociar(asociado:any,event:any){
    let servicio=event.target.value;
    for (let key in this.serviciosAdicionalesSeleccionados) {
      if(this.serviciosAdicionalesSeleccionados[key].includes(asociado.id)){
        this.serviciosAdicionalesSeleccionados[key]=this.serviciosAdicionalesSeleccionados[key].filter(x=>x != asociado.id);
      }
    }
    this.eliminarDelResumen(asociado);
    if(servicio!=''){
      let serviciosAsociados=Object.keys(this.serviciosAdicionalesSeleccionados);
      if(!serviciosAsociados.includes(servicio)){
        this.serviciosAdicionalesSeleccionados[servicio]=[];
      }
      this.serviciosAdicionalesSeleccionados[servicio].push(asociado.id);
      this.addToResumen(asociado);
    }
  }
  seleccionarPack(pack:any){
    let seleccionado = this.packsSeleccionados.find(seleccion => seleccion.id == pack.id);
    let seleccionable = this.serviciosAsociables.find(seleccion => seleccion.id == pack.id);
    if(this.packsSeleccionados.length<1){
        this.addToResumen(pack);
        if(this.adicionalesDelPack.length!=0){
          this.adicionalesDelPack.forEach((element:any) => {
            this.addToResumen(element);
          });
        }
        if(!seleccionado && !seleccionable){
          this.packsSeleccionados.push(pack);
          this.serviciosAsociables.push(pack);
        }
    }else{
      Swal.fire({
        title:'Error',
        text:'Solo se puede seleccionar un pack',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    }
    if(seleccionado){
      Swal.fire({
        title:'Error',
        text:'Pack ya seleccionado',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    }
  }
  addToResumen(pack:any){
    let seleccionado = this.resumen.find(seleccion => seleccion.id == pack.id);
      if(!seleccionado){
        this.resumen.push(pack);
        this.total+=pack.precio;
        this.total=parseFloat((Math.round(this.total * 100) / 100).toFixed(2));
      }
  }
  eliminarPack(pack:any){
    this.eliminarServiciosAsociados(pack);
    this.eliminarDelResumen(pack);
    if(this.adicionalesDelPack.length!=0){
      this.adicionalesDelPack.forEach((element:any) => {
        this.eliminarDelResumen(element);
      });
    }
    this.packsSeleccionados=this.packsSeleccionados.filter(seleccion => seleccion.id != pack.id);
    this.serviciosAsociables=this.serviciosAsociables.filter(seleccion => seleccion.id != pack.id);
  }
  eliminarServiciosAsociados(pack:any){
    if(Object.keys(this.serviciosAdicionalesSeleccionados).includes(""+pack.id)){
      let eliminar= this.serviciosAdicionalesSeleccionados[""+pack.id];
      if(eliminar.length!=0){
        eliminar.forEach(element => {
          let asociado=this.resumen.find(seleccion => seleccion.id == element);
          this.eliminarDelResumen(asociado);
        });
        this.serviciosAdicionalesSeleccionados[""+pack.id]=[];
      }
    }else{
      for(let key in this.serviciosAdicionalesSeleccionados){
        this.serviciosAdicionalesSeleccionados[key]=this.serviciosAdicionalesSeleccionados[key].filter(seleccion => seleccion != pack.id);
      }
    }
    
  }
  eliminarDelResumen(servicio:any){
    let seleccionado = this.resumen.find(seleccion => seleccion.id == servicio.id);
    if(seleccionado){
      this.resumen=this.resumen.filter(seleccion => seleccion.id != servicio.id);
      this.total-=servicio.precio;
      this.total=parseFloat((Math.round(this.total * 100) / 100).toFixed(2));
    }
  }
  async subirPedidos(){
    this.textoCarga='Subiendo pedidos...';
    this.mostrarCarga();
    let servicios = this.montarServiciosParaSubir();
    const payload={
      'servicios':servicios,
      'cod_contrato':this.cod_contrato,
    }
    const headers={
      'Authorization':'Bearer '+this.tokenMarcaBlanca,
    }
    await this.marcaBlanca.peticionMarca(environment.setServiciosMarcaBlanca,'post',headers,payload).then(
      (data:any)=>{      
      this.ocultarCarga();
      if(data[1]){
        Swal.fire({
          title:'El pedido se ha realizado con éxito',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
      }).then(()=>{
        this.route.navigateByUrl('/create-subcription-white-brand');
      });
    }
    })
    .catch( (error:any) => {
      this.ocultarCarga();
      Swal.fire({
        title: 'Error',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
        html: error.error.message ?? error.error.detalle
      });
    });
  }
  private montarServiciosParaSubir(){
    let servicios=[];
    let serviciosAsociadosAOtro=[];
    for (let key in this.serviciosAdicionalesSeleccionados) {
      serviciosAsociadosAOtro=serviciosAsociadosAOtro.concat(this.serviciosAdicionalesSeleccionados[key]);
    }
    this.resumen.forEach(element => {
      if(!serviciosAsociadosAOtro.includes(element.id)){
        let asociados = this.serviciosAdicionalesSeleccionados[element.id.toString()];
        let servicio={
          "servicio":element.id,
          "adicionales":( asociados && asociados.length!=0)?asociados:[],
        };
        if(this.checkPortabilidad(element.id)){
          servicio["portabilidad_nombre"]=this.portabilidad[""+element.id]["portabilidad_nombre"];
          servicio["portabilidad_primer_apellido"]=this.portabilidad[""+element.id]["portabilidad_primer_apellido"];
          servicio["portabilidad_segundo_apellido"]=this.portabilidad[""+element.id]["portabilidad_segundo_apellido"];
          servicio["portabilidad_documento"]=this.portabilidad[""+element.id]["portabilidad_documento"];
          servicio["portabilidad_numero"]=this.portabilidad[""+element.id]["portabilidad_numero"];
        }
        
        servicios.push(servicio);
      }
      
    });
    return servicios;
  }
  checkPortabilidad(servicio:number){
    return Object.keys(this.portabilidad).includes(""+servicio) &&this.portabilidad[""+servicio]!=null && this.portabilidad[""+servicio].length!=0;
  }
  quitarPortabilidad(servicio:any){
    this.portabilidad[""+servicio]=null;
    this.quitarDisplayAddPortabilidad();
  }
  displayAddPortabilidad(servicio:any){
    this.modalPortabilidad=""+servicio;
    this.tipoDocumento='';
  }
  quitarDisplayAddPortabilidad(){  
    this.modalPortabilidad="";
    this.nPortabilidad="";
    this.apellido1Portabilidad="";
    this.apellido2Portabilidad="";
    this.documentoPortabilidad="";
    this.numeroPortabilidad="";
  }
  crearPortabilidad(){
    let telf=Number(this.numeroPortabilidad);
    if(telf<800000000 || telf>999999999){
      Swal.fire({
        title:'Fijo no válido',
        text:'El teléfono introducido no es un teléfono fijo',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
        icon: 'error',
      })
    }else{
      if( this.comprobarDoc()){
        this.portabilidad[this.modalPortabilidad]={
          "portabilidad_nombre":this.nPortabilidad,
          "portabilidad_primer_apellido":this.apellido1Portabilidad,
          "portabilidad_segundo_apellido":this.apellido2Portabilidad,
          "portabilidad_documento":this.documentoPortabilidad,
          "portabilidad_numero":this.numeroPortabilidad,
        };  
        this.quitarDisplayAddPortabilidad();
      }
    }
  }
  editarPortabilidad(servicio:any){
    let portabilidad= this.portabilidad[""+servicio];
    this.modalPortabilidad=""+servicio;
    this.nPortabilidad=portabilidad.portabilidad_nombre;
    this.apellido1Portabilidad=portabilidad.portabilidad_primer_apellido;
    this.apellido2Portabilidad=portabilidad.portabilidad_segundo_apellido;
    this.documentoPortabilidad=portabilidad.portabilidad_documento;
    this.numeroPortabilidad=portabilidad.portabilidad_numero;
  }
  comprobarDoc(){
    var valido: boolean=false;
    switch (this.tipoDocumento) {
      case "N":
        valido=this.isNif(this.documentoPortabilidad);
       if(!valido){
        Swal.fire({
          title:'Error',
          text:'Documento no válido',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          icon: 'error',
        });
       }
      break;
      case "NIE":
        valido=this.isNie(this.documentoPortabilidad);
        if(!valido){
          Swal.fire({
            title:'Error',
            text:'Documento no válido',
            showCancelButton: false,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Confirmar',
            icon: 'error',
          })
        }
      break;
      case "P":
        let pasaporte=this.documentoPortabilidad;
        let isCif=this.validaCif(pasaporte);
        let isNie=this.isNie(pasaporte);
        let isNif=this.isNif(pasaporte);
          if(isCif){
            this.wrongPassportModalRef=this.dialog.open(WrongPassportModalComponent,{
              height: '400px',
              width: '600px',
              data:{
                tipo:'CIF',
                completar:'cambiar y añadir portabilidad'
              }
            });
            this.wrongPassportModalRef.afterClosed().subscribe({
              next:(change:any)=>{
                if(change.change){
                  this.tipoDocumento='C';
                  this.crearPortabilidad();
                }
              }
            });
          }
          if(isNie){
            this.wrongPassportModalRef=this.dialog.open(WrongPassportModalComponent,{
              height: '400px',
              width: '600px',
              data:{
                tipo:'NIE',
                completar:'cambiar y añadir portabilidad'
              }
            });
            this.wrongPassportModalRef.afterClosed().subscribe({
              next:(change:any)=>{
                if(change.change){
                  this.tipoDocumento='NIE';
                  this.crearPortabilidad();
                }
              }
            });
          }
          if(isNif){
            this.wrongPassportModalRef=this.dialog.open(WrongPassportModalComponent,{
              height: '250px',
              width: '600px',
              data:{
                tipo:'NIF',
                completar:'cambiar y añadir portabilidad'
              }
            });
            this.wrongPassportModalRef.afterClosed().subscribe({
              next:(change:any)=>{
                if(change.change){
                  this.tipoDocumento='N';
                  this.crearPortabilidad();
                }
              }
            });
          }
          valido=!isCif && !isNie && !isNif;
        break;
      case "C":
          valido=this.validaCif(this.documentoPortabilidad);
          if(!valido){
            Swal.fire({
              title:'Error',
              text:'Documento no válido',
              showCancelButton: false,
              confirmButtonColor: '#00b1aa',
              confirmButtonText: 'Confirmar',
              icon: 'error',
            });
          }
        break;
    }
    return valido;
  }
  
  isNif(documento) {
    var nif = documento.toUpperCase();
    var lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];

    if (nif.length == 9) {
      var letter = nif.substring(8, 9);
      var number = parseInt(nif.substring(0, 8));
      if (!isNaN(number) && isNaN(parseInt(letter))) {
          if (lettersEnd[number % 23] === letter) {
              return true;
          }
      }
    }
    return false;
  }
  isNie(documento) {
      var nie = documento.toUpperCase(); //Z4138590K

      var i;
      var num = [];
      if (nie.match('^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]')) {
          for (i = 0; i < 9; i++) {
              var aux = i;
              num[i] = nie.substr(i, aux++);
          }
          var letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
          var dniTransform = nie.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
          if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
              return true;
          }
      }
      return false;
  }
  /**
   * Validador de cif
   * @param documento
   * @returns
   */
  validaCif(documento) {
      if (documento == "") {return false; }
      var texto = documento;
      var pares = 0;
      var impares = 0;
      var suma;
      var ultima;
      var unumero;
      var uletra = new Array("J", "A", "B", "C", "D", "E", "F", "G", "H", "I");
      var xxx;

      texto = texto.toUpperCase();

      var regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g);
      if (!regular.exec(texto)) {}

      ultima = texto.substr(8, 1);

      for (var cont = 1; cont < 7; cont++) {
          xxx = (2 * parseInt(texto.substr(cont++, 1))).toString() + "0";
          impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
          pares += parseInt(texto.substr(cont, 1));
      }
      xxx = (2 * parseInt(texto.substr(cont, 1))).toString() + "0";
      impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

      suma = (pares + impares).toString();
      unumero = parseInt(suma.substr(suma.length - 1, 1));
      unumero = (10 - unumero).toString();
      if (unumero == 10) unumero = 0;

      if (ultima == unumero || ultima == uletra[unumero]) {
          return true
      } else {
          return false;
      }
  }
}
