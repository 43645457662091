import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-shared-group-page',
    templateUrl: './add-shared-group-page.component.html',
    styleUrls: ['./add-shared-group-page.component.css']
})
export class AddSharedGroupPageComponent implements OnInit {
    load: boolean = false;
    contactoOficial;
    con: any;
    data2;
    list = null;
    listPetCab = null;
    listPetCabAux = null;
    listLineasInclude = null;
    peticionCabecera;
    lineasCliente = null;
    lineasAppendGroup = [];
    activarBotonAdd = false;
    lineasAppendGroupPeticion = [];
    contrato;
    lineasExistentesEnOtrosGrupos: any;
    tarifaSelected = null;
    @ViewChild('inputDni') inputDni: ElementRef;
    constructor(
        private cookies: CookieService,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.getContratos();
    }

    /**
     * devuelve el listado de contratos
     */
    getContratos() {
        this.lineasCliente = null;
        this.listLineasInclude = null;
        this.list = null;
        this.listPetCab = null;
        this.listPetCabAux = null;
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            this.showloading();
            const url = environment.getContratosMovil;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                } else {
                    this.data2 = [];
                    this.data2.push(res);
                }
                this.hideloading();
            }, error => {
                this.hideloading();
            });
        } catch {
            this.hideloading();
        }
    }
    /**
     * Retorna un listado de lineas bajo un contrato seleccionado
     * @param $event 
     */
    getlineas($event) {
        const splittedValues = $event.split('|');
        const evento = splittedValues[0];
        this.con = splittedValues[1];

        /* this.ter = t;
        this.con = c; */
        this.lineasCliente = null;
        this.listLineasInclude = null;
        this.list = null;
        this.listPetCab = null;
        this.listPetCabAux = null;
        this.contactoOficial = evento;
        this.getGroupList(evento);
    }

    /**
     * Retorna un listado de peticiones de cabecera 
     * @param $event 
     */
    getPeticionCabecera($event) {
        this.lineasCliente = null;
        this.listLineasInclude = null;
        this.peticionCabecera = $event.split("|")[0];
        this.tarifaSelected = $event.split("|")[1];
        this.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        let body = {
            "contrato": this.contactoOficial
        }

        const url = environment.getListSharedGroup;

        this.listLineasInclude = [];
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    res.forEach((item) => {
                        if (item.PETICION == this.peticionCabecera) {
                            this.listLineasInclude.push({
                                peticionCabecera: item.PETICION,
                                tarifa: item.TARIFA,
                                des: String(item.TITULAR).trim(),
                                telf: item.NUMERO
                            });
                        }
                    })
                }
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Busca y retorna las lineas moviles que posee un cliente
     * @param dni 
     */
    buscarLineas() {
        this.showloading();
        let dni = this.inputDni.nativeElement.value;
        this.lineasCliente = null;
        // if (dni.length == 9) {
        let codigoTercero = this.cookies.get("tercero");
        const body = {
            "dniCuarto": dni.toUpperCase(),
            "tercero": codigoTercero,
            "contratoProvisional": this.con
        }
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const url = environment.checkMobileLines;
        this.lineasCliente = null;

        this.http.post(url, body, { headers }).subscribe((res: any) => {
            this.hideloading();
            if (!res.length) { //______NO POSEE LINEAS
                Swal.fire({
                    title: "INFO BÚSQUEDA",
                    icon: "warning",
                    text: "El cliente introducido no tiene líneas asociadas."
                });
                return;
            } else { //Hay lineas
                this.lineasCliente = [];
                if (res.length == 1) { //Si solo hay una linea
                    this.lineasCliente.push(res[0]);
                } else {
                    res.forEach(element => {
                        this.lineasCliente.push(element);
                    });
                }
                if (!this.lineasCliente.length) {
                    this.lineasCliente = null;
                    Swal.fire({
                        title: "INFO BÚSQUEDA",
                        icon: "warning",
                        text: "El cliente introducido no tiene líneas asociadas."
                    });
                }
            }
        })
    }

    /**
     * Retorna el listado de grupos compartidos filtrado mpor contrato
     * @param contrato 
     */
    getGroupList(contrato: String) {
        this.lineasCliente = null;
        this.listLineasInclude = null;
        this.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        let body = {
            "contrato": contrato,
        }
        this.lineasExistentesEnOtrosGrupos = null;
        const url = environment.getListSharedGroup;

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    this.list = [];
                    this.listPetCab = [];
                    this.listPetCabAux = [];
                    this.lineasExistentesEnOtrosGrupos = [];
                    this.list = res;
                    this.hideloading();
                    this.list.forEach((item) => {
                        //pushes only unique element 
                        this.lineasExistentesEnOtrosGrupos.push(item.NUMERO); //------>AÑADIDO DE LINEAS A EXCLUIR
                        if (!this.listPetCabAux.includes(item.PETICION)) {
                            this.listPetCab.push({
                                peticionCabecera: item.PETICION,
                                tarifa: item.TARIFA,
                                titular: String(item.TITULAR).trim()
                            });
                            this.listPetCabAux.push(item.PETICION);
                        }
                    })
                }
                this.hideloading();
            })
        } catch {
            this.list = null;
            this.listPetCab = null;
            this.listPetCabAux = null;
            this.hideloading();
        }
    }

    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }

    /**
     * Añade lineas al grupo
     * @param telefono 
     * @param titular 
     */
    appendGroup(linea) {
        this.activarBotonAdd = true;
        if (this.lineasAppendGroup == null) { this.lineasAppendGroup = [] }
        if (this.lineasAppendGroupPeticion == null) { this.lineasAppendGroup = [] }
        var tamano: number = this.listLineasInclude.length + this.lineasAppendGroup.length;
        if (tamano < 3) {
            //Elimino de la lista los elementos que quiero añadir
            this.lineasCliente = this.lineasCliente.filter(item => item.telf != linea.telf);
            this.lineasAppendGroup.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo, peticionCabecera: this.peticionCabecera, tarifa: linea.tarifa });
            this.lineasAppendGroupPeticion.push({ linea: linea.telf, peticion: linea.pet, peticionCabecera: this.peticionCabecera, des: linea.des, tarifa: linea.tarifa });
        } else { //_______________________HA EXCEDIDO EL MAXIMO DE LINEAS
            Swal.fire({
                title: "ESTADO DEL GRUPO",
                icon: "warning",
                text: "No es posible añadir más líneas al grupo. El límite es de 3 líneas."
            });
        }
    }

    /**
     * Quita lineas que no deseamos añadir al grupo
     * @param linea 
     */
    removeLinesGroup(linea) {
        this.lineasAppendGroup = this.lineasAppendGroup.filter(item => item.telf != linea.telf);
        this.lineasAppendGroupPeticion = this.lineasAppendGroupPeticion.filter(item => item.linea != linea.telf);
        this.lineasCliente.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo });
    }

    /**Lanza la peticion del grupo de lineas 
     */
    launchAddLinesGroup() {
        if (this.lineasAppendGroupPeticion.length == 0) {
            Swal.fire({
                title: "ERROR AL FINALIZAR",
                icon: "error",
                text: "El campo 'LÍNEAS A AÑADIR' no puede estar vacío.",
            });
            return;
        }

        const body = {
            "contratoOficial": this.contactoOficial,
            "lineas": this.lineasAppendGroupPeticion,
            "tarifa": this.tarifaSelected.trim(),
            "peticionCabecera": this.peticionCabecera,
        };

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.addLineGroup;
        this.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (this.checkAddLines(res)) { //Se creó el grupo correctamente
                    Swal.fire({
                        title: "INFO GRUPO",
                        icon: "success",
                        text: "Las líneas seleccionadas han sido añadidas correctamente.",
                    });
                    this.lineasAppendGroupPeticion = null; //refresco la lineas añadidas
                    this.refreshTable(); //refrecar tabla al realizar la peticion
                } else { //No se pudo crear el grupo
                    Swal.fire({
                        title: "INFO GRUPO",
                        icon: "warning",
                        text: "Error:" + res[0].error,
                        // text: "Las lineas seleccionadas no se pueden añadir al grupo",
                    });
                }
                this.hideloading();
            }, error => {
                Swal.fire({
                    title: "INFO GRUPO",
                    icon: "warning",
                    text: "Las líneas seleccionadas no se pueden añadir al grupo.",
                })
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Refrescar la tabla
     * 
     */
    refreshTable() {
        this.listLineasInclude = [];

        this.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        let body = {
            "contrato": this.contactoOficial
        }

        const url = environment.getListSharedGroup;

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    res.forEach((item) => {
                        if (item.PETICION == this.peticionCabecera) {
                            this.listLineasInclude.push({
                                peticionCabecera: item.PETICION,
                                tarifa: item.TARIFA,
                                des: String(item.TITULAR).trim(),
                                telf: item.NUMERO
                            });
                        }
                    })
                    this.lineasAppendGroup = null; //reset lineas añadidas
                }
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Comprobacion de las respuestas del grupo
     * @param array 
     * @returns 
     */
    checkAddLines(array): Boolean {
        // var count = 0
        let flag = false;
        let length = array.length;
        // || array[length - 1].peticion != null || array[length - 1] == ""
        if (array[length - 1].peticion == "Grupo creado correctamente." || array[length - 1] == "") {
            flag = true;
        }
        return flag; //No hubo problemas al añadir las lineas al grupo
    }
}