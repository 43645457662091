<div *ngIf="load" id="loading">
    <mat-grid-list cols=3 rowHeight=40em>
        <mat-grid-tile>

        </mat-grid-tile>
        <mat-grid-tile>
            <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
        </mat-grid-tile>
        <mat-grid-tile>

        </mat-grid-tile>
    </mat-grid-list>

</div>
<div class="formbox">
    <div class="login">
        <form class="custom-form" method="post" [formGroup]="loginForm">
            <p style="text-align: center;">
                <img src="assets/img/NuevoD/logoPTVOP.svg" class="logo">
            </p>
            <p style="text-align: center;">
                <img src="assets/img/NuevoD/iconoUblanco.svg" class="iconoUB">
            </p>

            <div class="input-container">
                <i class="icon"><img src="assets/img/NuevoD/iconoUsuario.svg" class="iconoUI"></i>
                <input type="text" name="usuario" placeholder="Usuario" required="required"
                    class="input-field" formControlName="usuario" autoComplete="username" />
            </div>

            <div class="input-container">
                <i class="icon"><img src="assets/img/NuevoD/iconoContrasena.svg" class="iconoUI"></i>
                <input type="password" name="clave" placeholder="Clave" required="required"
                    class="input-field" formControlName="clave" autoComplete="current-password" />
            </div>

            <p class="textRC">
                <!-- <u (click)="forgotPassw()">Recuperar contraseña</u> -->
            </p>
            <button type="submit" (click)="login()">iniciar sesión</button>
        </form>
    </div>
</div>