import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-list-shared-group',
    templateUrl: './list-shared-group.component.html',
    styleUrls: ['./list-shared-group.component.css']
})
export class ListSharedGroupComponent implements OnInit, OnDestroy, AfterViewInit {
    @Component({
        selector: 'app-down-shared-group-page',
        templateUrl: './down-shared-group-page.component.html',
        styleUrls: ['./down-shared-group-page.component.css']
    })

    data;
    data2;
    linea;
    aux;
    contratoOficial;
    contactoProvisional;
    tercero;
    load;
    tarifaFamiliarSelected;

    list: any;
    listPetCab = null;
    listPetCabAux = null;
    tarifasUpdate = null;

    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Blfrtip',
        search: true,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        paging: true,
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    lineasCliente;
    lineasAppendGroup = [];
    lineasAppendGroupPeticion = [];
    constructor(
        private cookies: CookieService,
        private http: HttpClient
    ) {
        var dataTable = $("#dataTableListBajas").dataTable();
        dataTable.DataTable().destroy;
    }

    ngAfterViewInit(): void {
        var dataTable = $("#dataTableListBajas").dataTable();
        dataTable.DataTable().destroy; //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }
    ngOnInit(): void {
        this.lineasCliente = null;
        this.getContratos();
        this.lineasAppendGroup = null;
        var dataTable = $("#dataTableListBajas").dataTable();
        dataTable.DataTable().destroy;
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
     * Evento de selección de contratos
     * @param $event 
     */
    changeContratos($event) {
        this.list = null;
        this.contratoOficial = $event.split("|")[0];
        this.contactoProvisional = $event.split("|")[1];
        this.getGroupList(this.contratoOficial);
    }

    /**
     * devuelve el listado de contratos
     */
    getContratos() {
        this.showloading();
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            const url = environment.getContratosMovil;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                } else {
                    this.data2 = [];
                    this.data2.push(res);
                }
                this.hideloading();
            }, error => {
            });
        } catch {
            this.hideloading();
        }
    }

    /**
     * Retorna el listado del Grupo compartido
     * @param contrato 
     */
    getGroupList(contrato: String) {
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        let body = {
            "contrato": contrato
        }

        const url = environment.getListSharedGroup;

        this.list = null;
        this.listPetCab = null;
        this.listPetCabAux = null;

        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length) {
                    this.list = [];
                    this.listPetCab = [];
                    this.listPetCabAux = [];
                    this.getTarifas();
                    this.hideloading();
                    res.forEach(item => {
                        if (!this.listPetCabAux.includes(item.PETICION)) {
                            this.listPetCab.push({
                                peticionCabecera: item.PETICION,
                                tarifa: item.TARIFA,
                                titular: String(item.TITULAR).trim(),
                            });
                            this.listPetCabAux.push(item.PETICION);
                            
                        }
                    })

                    this.listPetCab.forEach(element => {
                        let listContratos = [];
                        let listTarifa = [];
                        let listTitular = [];
                        let listNumeros = [];
                        let cabecera
                        res.forEach(item => {
                            if (element.peticionCabecera == item.PETICION) {
                                listContratos.push(item.contratoOficial);
                                listTarifa.push(item.TARIFA.trim());
                                listTitular.push(item.TITULAR.trim());
                                listNumeros.push(item.NUMERO);
                                cabecera=item.CABECERA
                                
                            }
                        });
                        
                        this.list.push({
                            CONTRATO: listContratos,
                            PETICION: element.peticionCabecera,
                            NUMERO: listNumeros,
                            PET_HIJA: null,
                            TARIFA: listTarifa,
                            TITULAR: listTitular,
                            CAMBIOTARIFA: null,
                            CABECERA:cabecera
                            
                        });
                        res.forEach(item => {
                            if (element.peticionCabecera == item.PETICION) {
                                this.list.push({
                                    CONTRATO: item.CONTRATO,
                                    PETICION: item.PETICION,
                                    CABECERA:item.CABECERA,
                                    NUMERO: item.NUMERO,
                                    PET_HIJA: item.PET_HIJA,
                                    TARIFA: item.TARIFA.trim(),
                                    TITULAR: item.TITULAR.trim(),
                                    CAMBIOTARIFA: null,
                                });
                            }
                        });
                    });
                    this.updateDataTable();
                    $('#dataTableListBajas').DataTable().ajax.reload();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'No existen grupos compartidos en el contrato seleccionado.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                }
                this.hideloading();
            }, error => {
                this.hideloading();
            })
        } catch {
            this.updateDataTable();
            this.list = null;
            this.listPetCab = null;
            this.listPetCabAux = null;
            this.hideloading();
        }
    }

    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }

    customRow(linea) {
        if (!linea.CONTRATO) {
            return "";
        }
        return linea.PETICION;
    }

    /**
     * Evento de seleccion de la tarifa
     * @param $event 
     */

    selectTarifa($event) {
        this.tarifaFamiliarSelected = $event.split("@")[0].trim().replace(/\s/g, "");
    }

    /**
     * Añade lineas al grupo
     * @param telefono 
     * @param titular 
     */
    appendGroup(linea) {
        if (this.lineasAppendGroup == null) { this.lineasAppendGroup = [] }
        if (this.lineasAppendGroupPeticion == null) { this.lineasAppendGroup = [] }
        if (this.lineasAppendGroup.length < 3) {
            //Elimino de la lista los elementos que quiero añadir
            this.lineasCliente = this.lineasCliente.filter(item => item.telf != linea.telf);
            this.lineasAppendGroup.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo });
            this.lineasAppendGroupPeticion.push({ linea: linea.telf, peticion: linea.pet, peticionCabecera: "0" });
        } else { //_______________________HA EXCEDIDO EL MAXIMO DE LINEAS
            Swal.fire({
                title: "ESTADO DEL GRUPO",
                icon: "warning",
                text: "No es posible añadir más líneas al grupo. El límite es de 3 líneas."
            });
        }
    }

    /**
     * Quita lineas que no deseamos añadir al grupo
     * @param linea 
     */
    removeLinesGroup(linea) {
        this.lineasAppendGroup = this.lineasAppendGroup.filter(item => item.telf != linea.telf);
        this.lineasAppendGroupPeticion = this.lineasAppendGroupPeticion.filter(item => item.linea != linea.telf);
        this.lineasCliente.push({ telf: linea.telf, des: linea.des, pet: linea.pet, cuarto: linea.cuarto, tipo: linea.tipo });
    }


    /**Lanza el grupo de lineas 
     */
    launchCreateGroup() {
        // let bodyLineas = [];
        const body = {
            "contratoOficial": this.contratoOficial,
            "lineas": this.lineasAppendGroupPeticion,
            "tarifa": this.tarifaFamiliarSelected,
        }
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.startGroupLines;
        this.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (this.checkAddLines(res)) { //Se creó el grupo correctamente
                    Swal.fire({
                        title: "INFO GRUPO",
                        icon: "success",
                        text: "Líneas añadidas correctamente.",
                    });
                } else { //No se pudo crear el grupo
                    Swal.fire({
                        title: "INFO GRUPO",
                        icon: "warning",
                        text: "Las líneas seleccionadas no se pueden añadir al grupo.",
                    });
                }
                this.hideloading();
            }, error => {
                Swal.fire({
                    title: "INFO GRUPO",
                    icon: "warning",
                    text: "Las lineas seleccionadas no se pueden añadir al grupo.",
                });
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Comprobador de añadido de lineas
     * @param array 
     * @returns 
     */
    checkAddLines(array): Boolean {
        // var count = 0
        let flag = false;
        let length = array.length;
        if (array[length - 1] == "Grupo creado correctamente.") {
            flag = true;
        }
        return flag; //No hubo problemas al añadir las lineas al grupo
    }

    /**
     * Actualización del dataTable
     */
    updateDataTable() {
        let dataTable = $("#dataTableListBajas").dataTable();
        dataTable.DataTable().destroy;
        $('#dataTableListBajas').DataTable({
            retrieve: true,
            search: true,
            paging: true,
            pageLength: 30
        });
        this.dtTrigger.next();
        // $('#dataTableListBajas').DataTable().ajax.reload();
    }

    /**
     * Retorna Listado de tarifas 
     */
    getTarifas() {
        const body = {
            "contratoP": this.contactoProvisional,
            "tercero": this.cookies.get("tercero")
        }

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.getTarifaMobile;
        this.tarifasUpdate = [];
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading();
                this.tarifasUpdate = res;
            }, (error: any) => {
            })
        } catch {
            this.hideloading();
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'No existen grupos compartidos en el contrato seleccionado.',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            });
        }
    }

    /**
     * Evento encargado de localizar la tarifa y la peticionhija de la linea seleccionada
     * @param $event 
     */
    changeTarifa($event) {
        let tarifa = $event.split("|")[0];
        let petHija = $event.split("|")[1];
        this.list.forEach(element => {
            if (element.PET_HIJA == petHija) {
                element.CAMBIOTARIFA = tarifa;
            }
        });
    }

    /**
     *  Evento encargada para el cambio de tarifa
     * @param telefono 
     */
    launchUpdateTarifa(telefono: String) {
        let peticion = "";
        let tarifa = "";

        this.list.forEach(element => {
            if (element.NUMERO == telefono) {
                peticion = element.PETICION_HIJA;
                tarifa = element.CAMBIOTARIFA;
            }
        });
        const body = {
            "Tarifa": tarifa,
            "codTercero": this.cookies.get("tercero"),
            "peticion": peticion
        }

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.updateTarifa;

        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                // this.tarifasUpdate = res;
                if (res) {
                    if (res.id = "OK") {
                        Swal.fire({
                            title: "CAMBIO DE TARIFA",
                            icon: "success",
                            text: res.des.trim().toUpperCase()
                        });
                    }
                }
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * 
     * @param peticionCabecera 
     * @returns 
     */
    rowColor(peticionCabecera: String) {
        let color = "";
        this.listPetCab.forEach(element => {
            if (element.peticionCabecera == peticionCabecera) {
                color = element.color;
            }
        });
        return color;
    }

    // randomColor(): String {
    //     var letters = '0123456789ABCDEF';
    //     var color = '#';
    //     for (var i = 0; i < 6; i++) {
    //         color += letters[Math.floor(Math.random() * 16)];
    //     }
    //     return color;
    // }
}
