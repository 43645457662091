<div class="formbox">
    <div class="wrapper_load" *ngIf="load">
        <img class="customLoad" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        LISTA DE ALBARANES
    </div>

    <div class="divTabla">
        <div class="container " style="width: 60%; zoom:90%;">
            <div style="zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover " datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead style="font-family: MontserratBold; text-transform: uppercase; " *ngIf="!load">
                        <tr>
                            <th>Albaranes</th>
                            <th>Descargar</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: start;">
                        <tr *ngFor="let ele of listado;">
                            <td style="width: 35% !important; ">{{ele}}</td>
                            <td >
                                <button class="guardarBtn btnCustom" (click)="descargarAlbaran(ele)">DESCARGAR</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>