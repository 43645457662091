import { Component, OnInit, ViewChild } from '@angular/core';
import { SuscriptoresService } from 'src/app/services/suscriptores.service';
import { CookieService } from 'ngx-cookie-service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import { AsignaTecnicosService } from 'src/app/services/asigna-tecnicos.service';
import { MatDialog } from '@angular/material/dialog';
import { IncidenciasListComponent } from '../incidencias-list/incidencias-list.component';
import { ListaServiciosActivosComponent } from '../lista-servicios-activos/lista-servicios-activos.component';
import moment from 'moment';
import Swal from 'sweetalert2';
import { DiagnosticoOntModalComponent } from '../diagnostico-ont-modal/diagnostico-ont-modal.component';
import { CompruebaCoberturaComponent } from '../comprueba-cobertura/comprueba-cobertura.component';
import { ListaDocumentosComponent } from '../lista-documentos/lista-documentos.component';
import { SubirDocumentacionComponent } from 'src/app/modals/subir-documentacion/subir-documentacion.component';

@Component({
  selector: 'app-lista-suscriptores-marca-blanca',
  templateUrl: './lista-suscriptores-marca-blanca.component.html',
  styleUrls: ['./lista-suscriptores-marca-blanca.component.css']
})

export class ListaSuscriptoresMarcaBlancaComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;  
  dtOptions = {
    dom: 'Bflrtip',
    retrieve: true,
    pagingType: 'simple_numbers',
    language: {
      url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
    }
  };
  listado:any=null
  load:boolean=false
  incidenciasModelRef:any;
  serviciosModelRef:any;
  diagnosticoOntModelRef:any;
  cambioDomicilioModelRef:any;
  listaDocumentos:any; 
  dtTrigger: Subject<void> = new Subject<void>();

  constructor(public suscriptorSvc:SuscriptoresService,
    private cookie: CookieService,
    private funcionesSvc:PeticionServiceService,
    private asignaTec:AsignaTecnicosService,
    private dialog:MatDialog,) {}

  ngOnInit(): void {            
    this.getLista();    
  }

  async getLista(){
    this.loadSpiner();
    const headers = new HttpHeaders({
      'token': this.cookie.get("token"),
    });
    let url=environment.listarContratos
    let param={
      "cod_tercero":this.cookie.get("tercero")
    }
    try {
      const data = await this.funcionesSvc.peticionMarca(url, "post", {}, param);
      this.listado = data;
      if (this.datatableElement && this.datatableElement.dtInstance) {
        const dtInstance = await this.datatableElement.dtInstance;
        dtInstance.destroy();        
      }         
      this.dtTrigger.next(); 
    } catch (error) {
      console.log(error);
    }            
    this.loadSpiner();
  }

 async obtenerTecnico(provincia,municipio,calle,finca,bis,fecha){
    let param={
      "cod_provincia" : provincia,
      "cod_municipio" : municipio,
      "cod_calle": calle,
      "finca" : finca,
      "bis":bis,
      "fecha_vigencia":fecha  
    }
    await this.asignaTec.obtenerTecnico(param).then((data:any)=>{            
    }).catch((err:any)=>{
        console.log(err);        
    })
  }

  mostrarIncidencias(contrato:number,provincia,municipio,calle,finca,bis,tercero?){
  
    let valueBis
    if(bis=="b"){ 
      valueBis=true
    }else{
      valueBis=false
    }
    this.incidenciasModelRef= this.dialog.open(IncidenciasListComponent,{
      height: '40vh',
      width:'60vw',
      data:{
        'contrato':contrato,
        "cod_provincia" : provincia,
        "cod_municipio" : municipio,
        "cod_calle": calle,
        "finca" : finca,
        "vis":valueBis,
        "fecha_vigencia":moment(new Date()).format("YYYY-MM-DD")
      }
    });
  }

  loadSpiner(){
    this.load=!this.load
  }

  public get serviceData(){
    return this.funcionesSvc.datos;
  }

  recuperarServicios(contrato:number,tipoCliente:any,tercero:any,estado:any){
    this.serviciosModelRef=this.dialog.open(ListaServiciosActivosComponent,{
      height: '50vh',
      width:'60vw',
      data:{
        'contrato':contrato,
        'tipo_cliente':tipoCliente,
        'tercero':tercero,
        'estado':estado
      }
    });
  }

  async confirmarContrato(contrato:any){
    await this.funcionesSvc.peticionMarca(environment.confirmarContratoMarcaBlanca,'post',{},{
      'codigo_contrato':contrato,
    }).then(()=>{
      this.getLista();
    }).catch((error:any)=>{
      console.log(error);
      Swal.fire({
        title:'Error',
        icon:'error',
        text:error.error.id3,
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      })  
    });
  }

  async bajaContrato(idContrato:any){
    this.load=true;
    let numeroServicios=0;
    let listaServicios=[];
    let cadena="";
    let pasable=false;

    //recuperamos la lista de servicios de ese contrato 
    await this.recuperaServiciosActivos(idContrato).then((data:any)=>{
      numeroServicios=data.numeroServicios
      listaServicios=data.data      
    });
    
    if(numeroServicios!=0){
      for (const ele of listaServicios) {
        cadena+=ele.Descripcion+"\<br>";
        if(ele.estado!='C') {
          pasable=true;
        }
      }

      if(pasable){//en el caso en el que todos los servicios sean esst
        //en el caso de que haya servicios le aparecerá un modal indicando que estos existen en el contrato y seran eliminados 
        Swal.fire({
          title: 'Advertencia!',
          html: "Los siguientes servicios activos serán eliminados:<br><br>"+cadena,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok, eliminar',
          cancelButtonText: 'Cancelar operación'          
        }).then(async (result) => {
          if (result.isConfirmed) {
            //eliminamos servicios            
            await this.eliminaTodosServiciosActivos(listaServicios);
            await this.darDeBaja(idContrato);            
          }
        })
      }else{
        Swal.fire({
          title: 'Advertencia!',
          text: "No va a ser posible dar de baja los servicios por que no están confirmados",
          icon: 'warning',
        })
      }
    }else{
      Swal.fire({
        title: 'Advertencia!',
        text: "Va a dar este contrato de baja, está seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok, eliminar',
        cancelButtonText: 'Cancelar operación'
      }).then((result) => {
        if (result.isConfirmed) {
          //damos de baja directamente
          this.darDeBaja(idContrato);          
        }
      })
    }        
    this.load=false;
  }
  
  async recuperaServiciosActivos(contrato){
    this.load=true;
    let numeroServicios=0;
    let servicios=[];
    await this.funcionesSvc.peticionMarca(environment.recuperarServiciosActivosMarcaBlanca,'post',{},{
      'id_contrato':contrato,
    }).then((data:any)=>{      
      data.forEach(element => {
        numeroServicios++;
      });
      servicios=data;      
    }).catch((error:any)=>{
      console.log(error);
      Swal.fire({
        title:'Error',
        icon:'error',
        text:error.error.id3,
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      })
    });
    this.load=false;
    return {"numeroServicios":numeroServicios,"data":servicios};
  }

  //Función que elimina todos los servicios activos 
  async eliminaTodosServiciosActivos(listaServicios){
    this.load=true;
    let payLoad={};    
    for (const servicio of listaServicios) {      
      if((servicio.estado == 'P' || servicio.estado == '9') && servicio.NumParte!=0) {
        payLoad={
          'numeroParte': servicio.NumParte,
          'tercero': servicio.Tercero,
          'contratoprovisional': servicio.Cttprv,
          'seccion': servicio.Seccion,
          'linea': servicio.Linea
        } 
        await this.funcionesSvc.peticionMarca(environment.rechazaPedido,'post',{},payLoad).catch((error:any)=>{
          console.log(error);
        });
      } else {
        payLoad={        
          'tercero': servicio.Tercero,
          'contratoprovisional': servicio.Cttprv,
          'seccion': servicio.Seccion,
          'linea': servicio.Linea,
        }
        await this.funcionesSvc.peticionMarca(environment.bajaPedido,'post',{},payLoad).catch((error:any)=>{
          console.log(error);
        });
      }                                
    }
    this.load=false;
  }

  //Función que da de baja el contrato, esta función será llamada despues de eliminar todos los servicios del contrato 
  async darDeBaja(idContrato){    
    this.load=true;
    await this.funcionesSvc.peticionMarca(environment.bajaContrato+"/"+idContrato,'get',{},{}).then((data:any)=>{
      if(data.estado){
        Swal.fire({
          title:'Todo correcto',
          icon:'success',
          text:data.mensaje,
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
        }).then( result => {
          if(result.isConfirmed) {
            //en este caso reload
            this.load=false;            
            this.getLista();
          }
        });
      }else{
        Swal.fire({
          title:'Error',
          icon:'error',
          text:data.mensaje,
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
        })
      }
    }).catch((error:any)=>{
      console.log(error);   
    });
    this.load=false;    
  }

  diagnosticoOnt(mac:string){
     if(mac!=''){
      this.diagnosticoOntModelRef=this.dialog.open(DiagnosticoOntModalComponent,{
        disableClose: true,
        width:'50vw',
        data:{
          'mac':mac
        }
      });
     }
  }

  cambioDomicilio(data:any){
    this.cambioDomicilioModelRef=this.dialog.open(CompruebaCoberturaComponent,{
      disableClose: true,
      width:'50vw',
      height:'45vw',
      data:{
        "data":data
      }
    })
    this.cambioDomicilioModelRef.afterClosed().subscribe({
      next:(data:any)=>{
        this.getLista()
      }
    })
  }

  listarDocumentos(contrato){
    this.listaDocumentos=this.dialog.open(ListaDocumentosComponent,{
      height: '60vh',
      width:'80vw',
      data:{
        'contrato':contrato,
      }
    });
  }


  subirDocumentacion(contrato){
    this.serviciosModelRef=this.dialog.open(SubirDocumentacionComponent,{
      height: '60vh',
      width:'80vw',
      data:{
        contrato:contrato
      },
      
    });
  }
  isCamdomiOne(ele: any): boolean {
    return ele.Camdomi === '1';
  }

}
