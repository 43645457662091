import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CDRService } from 'src/app/services/cdr.service';

@Component({
    selector: 'app-cdr-page',
    templateUrl: './cdr-page.component.html',
    styleUrls: ['./cdr-page.component.css']
})
export class CdrPageComponent implements OnInit {
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        buttons: false,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        pagingType: 'simple_numbers',
    };

    dtTrigger: Subject<void> = new Subject<void>();
    //====================END DATATABLE =====================================//
    constructor(public cdrService: CDRService) {}

    ngOnInit(): void {}

}