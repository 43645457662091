import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { url } from 'inspector';
import { CookieService } from 'ngx-cookie-service';
import { element } from 'protractor';
import { Subject } from 'rxjs';
import { DoneteComponent } from 'src/app/donete/donete.component';
import { AuthService } from 'src/app/services/auth.service';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DocumentacionComponent } from './documentacion/documentacion.component';
import { LoadingServiceService } from './loading-service.service';
import { ChangeDetectorRef } from '@angular/core';
import { ChangePageService } from 'src/app/services/change-page.service';
import { ContratosService } from 'src/app/services/contratos.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-accioneslineamovil',
    templateUrl: './accioneslineamovil.component.html',
    styleUrls: ['./accioneslineamovil.component.css']
})
export class AccioneslineamovilComponent implements OnInit {
    con: any
    peticion;
    activacion;
    tarifa;
    titular: any;
    dni;
    numero;
    multisim;
    cambio;
    hora;
    tercero: any;
    data;
    data2;
    data3;
    data4;
    data5: any
    bonusForPhone: any
    promo: boolean
    tarifaOrigen: any
    public load: Boolean = false;
    //=====Layout =========///
    public consumowrapper = false;
    public cambiowrapper = false
    public bonoswrapper = false
    public servicioswrapper = false
    public infowrapper = false
    public listarMultisimwrapper = false
    public titularidadwrapper = false
    public controlRiesgoWrapper = false
    public cambioSimWrapper = false
    public documentacionWrapper = false
    public actionSim = false
    public defaultwrapper = false
    //======End Layout =====//
    protected newTarifaUpdate: string
    // public doughnutChartLabels: string[]
    // public demodoughnutChartData: number[]
    // public doughnutChartType: string
    documento: any

    hiddeTitularidadContent = false;

    consumo = {
        telefono: ''
    }

    //_______BONOS_______
    bono = {
        peticion: ''
    }
    Nohaybonos = false;

    //_______INFOSIM_______
    infoSim = {
        tercero: '',
        telefono: ''
    }
    iccSim: any;
    pinpuk = {
        tercero: '',
        iccSim: ''
    }
    pin: any;
    pin2: any;
    puk: any;
    puk2: any;
    public listTarifas: any

    riesgoDeLinea = {
        'limite': 0,
        "bloqueo": false
    }
    listaLllamadas
    groupListLlamadas
    // tiposDeLlamada = []
    datosPersonales: any;
    buscarCliente: boolean;
    dataClientExist: any;
    crearnuevo
    itemsGroupLlamada
    esContfratoDistribuidor = false;
    simEsValida = false;

    //________AUXILIARES SOLO PARA CONTRATO DISTRIBUIDOR
    dobleGb
    newTarifa
    cambiarTarifa: boolean = false
    historicTarifa: boolean = false
    gestionBonos: boolean = false
    historicBonos: boolean = false
    resetDocumentacion: Subject<boolean> = new Subject<boolean>();
    resetCambioCliente: Subject<boolean> = new Subject<boolean>();
    resetConsumo: Subject<boolean> = new Subject<boolean>();
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        public cookies: CookieService,
        private dialog: MatDialog,
        private cdref: ChangeDetectorRef,
        public loadService: LoadingServiceService,
        public changePage: ChangePageService,
        public contratosSvc: ContratosService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        //__________________________________________INICIALIZACION DE LA BARRA LATERAL IZQUIERDA
        this.con = this.cookies.get("con");
        this.peticion = this.cookies.get("peticion");
        this.activacion = this.cookies.get("activacion");
        this.tarifa = this.cookies.get("tarifa");
        this.titular = this.cookies.get("titular");
        this.dni = this.cookies.get("dni");
        this.numero = this.cookies.get("numero");
        this.multisim = this.cookies.get("multisim");
        this.cambio = this.cookies.get("cambio");
        this.hora = this.cookies.get("hora");
        this.tercero = this.cookies.get("tercero");
        this.dobleGb = this.cookies.get("dobleGb");
        this.newTarifa = this.cookies.get("nextTarifa");
        // this.promo=this.cookies.get("promo")
        //COMPROBAMOS PROMO
        if (this.cookies.get("promo") == "S" && this.tarifa == "DIS_VERANO" || this.tarifa == "OROC_VERANO") {

            this.promo = true;
            this.contratosSvc.getTarifaOrigen({ numero: this.numero }).subscribe((data: any) => {

                if (data.Tarifa == "Error: No hay tarifa asociada") {
                    this.tarifaOrigen = "No hay tarifa asociada"
                } else {
                    this.tarifaOrigen = data.Tarifa

                }
            })
        } else {
            this.promo = false;
        }

        this.changePage.contratoMovilSeleccionado = this.cookies.get("coficial") + "|" + this.cookies.get("cprovisional") + "|" + this.cookies.get("promo") //guardarContrato al volver atras


        // this.lookDocumentacionFtp()



        //______________________________________END INICIALIZACION DE LA BARRA LATERAL IZQUIERDA



        //DATA 2
        this.route.queryParams.subscribe(params => {
            const conValue = params['con'];
            this.con = conValue // Aquí puedes trabajar con el valor recibido
        });
    }
    ngAfterContentChecked() {

        this.cdref.detectChanges();

    }
    //__________________________________LOADING__________________________________
    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }

    //__________________________________GET_CONSUMO__________________________________
    async getConsumo() {
        this.switchLayout('consumo'); //cambiar de layout
    }


    // events
    public chartClicked(e: any): void {
        console.log(e);
    }

    public chartHovered(e: any): void {
        console.log(e);
    }


    //__________________________________GET_BONOS__________________________________
    /**
     * Método encargado de retornar la lista de bonos que puede activar una determinada linea
     */
    async bonusForLine() {
        this.switchLayout('bonos'); //cambiar de layout

    }



    //__________________________________INFOSIM__________________________________
    infosim() {
        this.switchLayout('info'); //cambiar de layout


    }
    listarmultisim() {
        console.log("listar multiSIM")
        this.switchLayout('listar multiSIM');

    }


    //===============================================JOSE GABRIEL========================================================================//
    /**
     * Método encargado de mostrar el panel de cambio de tarifa
     */
    cambioTarifaPanel() {
        this.switchLayout('cambio de tarifa'); //cambiar de layout

    }



    //__________________________________SWITCHLAYOUT__________________________________
    /**
     * Metodo encargado de mostrar el Layout a la correspondiente accion en el contenedor principal
     * @param layout nombre del boleano que permite mostrar el layout
     */
    switchLayout(layout: String) {
        switch (layout) {
            case 'consumo':

                this.resetConsumo.next(true);
                this.consumowrapper = true
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.listarMultisimwrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;
                break;
            case 'cambio de tarifa':
                // this.getTarifasMobile() //CARGO LA LISTA DE TARIFAS

                this.loadService.cambiarTarifa = false
                this.loadService.historicTarifa = false
                this.cambiowrapper = true
                this.consumowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.listarMultisimwrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;

            case 'bonos':

                this.loadService.historicBonos = false
                this.loadService.gestionBonos = false
                this.bonoswrapper = true
                this.consumowrapper = false
                this.cambiowrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.listarMultisimwrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;
                break;
            case 'servicios':
                this.consumowrapper = true
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.listarMultisimwrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;
            case 'info':
                this.infowrapper = true
                this.listarMultisimwrapper = false
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;
            case 'listar multiSIM':
                this.infowrapper = false
                this.listarMultisimwrapper = true
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;
            case 'titularidad':
                if (this.titularidadwrapper) {
                    this.resetCambioCliente.next(true);
                }
                this.hiddeTitularidadContent = false;
                this.titularidadwrapper = true
                this.listarMultisimwrapper = false
                this.consumowrapper = false
                this.controlRiesgoWrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.defaultwrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;
            case 'controlyriesgo':

                this.controlRiesgoWrapper = true
                this.listarMultisimwrapper = false
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;
            case 'cambioSim':

                this.cambioSimWrapper = true;
                this.listarMultisimwrapper = false
                this.controlRiesgoWrapper = false;
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.actionSim = false;
                this.documentacionWrapper = false;

                break;

            case 'actionSim':
                this.cambioSimWrapper = false;
                this.listarMultisimwrapper = false
                this.controlRiesgoWrapper = false;
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.actionSim = true;
                this.documentacionWrapper = false;
                break;
            case 'documentacion':
                if (this.documentacionWrapper) {
                    this.resetDocumentacion.next(true);
                }
                this.cambioSimWrapper = false;
                this.listarMultisimwrapper = false
                this.controlRiesgoWrapper = false;
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.titularidadwrapper = false
                this.defaultwrapper = false
                this.actionSim = false;
                this.documentacionWrapper = true;
                break;
            default:
                this.defaultwrapper = true
                this.consumowrapper = false
                this.cambiowrapper = false
                this.bonoswrapper = false
                this.servicioswrapper = false
                this.infowrapper = false
                this.listarMultisimwrapper = false
                this.titularidadwrapper = false
                this.controlRiesgoWrapper = false
                this.cambioSimWrapper = false;
                this.actionSim = false;
                this.documentacionWrapper = false;
                break;
        }
    }

    //__________________________________CONTROL DE RIESGO__________________________________

    async controlRiesgoLaunch() {
        this.switchLayout("controlyriesgo");
    }
    //__________________________________GUARDAR CONTROL DE RIEGO__________________________________

    //__________________________________CAMBIO SIM__________________________________

    cambioSim() {
        this.switchLayout("cambioSim")
    }

    cambioTitularidad() {
        this.switchLayout('titularidad');
        this.documento = "";
        this.buscarCliente = false;
        this.crearnuevo = false;
        this.dataClientExist = null;
    }

    buscarClienteExistente() {
        this.documento = "";
        this.buscarCliente = true;
        this.crearnuevo = false;
        this.dataClientExist = null;

    }

    /**
     * Encargado de lanzar la peticion de doble de gigas para una linea movil
     *
     */
    async launchDobleGigas() {
        this.showloading()
        const headers = new HttpHeaders({
            'user': this.cookies.get('user'),
            'token': this.cookies.get('token'),
            'pass': this.cookies.get('pass'),
        });
        const body = {
            "telefono": this.numero
        }
        let url = environment.launchDobleGigas;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading();
                if (res.id) {
                    Swal.fire({
                        title: "ACTIVACIÓN DOBLE GB",
                        icon: 'success',
                        text: res.id,

                    })

                    this.dobleGb = null;
                } else {
                    Swal.fire({
                        title: "ACTIVACIÓN DOBLE GB",
                        icon: "warning",
                        text: res.Error,
                    })
                    this.dobleGb = null;
                }
            }, error => {
                this.hideloading();
                Swal.fire({
                    title: "ACTIVACIÓN DOBLE GB",
                    icon: "warning",
                    text: error.Error,

                })
                console.log(error);

            })
        } catch {
            this.hideloading();
        }

    }



    refreshNewTitular() {

        // this.cookies.set("titular", titular);
        this.titular = this.dataClientExist.des;
        this.dni = this.dataClientExist.doc;

    }
    accionesSim() {
        this.switchLayout('actionSim');
    }


    documentacion() {
        this.switchLayout('documentacion');
    }

    cerrarDocumentacion() {
        this.loadService.archive = null;
    }

    cambioTarifa() {
        this.loadService.historicTarifa = false;
        this.loadService.cambiarTarifa = true;
    }

    verHistorico() {
        this.loadService.cambiarTarifa = false;
        this.loadService.historicTarifa = true;
    }
    gestionDeBonos() {
        this.loadService.gestionBonos = true
        this.loadService.historicBonos = false
    }
    historicoDeTarifas() {
        this.loadService.gestionBonos = false
        this.loadService.historicBonos = true
    }

    bloqueoDesbloqueo(peticion: any) {
        this.load = true
        this.contratosSvc.bloqueoDesbloqueo(peticion).subscribe((data: any) => {
            this.load = false
            if (this.cookies.get("robo") == "S") {
                this.cookies.set("robo", "N")
                Swal.fire({
                    title: "Bloqueo por robo desactivado",
                    icon: "info",
                    text: "Accion realizada correctamente",
                })
            } else if (this.cookies.get("robo") == "N") {
                this.cookies.set("robo", "S")
                Swal.fire({
                    title: "Bloqueo por robo activado",
                    icon: "info",
                    text: "Accion realizada correctamente",
                })
            }

        }, (error: any) => {
            Swal.fire({
                title: "Error",
                icon: "error",
                text: "Algo ha salido mal",
            })
        })
    }

}
